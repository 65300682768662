//@ts-check
//@ts-ignore
angular.module('app').controller('SubmissionTasksCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "Translate", "PopupService", "overlaySpinner", "ToasterService", "Util", "multiSelect", "EventTracker", function($scope, $rootScope, $state, $stateParams, Server, Translate, PopupService, overlaySpinner, ToasterService, Util, multiSelect, EventTracker) {
    /** 
     * @typedef { Object } SubmissionTask
     * @property { string } [_id]
     * @property { string } title
     * @property { string } [campaignId]
     * @property { string } [candidateId]
     * @property { string } body
     * @property { string } date
     * @property { Date|moment.Moment } time
     * @property { Array } [collaborators]
     * @property { boolean } done
     * @property { number } priority
     * @property { Object } singleNotif
     * @property { string } [createdBy]
     * @property { Array } selectedCampaign
     * @property { Array } selectedCandidate
     * @property { Array } selectedPriority
     * @property { Array } selectedReminder
     */

    /** 
     * @typedef { Object } SubmissionTaskMeta
     * @property { boolean } isExpanded
     * @property { boolean } isEditable
     * @property { Object } errorFields
     */

    /**
     * @typedef { Object } SubmissionTaskWrapper
     * @property { SubmissionTaskMeta } meta
     * @property { SubmissionTask } data
     * @property { Array } selectedCampaign
     * @property { Array } selectedCandidate
     * @property { Array } selectedPriority
     * @property { Array } selectedReminder
     * @property { Object } [campaign]
     * @property { Object } [candidate]
     */

    $scope.candidateScoped = !!$stateParams.candidateId;
    $scope.userRightName = 'tasks.list';

    
    let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
    $scope.multiselectSettings = {
        ...multiSelectln.objectSettings,
        idProperty: "id",
        dynamicTitle: true,
        smartButtonMaxItems: 5,
    };
    $scope.multiselectTexts = multiSelectln.texts;
    $scope.singleselectSettings = {
        ...$scope.multiselectSettings,
        selectionLimit: 1,
        showCheckAll: false,
        showUncheckAll: false,
        checkBoxes: false,
    }
    $scope.singleselectSettingsNoScroll = {
        ...$scope.singleselectSettings,
        scrollable: false,
    }
    $scope.multiSelectCampaignEvents = { onSelectionChanged: campaignSelected };
    $scope.singleselectPriorityEvents = { onSelectionChanged: prioritySelected }

    $scope.priorityOptions = [
        { id: 5, label: Translate.getLangString('priority_low') },
        { id: 3, label: Translate.getLangString('priority_medium') },
        { id: 1, label: Translate.getLangString('priority_high') },
    ];
    $scope.reminderOptions = [
        { id: 0, label: Translate.getLangString('no_reminder')},
        { id: 1, label: Translate.getLangString('reminder_at_time'), value: { period: 0, range: 'minutes' } },
        { id: 3, label: Translate.getLangString('reminder_30_min_before'), value: { period: 30, range: 'minutes' } },
        { id: 4, label: Translate.getLangString('reminder_1_hour_before'), value: { period: 1, range: 'hours' } },
        { id: 5, label: Translate.getLangString('reminder_1_day_before'), value: { period: 1, range: 'days' } },
        { id: 6, label: Translate.getLangString('reminder_2_day_before'), value: { period: 2, range: 'days' } },
    ];
    const emptyCandidateOptions = [{ id: 0, label: Translate.getLangString('no_candidate') }];
    $scope.campaignOptions = [];

    $scope.tasks = [];

    $scope.crud_translation = {
        title: 'tasks',
        add_item: 'task_add',
        items: 'tasks',
    }

    $scope.entityProperties = [
        {
            type: 'toggle',
            location: 'header',
            key: 'done',
            text: Translate.getLangString('status'),
            dataCssClass: 'task__done__col',
            filterCssClass: 'task__done__col',
            noSort: true,
            onToggle: (item) => $scope.toggleTaskDone(item),
            options: [
                { id: true, label: 'Done' },
                { id: false, label: 'Unfinished' },
            ],
            extraSettings: $scope.multiselectSettings,
        },
        {
            type: 'text',
            location: 'header',
            key: 'title',
            text: 'Title',
            isGlobalFilter: true,
            dataCssClass: 'task__title__col crud-list__title__col',
            filterCssClass: 'task__title__col crud-list__title__col',
            noFilter: true,
        },
        {
            type: 'collaborators',
            location: 'header',
            key: 'collaboratorsIds',
            text: Translate.getLangString('collaborators'),
            dataCssClass: 'task__collaborators__col',
            filterCssClass: 'task__collaborators__col',
            noSort: true,
            optionsFactory: async () => {
                const mapCollabOptions = (collab) => {
                    return { id: collab._id, label: Util.userFullName(collab) };
                }
                if ($rootScope.collaborators) {
                    return [
                        { id: $rootScope.user._id, label: Util.userFullName($rootScope.user) },
                        ...$rootScope.collaborators.map(mapCollabOptions)
                    ];
                }
                return Server.get('collaborators').then(function(collaborators) {
                    $rootScope.collaborators = collaborators;
                    return [
                        { id: $rootScope.user._id, label: Util.userFullName($rootScope.user) },
                        ...$rootScope.collaborators.map(mapCollabOptions)
                    ];
                });
            }
        },
        !$scope.candidateScoped ? {
            type: 'dropdown',
            location: 'header',
            key: 'selectedCampaign',
            text: Translate.getLangString('campaign'),
            emptyText: Translate.getLangString('no_campaign'),
            dataCssClass: 'task__campaign__col',
            filterCssClass: 'task__campaign__col',
            noSort: true,
            optionsFactory: () => {
                if (!$scope.campaignOptions || $scope.campaignOptions.length == 0) {
                    return undefined;
                }
                return $scope.campaignOptions;
            },
            extraSettings: $scope.singleselectSettings,
            dropdownEvents: $scope.multiSelectCampaignEvents,
            ref: {
                state: 'candidates',
                stateParams: '{ campaignId: item.data.selectedCampaign[0].id }'
            }
        } : undefined,
        !$scope.candidateScoped ? {
            type: 'dropdown',
            location: 'header',
            key: 'selectedCandidate',
            text: Translate.getLangString('Candidate'),
            emptyText: Translate.getLangString('no_candidate'),
            dataCssClass: 'task__candidate__col',
            filterCssClass: 'task__candidate__col',
            noFilter: true,
            options: [{ id: 0, label: Translate.getLangString('no_candidate')}],
            extraSettings: $scope.singleselectSettings,
            ref: {
                state: 'submission-tasks',
                stateParams: '{ candidateId: item.data.selectedCandidate[0].id }',
            }
        } : undefined,
        {
            type: 'datetime',
            location: 'header',
            key: 'time',
            text: Translate.getLangString('date'),
            dataCssClass: 'task__duedate__col',
            filterCssClass: 'task__duedate__col',
            noFilter: true,
        },
        {
            type: 'dropdown',
            location: 'header',
            key: 'selectedPriority',
            text: Translate.getLangString('priority'),
            dataCssClass: 'task__priority__col',
            filterCssClass: 'task__priority__col',
            noFilter: true,
            options: $scope.priorityOptions,
            extraSettings: $scope.singleselectSettings,
            events: $scope.singleselectPriorityEvents,
        },
        {
            type: 'dropdown',
            location: 'body',
            key: 'selectedReminder',
            text: Translate.getLangString('reminder'),
            emptyText: Translate.getLangString('no_reminder'),
            dataCssClass: 'task__reminder__col',
            noFilter: true,
            noSort: true,
            options: $scope.reminderOptions,
            extraSettings: $scope.singleselectSettingsNoScroll,
        },
        {
            type: 'quill',
            location: 'body',
            key: 'body',
            text: Translate.getLangString('body'),
        },
    ].filter(p => p);

    if (!$scope.candidateScoped) {
        $rootScope.backEnabled = true;
        Util.setPageTitle(Translate.getLangString('tasks'));
        $rootScope.secondaryNav = null;
        $rootScope.backEnabled = true;
        $rootScope.candidate = undefined;
        $rootScope.campaign = undefined;
    }

    $rootScope.validateUserAccess((user) => {
        if (!$rootScope.fns.userHasRights($scope.userRightName, 'view', user)) {
            if ($scope.candidateScoped) {
                $state.go('submission', { candidateId: $stateParams.candidateId })
            } else {
                $state.go('campaigns')
            }
        }
    });

    /**
     * Check if all input values are valid
     * @param { SubmissionTask } task task being created/updated
     * @returns true if inputs are valid
     */
    $scope.validateInputs = function(task) {
        if ($scope.candidateScoped) {
            task.campaignId = $rootScope.campaign._id || $rootScope.candidate.campaignId
            task.candidateId = $rootScope.candidate._id
        }
        else {
            task.campaignId = task.selectedCampaign && task.selectedCampaign?.[0]?.id ? task.selectedCampaign[0].id : (task.selectedCandidate && task.selectedCandidate.length > 0 ? task.selectedCandidate[0].campaignId : undefined);
            task.candidateId = task.selectedCandidate && task.selectedCandidate?.[0]?.id ? task.selectedCandidate[0].id : undefined;
        }
        
        if (task.selectedPriority && task.selectedPriority.length > 0) {
            task.priority = task.selectedPriority[0].id ? task.selectedPriority[0].id : undefined;
        }
        if (task.selectedReminder && task.selectedReminder.length > 0) {
            task.singleNotif = task.selectedReminder[0].value;
        }

        const errorFields = {};
        if (!task.title) {
            errorFields.title = true;
        }
        if (!task.date) {
            errorFields.date = true;
        }
        return errorFields;
    }

    $scope.onItemAddClicked = function(newItem) {
        const date = moment(new Date()).add(1, 'day');
        newItem.date = date.toDate().toISOString();
        newItem.time = date.hour(9).minute(0);
        newItem.priority = 5;
        newItem.selectedPriority = [{ id: newItem.priority }];
        newItem.collaboratorsIds = [$rootScope.user._id];
        if ($scope.candidateScoped) {
            newItem.candidateId = $stateParams.candidateId;
        }
        newItem.selectedReminder[0] = $scope.reminderOptions[1];
    }

    $scope.onItemWrapperData = function(taskWrapper) {
        const task = taskWrapper.data;
        const classes = {};
        const today = new Date();
        if (task.time < today && !task.done) {
            classes['overdue'] = true;
        }
        if (task.done) {
            classes['task-title--done'] = true;
        }
        if (task.priority === 1) {
            classes['priority--high'] = true;
        }
        if (task.priority === 3) {
            classes['priority--medium'] = true;
        }
        if (task.priority === 5) {
            classes['priority--low'] = true;
        }
        taskWrapper.meta.cssClasses = classes;

        loadCandidates(null, taskWrapper);
    }

    async function loadTasks() {
        if ($scope.candidateScoped && !$rootScope.user || !$rootScope.user._id) {
            return;
        }
        const overlay = overlaySpinner.show('submission');
        let fetchUrl = $scope.candidateScoped ? `candidates/${$stateParams.candidateId}/tasks` : `tasks`
        return Server.get(fetchUrl)
            .then(res => {
                overlay.hide();
                if (Array.isArray(res)) {
                    $scope.tasks = res.map(task => {
                        if (task.date) {
                            task.time = moment(task.date);
                        }
                        if (task.notifs && task.notifs.length > 0) {
                            task.singleNotif = task.notifs[0].timeBeforeDate;
                        }
                        task.done = !!task.done;
                        task.selectedCampaign = [{ id: task.campaignId ?? 0 }]
                        task.selectedCandidate = [{ id: task.candidateId ?? 0 }];
                        task.selectedPriority = [$scope.priorityOptions.find(p => p.id === task.priority)].map(p => p ? p : { id: 0 });
                        task.selectedReminder = [$scope.reminderOptions.find(r => r.value && task.singleNotif && r.value.period === task.singleNotif.period && r.value.range === task.singleNotif.range)].map(r => r ? r : { id: 0 });                      
                        return task;
                    }).reverse();
                    
                    if ($scope.campaignOptions && $scope.campaignOptions.length > 0) {
                        $scope.syncSelectedCampaignAndCandidate();
                    }
                }
            }).catch(err => {
                overlay.hide();
                ToasterService.failure(err, 'load_tasks_error');
            });
    }

    async function loadCampaigns() {
        const overlay = overlaySpinner.show('submission');
        return Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true')
            .then((campaigns) => {
                overlay.hide();
                $scope.campaignOptions = [
                    { id: 0, label: Translate.getLangString('no_campaign'), candidateOptions: []},
                    ...campaigns.map(camp => (
                        {
                            id: camp._id,
                            label: camp.title[camp.language],
                            candidatesIds: camp.candidateIds,
                            candidateOptions: [],
                        }
                    ))
                ];
                $scope.syncSelectedCampaignAndCandidate();
            })
            .catch(err => {
                overlay.hide();
                ToasterService.failure(err, 'load_campaign_error');
            });
    }

    $scope.syncSelectedCampaignAndCandidate = function() {
        for (const task of $scope.tasks) {
            if (task.campaign) {
                let campaign = $scope.campaignOptions.find(camp => camp.id === task.campaign.id);
                if (!campaign) {
                    campaign = { id: task.campaign.id, label: task.campaign.title[task.campaign.language] };
                    $scope.campaignOptions.push({ id: task.campaign.id, label: task.campaign.title[task.campaign.language] });
                }
                task.selectedCampaign = [campaign ?? { id: 0 }];
            }
            if (!task.selectedCampaign[0].candidateOptions) {
                task.selectedCampaign[0].candidateOptions = []
            }

            if (task.candidate) {
                let candidate = task.selectedCampaign[0].candidateOptions.find(camp => camp.id === task.candidate.id);
                if (!candidate) {
                    candidate = { id: task.candidate.id, label: Util.userFullName(task.candidate) };
                    task.selectedCampaign[0].candidateOptions.push(candidate)
                }
                task.selectedCandidate = [candidate ?? { id: 0 }];
            }
        }
    }

    async function campaignSelected() {
        const { taskWrapper, candidates } = await loadCandidates();
        if (candidates && candidates.length > 0) {
            taskWrapper.data.selectedCandidate[0] = candidates[0];
        }
    }
    
    function prioritySelected() {
        const task = $scope.tasks.find(m => m.meta.isExpanded && m.meta.isEditable);
        if (!task) {
            return;
        }
        if (task.selectedPriority && task.selectedPriority.length > 0) {
            task.data.priority = task.selectedPriority[0].id ?? 0;
        }
    }

    /**
     * 
     * @param {any} campaign 
     * @returns { Object } list of candidates for the campaign
     */
    function loadCampaignCandidates(campaign) {
        if (campaign.candidateOptions && campaign.candidateOptions.length > 0) {
            return Promise.resolve(campaign.candidateOptions);
        }
        if (campaign.id) {
            overlaySpinner.show('submission');
            return Server.get(`campaignsForTasksList/${campaign.id}`)
                .then((campaignResponse) => {
                    overlaySpinner.hide('submission');
                    campaign.candidateOptions = [
                    ...emptyCandidateOptions,
                    ...campaignResponse.candidates.map(candidate => (
                            {
                                id: candidate._id,
                                label: $rootScope.userFullName(candidate),
                            }
                        ))
                    ];
                    return campaign.candidateOptions;
                })
                .catch((err) => {
                    overlaySpinner.hide('submission');
                    if (err.error && err.error < 0) {
                        return campaign.candidateOptions;
                    } else {
                        ToasterService.failure(err, 'load_candidates_error');
                    }
                });
        }

        return Promise.resolve(emptyCandidateOptions);
    }

    /**
     * 
     * @param {any} [campaign]
     * @param {any} [task] 
     * @returns { Promise<{taskWrapper: any, candidates?: Array }> }
     */
    function loadCandidates(campaign, task) {
        let taskWrapper = task;
        if (!taskWrapper) {
            taskWrapper = $scope.crudList.getEditingItem().currentState;
        }
        if (!campaign) {
            if (taskWrapper.data.selectedCampaign && taskWrapper.data.selectedCampaign[0]) {
                campaign = taskWrapper.data.selectedCampaign[0];
            } else {
                return Promise.resolve({ taskWrapper });
            }
        }
        return loadCampaignCandidates(campaign)
            .then(candidates => {
                taskWrapper.meta.options['selectedCandidate'] = candidates;
                return {
                    taskWrapper,
                    candidates,
                };
            });
    }

    /**
     * Toggle the done status for the task
     * @param {SubmissionTask} task 
     */
    $scope.toggleTaskDone = function(task) {
        if (task.done) {
            EventTracker.trackTaskDone();
        }
        if (task._id) {
            const overlay = overlaySpinner.show('submission');
            Server.put(`tasks/${task._id}/switchDoneStatus`)
                .then((taskResponse) => {
                    task.done = taskResponse.done;
                    overlay.hide();
                })
                .catch(err => {
                    overlay.hide();
                    ToasterService.failure(err, 'task_not_updated');
                });
        }
    }

    /**
     * 
     * @param {SubmissionTask} task 
     */
    $scope.saveTask = function(task) {
        const overlay = overlaySpinner.show('submission');
        const taskDto = JSON.parse(JSON.stringify(task));
        if (task._id) {
            return Server.put(`tasks/${task._id}`, taskDto)
                .then(() => {
                    overlay.hide();
                    ToasterService.success('task_updated');
                    if ($scope.$parent.reloadCandidate) {
                        $scope.$parent.reloadCandidate();
                    }
                    return task;
                })
                .catch((err) => {
                    overlay.hide();
                    ToasterService.failure(err, 'task_not_updated');
                });
        } else {
            return Server.post(`tasks`, taskDto)
                .then((res) => {
                    overlay.hide();
                    ToasterService.success('task_created');
                    task._id = res._id;
                    loadTasks();
                    if ($scope.$parent.reloadCandidate) {
                        $scope.$parent.reloadCandidate();
                    }
                    return task;
                })
                .catch(err => {
                    overlay.hide();
                    ToasterService.failure(err, 'task_not_created');
                });
        }
    }

    /**
     * 
     * @param {SubmissionTask} task
     */
    $scope.deleteTask = function(task) {
        const overlay = overlaySpinner.show('submission');
        return Server.delete(`tasks/${task._id}`, task)
        .then(() => {
            overlay.hide();
            ToasterService.success('task_deleted');
            if ($scope.$parent.reloadCandidate) {
                $scope.$parent.reloadCandidate();
            }
        })
        .catch((err) => {
            overlay.hide();
            ToasterService.failure(err, 'task_not_deleted');
            loadTasks();
        });
    }

    /**
     * Rule to define if a task is editable by the current user
     * @param { SubmissionTask } task 
     * @returns { boolean }
     */
    $scope.isTaskEditable = function(task) {
        // return !task._id || task.createdBy === $rootScope.user._id;
        return true; 
    }

    setTimeout(async () => {
        if (!$scope.tasks || $scope.tasks.length === 0) {
            if (!$scope.candidateScoped) {
                await loadCampaigns();
            }
            await loadTasks();
        }
    });
}])