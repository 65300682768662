//@ts-check
//@ts-ignore
angular.module('app').controller('DashboardBrandingEditCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "Translate", "Util", "Upload", "ToasterService", "overlaySpinner", "clipboard", "VideoClipUploader", "PopupService", "NG_QUILL_CONFIG_DESCRIPTIONS", "EventTracker", "multiSelect", function ($scope, $rootScope, $state, $stateParams, Server, Translate, Util, Upload, ToasterService, overlaySpinner, clipboard, VideoClipUploader, PopupService, NG_QUILL_CONFIG_DESCRIPTIONS, EventTracker, multiSelect) {
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  if ($state.is('dashboard-branding.legal') && !$rootScope.user.privileges.useCustomTermsAndConditions) {
    $state.go('dashboard-branding.info', { brandingId: $stateParams.brandingId });
  }

  let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
  $scope.multiSelectLanguagesSettings = {
      ...multiSelectln.objectSettings,
      idProperty: "value",
      displayProp: "full",
      dynamicTitle: true,
      smartButtonMaxItems: 5,
  };
  $scope.multiSelectLanguagesTexts = {
    ...multiSelectln.texts,
    buttonDefaultText: Translate.getLangString('all_languages')
  };
  $scope.languageOptions = Translate.getLangDropdownObject();

  var selectedBrandColor = null;
  var selectedNavbarColor = null;

  var langsArray = Translate.getLangsArray();
  $scope.langsArray = langsArray;

  $scope.ddLangs = Translate.getLangDropdownObject();
  $scope.descriptionLang = Translate.currentLanguage();
  $scope.titleLang = Translate.currentLanguage();
  $scope.termsLang = Translate.currentLanguage();
  $scope.assessmentTermsLang = Translate.currentLanguage();

  $scope.$parent.brandingId = $stateParams.brandingId;
  $scope.branding = $scope.$parent.brandings.find(brand => brand.id === $stateParams.brandingId) || {};
  $scope.errorFields = {};

  $scope.campaignsFilter = null;
  $scope.campaignOptions = [];
  $scope.campaignSelection = {
    allChecked: false,
  }

  $scope.brandingMediaChanges = [];
  $scope.addBrandingChanges = function(property) {
    if (!$scope.brandingMediaChanges.includes(property)) {
      $scope.brandingMediaChanges.push(property);
    }
  }

  $scope.locationOptions = [
    { value: 'beforeJobs', label: 'Before section "Jobs"' },
    { value: 'beforeInfo', label: 'Before section "About us"' },
    { value: 'beforeFindUs', label: 'Before section "Where to find us"' },
    { value: 'beforeFooter', label: 'Before footer' }
  ];
  $scope.arrangementOptions = [
    { value: 'textThenMedia', label: 'Text then media' },
    { value: 'mediaThenText', label: 'Media then text' },
    { value: 'onlyText', label: 'Only text' },
    { value: 'onlyMedia', label: 'Only media' },
    { value: 'imageBanner', label: 'Image banner' },
    { value: 'videoBanner', label: 'Video banner' },
  ];

  $scope.bannerVideoSelected = function(videoClipId) {
    if (!$scope.branding) {
      return;
    }
    if (!$scope.branding.customization) {
      $scope.branding.customization = {}
    }

    $scope.branding.customization.bannerVideo = videoClipId;
    $scope.addBrandingChanges('customization.bannerVideo');
  }
  $scope.presentationVideoSelected = function(videoClipId) {
    if (!$scope.branding) {
      return;
    }
    if (!$scope.branding.companyInformation) {
      $scope.branding.companyInformation = {}
    }

    $scope.branding.companyInformation.presentationVideo = videoClipId;
    $scope.addBrandingChanges('companyInformation.presentationVideo');
  }
  $scope.companyVideoPermission = {
    key: 'hasCustomVideos',
    upgradePlan: 'upgrd_company_video',
    text: Translate.getLangString('tooltip_upgrade_pro')
  }

  $scope.$parent.$on('onBrandingsData', function({ currentScope }) {
    $scope.branding = currentScope.brandings.find(brand => brand.id === $stateParams.brandingId) || {};
    if (!$scope.branding.customization) {
      $scope.branding.customization = {}
    }
    if (!$scope.branding.companyInformation) {
      $scope.branding.companyInformation = {}
    }
    if (!$scope.branding.legalInformation) {
      $scope.branding.legalInformation = {};
    }
    if (!$scope.branding.legalInformation.customTermsAndConditions) {
      $scope.branding.legalInformation.customTermsAndConditions = [{}];
      $scope.branding.legalInformation.addCustomDocument = false;
    } else {
      $scope.branding.legalInformation.addCustomDocument = $scope.branding.legalInformation.customTermsAndConditions.length > 0;
      if ($scope.branding.legalInformation.addCustomDocument) {
        $scope.termsLang = parseInt(Object.keys(
          $scope.branding.legalInformation.customTermsAndConditions[0].title ||
          $scope.branding.legalInformation.customTermsAndConditions[0].label ||
          $scope.branding.legalInformation.customTermsAndConditions[0].url ||
          { "0": '' }
        )[0]);
      }
    }
    if (!$scope.branding.legalInformation.customTermsAndConditions) {
      $scope.branding.legalInformation.customAssessmentTerms = [{}];
      $scope.branding.legalInformation.addCustomDocumentAssessment = false;
    } else {
      $scope.branding.legalInformation.addCustomDocumentAssessment = $scope.branding.legalInformation?.customAssessmentTerms?.length > 0;
      if ($scope.branding.legalInformation.addCustomDocumentAssessment) {
        $scope.termsLang = parseInt(Object.keys(
          $scope.branding.legalInformation.customAssessmentTerms[0].title ||
          $scope.branding.legalInformation.customAssessmentTerms[0].label ||
          $scope.branding.legalInformation.customAssessmentTerms[0].url ||
          { "0": '' }
        )[0]);
      }
    }
    
    setupColorPicker();
    setupAvailableLanguages();
    $scope.loadCampaigns();
    $scope.setPageTitle();
  });

  $scope.$watch('branding.customization.emailSignature', (newValue, oldValue) => {
    if (document.querySelector('.branding-dashboard--form.ng-dirty')) {
      $scope.addBrandingChanges('customization.emailSignature');
    }
  })

  $scope.getBranding = function(id) {
    const overlay = overlaySpinner.show('dashboard-branding');
    return Server.get(`employer-brandings/${id}`)
      .then(res => {
        overlay.hide();
        return res;
      })
      .catch(err => {
        overlay.hide();
        ToasterService.failure(err, 'load_brandings_error');
      })
  }

  $scope.validateInput = function(branding) {
    $scope.errorFields = {};
    if (!branding.name) {
      $scope.errorFields['brandingName'] = true;
    }

    return _.isEmpty($scope.errorFields);
  }

  $scope.saveBranding = function() {
    if (!$scope.validateInput($scope.branding)) {
      ToasterService.failure({}, 'err_1_form');
      return;
    }
    
    const overlay = overlaySpinner.show('dashboard-branding');
    if ($scope.branding.id) {
      Server.put(`employer-brandings/${$scope.branding.id}`, $scope.branding)
        .then(res => {
          overlay.hide();
          ToasterService.success('branding_updated');
          $scope.branding = res
          $scope.$parent.getBrandings();
        })
        .catch(err => {
          overlay.hide();
          ToasterService.failure(err, 'branding_not_updated');
        });
    } else {
      Server.post('employer-brandings', $scope.branding)
        .then(res => {
          overlay.hide();
          ToasterService.success('branding_created');
          $scope.branding = res
          $scope.$parent.getBrandings(false)
            .then(() => $state.go('dashboard-branding.edit', { brandingId: res.id }));
        })
        .catch(err => {
          overlay.hide();
          ToasterService.failure(err, 'branding_not_created');
        });
    }
  }

  $scope.deleteBranding = function() {
    if ($scope.branding.id) {
      PopupService.openGenericPopup($scope, {
        submit: function () {
          $scope.brandings = $scope.brandings.filter(item => item && item.id !== $scope.branding.id);

          const overlay = overlaySpinner.show('dashboard-branding');
          Server.delete(`employer-brandings/${$scope.branding.id}`)
            .then(res => {
              overlay.hide();
              ToasterService.success('branding_deleted');
              $state.go('dashboard-branding.new');
              $scope.$parent.getBrandings();
            })
            .catch(err => {
              overlay.hide();
              ToasterService.failure(err, 'branding_not_deleted');
            });

          $scope.modalHandle.close();
        },
        title: Translate.getLangString('delete_confirmation_title'),
        warningText: Translate.getLangString('delete_confirmation_warning'),
        yesText: Translate.getLangString('delete'),
        noText: Translate.getLangString('cancel')
      }, 'templates/modal-confirm-warning.html', {});
    }
  }

  $scope.duplicateBranding = function() {
    if ($scope.branding.id) {
      Server.post(`employer-brandings/${$scope.branding.id}/duplicate`)
      .then(function(res) {
        ToasterService.success('branding_duplicated');
        $scope.brandings.push(...[res].map(branding => ({
          ...branding,
          value: branding.id,
          text: branding.name,
        })));
        if ($scope.brandings.length > 0 && $rootScope.campaign) {
            if (!$rootScope.campaign.customization) {
                $rootScope.campaign.customization = {}
            }
        };
      },
      function(err) {
        ToasterService.failure(err, 'duplicate_branding_error');
      });
    }
  }

  $scope.validateCompanyInfo = function(branding) {
    $scope.errorFields = {};
    if (!branding.companyInformation || !branding.companyInformation.name) {
      $scope.errorFields['companyName'] = true;
    }
    return _.isEmpty($scope.errorFields);
  }

  $scope.saveCompanyInfo = function() {
    if (!$scope.validateCompanyInfo($scope.branding)) {
      ToasterService.failure({}, 'err_1_form');
      return;
    }

    for (const changes of $scope.brandingMediaChanges) {
      switch(changes) {
        case 'companyInformation.presentationVideo':
          EventTracker.trackEmployerBrandingAddVideo();
          break;
      }
    }
    $scope.brandingMediaChanges = [];

    const overlay = overlaySpinner.show('dashboard-branding');
    Server.put(`employer-brandings/${$scope.branding.id}/company-info`, $scope.branding.companyInformation)
      .then((res) => {
        overlay.hide();
        ToasterService.success('branding_updated');
        $scope.branding.companyInformation = res.companyInformation;
        postMessage({name: 'reloadCompanyInformation', mainEmployerBranding: $scope.branding});
      })
      .catch(err => {
        overlay.hide();
        ToasterService.failure(err, 'branding_not_updated');
      });
  }

  $scope.onCustomizationBannerOpacityChanged = function() {
    if ($scope.branding.customization.bannerOverlayOpacity) {
      $scope.addBrandingChanges('customization.bannerOverlayOpacity');
    }
  }
  $scope.onCustomizationNavbarOpacityChanged = function() {
    if ($scope.branding.customization.bannerOverlayOpacity) {
      $scope.addBrandingChanges('customization.navbarOpacity');
    }
  }
  $scope.clickShowAdvanced = function(show) {
    $scope.showAdvanced = show;
  }

  $scope.saveCustomization = function() {
    if (!$scope.branding.customization) {
      $scope.branding.customization = {}
    }
    $scope.branding.customization.mainColor = selectedBrandColor;
    $scope.branding.customization.navbarFontColor = selectedNavbarColor;

    for (const changes of $scope.brandingMediaChanges) {
      switch(changes) {
        case 'customization.bannerVideo':
          EventTracker.trackEmployerBrandingBannerVideo();
          break;
        case 'customization.logo':
          EventTracker.trackEmployerBrandingLogo();
          break;
        case 'customization.bannerImage':
          EventTracker.trackEmployerBrandingBannerImage();
          break;
        case 'customization.favicon':
          EventTracker.trackEmployerBrandingFavicon();
          break;
        case 'customization.brandColor':
          EventTracker.trackEmployerBrandingColor();
          break;
        case 'customization.emailSender':
          EventTracker.trackEmployerBrandingEmailSender();
          break;
        case 'customization.emailSignature':
          EventTracker.trackEmployerBrandingEmailSignature();
          break;
        case 'customization.bannerOverlayOpacity':
          EventTracker.trackEmployerBrandingChangeBannerOpacity();
          break;
        case 'customization.navbarOpacity':
          EventTracker.trackEmployerBrandingChangeNavbarOpacity();
          break;
        case 'customization.navbarFontColor':
          EventTracker.trackEmployerBrandingChangeNavbarFontColor();
          break;
      }
    }
    $scope.brandingMediaChanges = [];

    const overlay = overlaySpinner.show('dashboard-branding');
    Server.put(`employer-brandings/${$scope.branding.id}/customization`, $scope.branding.customization)
      .then((res) => {
        overlay.hide();
        ToasterService.success('branding_updated');
        $scope.branding.companyInformation = res.companyInformation;
        postMessage({name: 'reloadCustomization', mainEmployerBranding: $scope.branding});
      })
      .catch(err => {
        overlay.hide();
        ToasterService.failure(err, 'branding_not_updated');
      });
  }

  $scope.onShowCategoriesChanged = function() {
    if ($scope.branding.careerPage.filters.showMap) {
      $scope.addBrandingChanges('careerPage.filters.showMap');
    }
  }

  $scope.onShowCategoriesChanged = function() {
    if ($scope.branding.careerPage.filters.showCategories) {
      $scope.addBrandingChanges('careerPage.filters.showCategories');
    }
  }

  $scope.onShowCountriesChanged = function() {
    if ($scope.branding.careerPage.filters.showCountries) {
      $scope.addBrandingChanges('careerPage.filters.showCountries');
    }
  }

  $scope.onShowCitiesChanged = function() {
    if ($scope.branding.careerPage.filters.showCities) {
      $scope.addBrandingChanges('careerPage.filters.showCities');
    }
  }

  $scope.onCareerTitleChanged = function() {
    if ($scope.branding.careerPage.title[$scope.titleLang]) {
      $scope.addBrandingChanges('careerPage.title');
    }
  }

  $scope.saveCareerPage = function() {
    $scope.branding.careerPage.availableLanguages = $scope.selectedAvailableLanguages.map(item => item.value);

    const overlay = overlaySpinner.show('dashboard-branding');

    for (const changes of $scope.brandingMediaChanges) {
      switch(changes) {
        case 'careerPage.filters.showMap':
          EventTracker.trackEmployerBrandingCareerSetInteractiveMap();
          break;
        case 'careerPage.filters.showCategories':
          EventTracker.trackEmployerBrandingCareerSetCategoryFilter();
          break;
        case 'careerPage.filters.showCountries':
          EventTracker.trackEmployerBrandingCareerSetCountriesFilter();
        case 'careerPage.filters.showCities':
          EventTracker.trackEmployerBrandingCareerSetCitiesFilter();
        case 'careerPage.title':
          EventTracker.trackEmployerBrandingCareerChangeBannerTitle();
          break;
      }
    }
    $scope.brandingMediaChanges = [];

    Server.put(`employer-brandings/${$scope.branding.id}/career-page`, $scope.branding.careerPage)
      .then((res) => {
        overlay.hide();
        ToasterService.success('branding_updated');
        $scope.branding.careerPage = res.careerPage;
      })
      .catch(err => {
        overlay.hide();
        ToasterService.failure(err, 'branding_not_updated');
      });
  }

  $scope.loadCampaigns = function(bypassCache) {
    function applyFilter() {
      if ($scope.campaignsFilter) {
        $scope.campaignOptions = $rootScope.campaigns
          .filter(campaign => campaign.title[campaign.language].toLowerCase().includes($scope.campaignsFilter.toLowerCase()));
      } else {
        $scope.campaignOptions = $rootScope.campaigns.map(campaign => {
          if (campaign.careerPage && campaign.careerPage.employerBrandingsIds) {
            campaign.checked = campaign.careerPage.employerBrandingsIds.includes($scope.branding.id)
          } else {
            campaign.checked = false
          }
          return campaign
        });
      }
      $scope.syncAllChecked();
    }
    if (!bypassCache && $rootScope.campaigns) {
      applyFilter();
      return
    }

    let url = 'campaigns?isActive=true&type=normalCampaign&skipCollaboratorLoad=true&skipCandidatesLoad=true'
    return Server.get(url)
      .then(function (allCampaigns) {
          $rootScope.campaigns = allCampaigns;
          applyFilter();
      })
      .catch(err => {
        ToasterService.failure(err, 'load_campaign_error');
      });
  }
  $scope.saveCampaign = function(campaignId) {
    const overlay = overlaySpinner.show('dashboard-branding');
    return Server.post(`campaigns/${campaignId}/careerPage/${$scope.branding.id}`)
      .then((res) => {
        overlay.hide();
        ToasterService.success('branding_updated');
      })
      .catch(err => {
        overlay.hide();
        ToasterService.failure(err, 'branding_not_updated');
      });
  }
  $scope.toggleCampaignSelection = async function(campaign) {
    campaign.checked = !!campaign.checked;
    if (campaign.checked) {
      EventTracker.trackEmployerBrandingCareerAddCampaign();
    } else {
      EventTracker.trackEmployerBrandingCareerRemoveCampaign();
    }
    await $scope.saveCampaign(campaign.id);
    $scope.syncAllChecked();
    $scope.$apply();
  }

  $scope.selectAll = function() {
    for (const campaign of $scope.campaignOptions) {
      if (campaign.checked !== !!$scope.campaignSelection.allChecked) {
        campaign.checked = !!$scope.campaignSelection.allChecked;
        $scope.saveCampaign(campaign._id);
      }
    }
  }

  $scope.syncAllChecked = function() {
    if ($scope.campaignOptions.every(p => !!p.checked)) {
        $scope.campaignSelection.allChecked = true;
    } else if ($scope.campaignOptions.every(p => !p.checked)) {
        $scope.campaignSelection.allChecked = false;
    } else {
        $scope.campaignSelection.allChecked = null;
    }
  }

  $scope.uploadBrandingCustomization = function(model, type, file, index) {
    var url = '';
    let resourceUrl = `employer-brandings/${$scope.branding.id}`;
    let prop = '';
    switch (model) {
      case 'companyInfo':
        url = resourceUrl + '/company-info';
        prop = 'companyInformation';
        break;
      case 'customization':
        url = resourceUrl + '/customization';
        prop = 'customization';
        break;
      case 'career':
        url = resourceUrl + '/career-page';
        prop = `careerPage.sections[${index}]`;
        break;
    }

    switch (type) {
      case 'logo':
          url += '/logo';
          prop += '.logo';
          break;
      case 'bannerImage':
          url += '/bannerImage';
          prop += '.bannerImage';
          break;
      case 'bannerVideo':
          url += file ? '/bannerVideo' : '/bannerVideoDelete';
          prop += '.bannerVideo';
          break;
      case 'bannerVideoDelete':
          url += '/bannerVideoDelete';
          prop += '.bannerVideo';
          break;
      case 'videoclip':
          url += file ? '/videoclip' : '/videoclipdelete';
          prop += '.presentationVideo';
          break;
      case 'videoclipDelete':
          url += '/videoclipdelete';
          prop += '.presentationVideo';
          break;
      case 'favicon':
          url += '/favicon';
          prop += '.favicon';
          break;
      case 'sectionVideo':
          url += file ? '/sectionVideo' : '/sectionVideoDelete';
          prop += '.video[0].videoClipId';
          break;
      case 'sectionImage':
          url += '/sectionImage';
          prop += '.images[0][0]';
          break;
    }
    $scope.addBrandingChanges(prop);

    if (index !== undefined) {
      url += `/${index}`;
    }

    const overlay = overlaySpinner.show('dashboard-branding');
    Upload.upload({
      url: Server.makeUrl(url),
      data: {
        file: file || {}
      }
    }).then((resp) => {
      overlay.hide();
      $scope.getBranding($scope.branding.id)
        .then((branding) => {
          _.set($scope.branding, prop, _.get(branding, prop));
        });
    }, (err) => {
      overlay.hide();
      console.log('Error status: ' + err && err.status);
      ToasterService.failure(err, 'err_0_error_occurred');
    }, (evt) => {
      const progressPercentage = 100.0 * evt.loaded / evt.total;
      console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
    });
  }

  function setupAvailableLanguages() {
    if ($scope.branding && $scope.branding.careerPage && $scope.branding.careerPage.availableLanguages) {
      $scope.selectedAvailableLanguages = $scope.branding.careerPage.availableLanguages.map(lang => ({ value: lang }));
    } else {
      $scope.selectedAvailableLanguages = [];
    }
  }

  function setupColorPicker() {
    if ($scope.branding && 
      $scope.branding.customization && 
      $scope.branding.customization.mainColor)
    {
      selectedBrandColor = $scope.branding.customization.mainColor;
    }

    $('#colorPicker').spectrum({
      color: selectedBrandColor,
      preferredFormat: "hex",
      allowEmpty: true,
      showInput: true,
      showButtons: true,
      disabled: !$rootScope.fns.userHasRights("employerBranding.customization", "edit"),
      hide: function (tinycolor) {
        if (!tinycolor) {
          selectedBrandColor = null;
          return;
        }
        selectedBrandColor = tinycolor.toHexString();
        $scope.addBrandingChanges('customization.brandColor');
      }
    });

    if ($scope.branding && 
      $scope.branding.customization && 
      $scope.branding.customization.navbarFontColor)
    {
      selectedNavbarColor = $scope.branding.customization.navbarFontColor;
    }
    $('#colorPickerNavbar').spectrum({
      color: selectedNavbarColor,
      preferredFormat: "hex",
      allowEmpty: true,
      showInput: true,
      showButtons: true,
      disabled: !$rootScope.fns.userHasRights("employerBranding.customization", "edit"),
      hide: function (tinycolor) {
        if (!tinycolor) {
          selectedNavbarColor = null;
          return;
        }
        selectedNavbarColor = tinycolor.toHexString();
        $scope.addBrandingChanges('customization.navbarFontColor');
      }
    })
  }

  $scope.setPageTitle = function() {
    const brandName = $scope.branding ? $scope.branding.name : undefined;
    const pageName = Translate.getLangString('employer_branding');
    Util.setPageTitle(pageName, brandName, pageName);
  }

  $scope.copyCareerPageUrl = function () {
    if ($scope.branding.careerPage && $scope.branding.careerPage.pageUrl) {
      clipboard.copyText($scope.branding.careerPage.pageUrl);
      EventTracker.trackEmployerBrandingCopyCareerLink();
      ToasterService.success('link_copied', {
        timeout: 2000
      });
    }
  }
  $scope.copyCareerRssUrl = function () {
    if ($scope.branding.careerPage && $scope.branding.careerPage.rssUrl) {
      clipboard.copyText($scope.branding.careerPage.rssUrl);
      EventTracker.trackEmployerBrandingCopyRssLink();
      ToasterService.success('link_copied', {
        timeout: 2000
      });
    }
  }

  $scope.addSection = function() {
    if (!$scope.branding.careerPage) {
      $scope.branding.careerPage = {};
    }
    if (!$scope.branding.careerPage.sections) {
      $scope.branding.careerPage.sections = [];
    }
    $scope.branding.careerPage.sections.push({
      title: {},
      location: 'beforeJobs',
      arrangement: 'onlyText',
    });
  }
  $scope.removeSection = function(index) {
    $scope.branding.careerPage.sections = $scope.branding.careerPage.sections.filter((_, idx) => idx !== index);
  }
  $scope.dragSection = function (index) {
    $scope.branding.careerPage.sections.splice(index, 1);
  };

  $scope.onAddDocumentChanged = function() {
    if ($scope.branding.legalInformation.addCustomDocument && !$scope.branding.legalInformation.customTermsAndConditions[0]) {
      $scope.branding.legalInformation.customTermsAndConditions[0] = {};
    }
  }
  $scope.onAddDocumentAssessmentChanged = function() {
    if ($scope.branding.legalInformation.addCustomDocumentAssessment && !$scope.branding.legalInformation.customAssessmentTerms[0]) {
      $scope.branding.legalInformation.customAssessmentTerms[0] = {};
    }
  }

  $scope.saveLegal = function() {
    $scope.errorFields = {};
    if ($scope.branding.legalInformation.addCustomDocument) {
      for (const lang of $scope.ddLangs) {
        const title = _.get($scope.branding, `legalInformation.customTermsAndConditions[0].title[${lang.value}]`);
        const label = _.get($scope.branding, `legalInformation.customTermsAndConditions[0].label[${lang.value}]`);
        const url = _.get($scope.branding, `legalInformation.customTermsAndConditions[0].url[${lang.value}]`);
        if ((title || label || url) && !(title && label && url)) {
          $scope.termsLang = lang.value;
          $scope.errorFields['terms_title'] = !title;
          $scope.errorFields['terms_label'] = !label;
          $scope.errorFields['terms_url'] = !url;
          $scope.errorFields['terms'] = Translate.getLangString("branding_terms_incomplete_on_language");
        }
      }
      if (!_.isEmpty($scope.errorFields)) {
        return;
      }
    }
    if ($scope.branding.legalInformation.addCustomDocumentAssessment) {
      for (const lang of $scope.ddLangs) {
        const title = _.get($scope.branding, `legalInformation.customAssessmentTerms[0].title[${lang.value}]`);
        const label = _.get($scope.branding, `legalInformation.customAssessmentTerms[0].label[${lang.value}]`);
        const url = _.get($scope.branding, `legalInformation.customAssessmentTerms[0].url[${lang.value}]`);
        if ((title || label || url) && !(title && label && url)) {
          $scope.termsLang = lang.value;
          $scope.errorFields['assessment_terms_title'] = !title;
          $scope.errorFields['assessment_terms_label'] = !label;
          $scope.errorFields['assessment_terms_url'] = !url;
          $scope.errorFields['assessment_terms'] = Translate.getLangString("branding_terms_incomplete_on_language");
        }
      }
      if (!_.isEmpty($scope.errorFields)) {
        return;
      }
    }

    const data = {}
    if ($scope.branding.legalInformation.addCustomDocument) {
      data.customTermsAndConditions = $scope.branding.legalInformation.customTermsAndConditions;
    }
    if ($scope.branding.legalInformation.addCustomDocumentAssessment) {
      data.customAssessmentTerms = $scope.branding.legalInformation.customAssessmentTerms;
    }
    if ($scope.branding.legalInformation.gtag) {
      data.gtag = $scope.branding.legalInformation.gtag;
    }
    if ($scope.branding.legalInformation.ga) {
      data.ga = $scope.branding.legalInformation.ga;
    }
    const overlay = overlaySpinner.show('dashboard-branding');
    Server.put(`employer-brandings/${$scope.branding.id}/legal-information`,
      data
    )
      .then((res) => {
        overlay.hide();
        ToasterService.success('branding_updated');
        $scope.branding.legalInformation = { ...$scope.branding.legalInformation, ...res.legalInformation };
      })
      .catch(err => {
        overlay.hide();
        ToasterService.failure(err, 'branding_not_updated');
      });
  }

  setupColorPicker();
  setupAvailableLanguages();
  $scope.loadCampaigns(true);
  $scope.setPageTitle();
  setTimeout(() => {
    const customerSupport = document.querySelector('.branding-dashboard__warning .link-button')
    if (customerSupport) {
      customerSupport.addEventListener('click', async (event) => {
        await PopupService.openSupportMessage($scope, {}, Translate.getLangString("branding_limit_request"));
      })
    }
  }, 500);
}]);
