angular.module('app').controller('CareerHeaderCtrl', ["$rootScope", "$scope", "Server", "$window", "Translate", "multiSelect", "googleMaps", "$timeout", "Util", "htmlTemplates", function ($rootScope, $scope, Server, $window, Translate, multiSelect, googleMaps, $timeout, Util, htmlTemplates) {
    $rootScope.employerBranding = {
        customization: {},
        careerPage: {},
        companyInformation: {}
    }

    $rootScope.careerNavbarOptions = []

    //
    // Navbar
    //

    // collapse and expand for mobile phones
    let mainNav = document.getElementById('js-menu');
    $rootScope.toggleNavbar = function (opts) {
        mainNav.classList.toggle('active');
        if (opts && opts.forceClose) {
            mainNav.classList.remove('active');
        }
    }

    $rootScope.scrollToSection = function (selector, scrollWindow) {
        let yOffset = -80;
        let element = document.querySelector(selector);
        if (!element) {
            console.warn(`no element found to scroll with selector ${selector}`);
            return;
        }

        if (scrollWindow) {
            const elementBoundary = element.getBoundingClientRect();
            let y = elementBoundary.top + window.scrollY + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        } else {
            let scrollable = document.querySelector('.career-app__container') || element.parentElement;
            const elementBoundary = element.getBoundingClientRect();
            let y = elementBoundary.top + scrollable.scrollTop + yOffset;
            scrollable.scrollTo({ top: y, behavior: 'smooth' });
        }
        $scope.toggleNavbar({ forceClose: true });
    }

    /**
     * @property {{elementId: string, text: string}[]}
     */
    $rootScope.setNavbarOptions = function(options) {
        $rootScope.careerNavbarOptions = options;
    }
    $rootScope.setNavbarLanguages = function(languages) {
        $rootScope.ddLangs = languages;
    }

    $rootScope.setNavTitle = function() {
        if ($rootScope.campaign) {
            $rootScope.navTitle = $rootScope.campaign.title[$rootScope.campaign.language];
        }
        else if ($rootScope.assessment) { 
            $rootScope.navTitle = $rootScope.assessment.title[$rootScope.assessment.language];
        }
        else {
            $rootScope.navTitle = '';
        }
    }

    $rootScope.changeVisitorLanguage = function (val) {
        Translate.changeVisitorLanguage(val);
        $rootScope.$broadcast('onVisitorLanguageChange');
    };
    $rootScope.ddLangs = Translate.getLangDropdownObject();
}])