//@ts-check
//@ts-ignore
angular.module('app').controller('SubmissionRatingNotesCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "$state", "PopupService", "overlaySpinner", "Translate", "Util", "ToasterService", "StageFactory", function ($scope, $rootScope, $stateParams, Server, $state, PopupService, overlaySpinner, Translate, Util, ToasterService, StageFactory) {
  /** 
   * @typedef { Object } RatingNote
   * @property { string } _id
   * @property { string } title
   * @property { string } body
   * @property { number } rating
   * @property { string } createdBy
   */

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.userHasRights('candidates.notes', 'view', user)) {
      $state.go('submission', { candidateId: $stateParams.candidateId });
    }
  });

  $scope.ratingNotes = [];
  $scope.entityProperties = [
    {
      type: 'text',
      location: 'header',
      key: 'title',
      text: 'Title',
      dataCssClass: 'crud-list__title__col',
      filterCssClass: 'crud-list__title__col',
      noFilter: true,
      noSort: true,
    },
    {
      type: 'stars',
      location: 'header',
      key: 'rating',
      text: 'Rating',
      dataCssClass: 'no-grow',
      filterCssClass: 'no-grow',
      noFilter: true,
      noSort: true,
    },
    {
      type: 'quill',
      location: 'body',
      key: 'body',
      text: 'Body',
      dataCssClass: 'no-grow',
      filterCssClass: '',
      noFilter: true,
      noSort: true,
    },
  ];
  $scope.crud_translation = {
    title: 'rating_notes',
    add_item: 'rating_entry_add',
    items: 'rating_notes',
  }

  /**
   * Check if all input values are valid
   * @param { RatingNote } ratingNote ratingNote being created/updated
   * @returns true if inputs are valid
   */
  $scope.validateInputs = function(ratingNote) {
    const errorFields = {};
    if (!ratingNote.title) {
      errorFields.title = true;
    }
    return errorFields;
  }

  $scope.loadRatingNotes = function() {
    if ($rootScope.candidate?.ratingElements?.other?.length) {
      $scope.ratingNotes = $rootScope.candidate.ratingElements.other;
    }
  }

  /**
   * Rule to define if an ratingNote is editable by the current user
   * @param { RatingNote } ratingNote 
   * @returns { boolean }
   */
  $scope.isEditable = function(ratingNote) {
      return !ratingNote._id || ratingNote.createdBy === $rootScope.user._id;
  }

  /**
   * 
   * @param {RatingNote} ratingNote
   */
  $scope.deleteEntry = function(ratingNote) {
    overlaySpinner.show('submission');
    return Server.delete(`candidates/${$stateParams.candidateId}/ratingNotes/${ratingNote._id}`, ratingNote)
      .then(() => {
        overlaySpinner.hide('submission');
        ToasterService.success('rating_note_deleted');
        if ($scope.$parent.reloadCandidate) {
          $scope.$parent.reloadCandidate();
        }
      })
      .catch((err) => {
        overlaySpinner.hide('submission');
        ToasterService.failure(err, 'rating_note_not_deleted');
        $scope.loadRatingNotes();
      });
  }

  /**
   * 
   * @param {RatingNote} ratingNote
   */
  $scope.saveEntry = function (ratingNote) {
    overlaySpinner.show('submission');
    const entryDto = JSON.parse(JSON.stringify(ratingNote));
    if (ratingNote._id) {
      return Server.put(`candidates/${$stateParams.candidateId}/ratingNotes/${ratingNote._id}`, entryDto)
        .then(() => {
          overlaySpinner.hide('submission');
          ToasterService.success('rating_note_updated');
          if ($scope.$parent.reloadCandidate) {
              $scope.$parent.reloadCandidate();
          }
          return ratingNote;
        })
        .catch((err) => {
          overlaySpinner.hide('submission');
          ToasterService.failure(err, 'rating_note_not_updated');
        });
    } else {
      return Server.post(`candidates/${$stateParams.candidateId}/ratingNotes`, entryDto)
        .then((res) => {
          overlaySpinner.hide('submission');
          ToasterService.success('rating_note_created');
          ratingNote._id = res._id;
          if ($scope.$parent.reloadCandidate) {
              $scope.$parent.reloadCandidate();
          }
          return ratingNote;
        })
        .catch(err => {
          overlaySpinner.hide('submission');
          ToasterService.failure(err, 'rating_note_not_created');
        });
    }
  }

  /**
   * Rule to define if an ratingNote is editable by the current user
   * @param { RatingNote } ratingNote 
   * @returns { boolean }
   */
  $scope.isEditable = function(ratingNote) {
    return !ratingNote._id || ratingNote.createdBy === $rootScope.user._id;
  }

  $scope.$on('onCandidateData', $scope.loadRatingNotes);
  setTimeout(() => {
    $scope.loadRatingNotes();
  }, 1000);
}])
