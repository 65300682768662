angular.module('app.services', ['vesparny.fancyModal', 'bsLoadingOverlay', 'bsLoadingOverlaySpinJs', 'angular-clipboard', 'toaster'])

.factory('env', function() {
    return window.__env || {}
})

.constant('videoStates', {
    idle: 0,
    recording: 1,
    uploading: 2,
    playback: 3,
    done: 4
})

.constant('matchingSliderOpts', {
    question: {
        options: {
            floor: 0,
            ceil: 1,
            step: 1/5,
            precision: 2,
            translate: function(value) {
                if (isNaN(value)) {
                    return '';
                }
                else if (value == this.options.floor || value == this.options.ceil) {
                    // to avoid repeating the floor or ceil number, already shown
                    return '';
                } else {
                    return Math.round(value / this.step);
                }
            }
        },
    },
    answer: {
        options: {
            ceil: 1,
            floor: 0,
            step: 1/5,
            precision: 2,
            translate: function(value) {
                if (isNaN(value)) {
                    return '';
                }
                else if (value == this.options.floor || value == this.options.ceil) {
                    // to avoid repeating the floor or ceil number, already shown
                    return '';
                } else {
                    return Math.round(value / this.step);
                }
            }
        },
    },
    /**
     * Used to move the sliders from zero (default) to their correct position. Needs to be done after a timeout, no idea why.
     */
    setAllSlidersPosition: function(scope) {
        setTimeout(function () {
            scope.$broadcast('rzSliderForceRender');
        }, 333);
    },
})

.constant('NG_QUILL_CONFIG_DESCRIPTIONS', {
    modules: {
      toolbar: [
        ['bold', 'underline'],  // <strong> and <u>
        [{ 'header': 2 }],      // custom button values <h2>
        [{ 'list': 'ordered' }, { 'list': 'bullet' }], // <ul>, <ol> and <li>
        [{ 'align' : [] }],     // assigns classes 'ql-align-center', 'ql-align-justify', 'ql-align-right', 'ql-align-left'
        ['link'],               // <a href='....'>
        ['clean'],              // remove formatting button
      ],
    },
    theme: 'snow',
})

.constant('NG_QUILL_CONFIG_EMAILS', {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline'],// toggled buttons
        [{'list': 'bullet'}],
        ['link'],
        ['clean'],              // remove formatting button
      ]
    },
    theme: 'snow',
})

.constant('NG_QUILL_CONFIG_ASSESSMENTS', {
    modules: {
        toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['link', 'image'],
            ['clean']
        ],
        imageResize: {
            modules: [ 'Resize', 'DisplaySize' ]
        },
    }
})

.constant('NG_QUILL_CONFIG_ASSESSMENTS_CANDIDATES', {
    modules: {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }],
            ['clean']
      ]
    },
    theme: 'snow',
})

.constant('_', window._) // lodash is loaded in window._

.factory('privilegeFactory', ["$rootScope", function($rootScope) {
    function hasPrivileges(requiredPrivileges, user) {
        if (!requiredPrivileges || !requiredPrivileges.length)
            return true;
        if (!user || !user.privileges)
            return false;
        return user.privileges && requiredPrivileges.every(function(requiredPriv) {
            return user.privileges[requiredPriv];
        });
    }
    function hasUiSettings(requiredUiSettings, user) {
        if (!requiredUiSettings || !requiredUiSettings.length)
            return true;
        if (!user)
            return false;
        if (!user.settings || !user.settings.ui)
            return true;
        return user.settings && user.settings.ui && requiredUiSettings.every(function(requiredUiSet) {
            return user.settings.ui[requiredUiSet];
        });
    }
    function hasCampaignsSettings(requiredSettings, user, defaultValue) {
        if (!requiredSettings || !requiredSettings.length)
            return defaultValue !== undefined ? defaultValue : true;
        if (!user)
            return defaultValue !== undefined ? defaultValue : false;
        if (!user.settings || !user.settings.campaigns)
            return defaultValue !== undefined ? defaultValue : false;
        return user.settings && user.settings.campaigns && requiredSettings.every(function(requiredUiSet) {
            return user.settings.campaigns[requiredUiSet];
        });
    }
    function hasCollaboratorsSettings(requiredSettings, user) {
        if (!requiredSettings || !requiredSettings.length)
            return true;
        if (!user)
            return false;
        if (!user.settings || !user.settings.collaborators)
            return false;
        return user.settings && user.settings.collaborators && requiredSettings.every(function(requiredUiSet) {
            return user.settings.collaborators[requiredUiSet];
        });
    }
    function hasPayPlan(requiredPayPlan, user) {
        if (!requiredPayPlan)
            return true;
        if (!user || !user.payPlan)
            return false;
        return user.payPlan && user.payPlan.current == requiredPayPlan;
    }
    /**
     * 
     * @param {string} userRightName 
     * @param { 'view'|'edit'|'delete'|true } requiredLevel 
     * @param { { rights: { hasAdminRights: boolean, basic: [key: string]: 'none'|'view'|'edit'|'delete', special: boolean }} } user 
     * @example
     * // To check if a user has admin rights
     * userHasRights('hasAdminRights', true)
     * // To check if a user has some user right that is under the basic or special properties in the UserRightsSchema
     * userHasRights('candidates.list', 'view') // second parameter can vary depending on the permission
     */
    function userHasRights(userRightName, requiredLevel, user) {
        if (user && user.userType > 0) {
            return true;
        }
        const userRight = user?.rights
        if (!userRight) {
            return false;
        }

        if (userRight.hasAdminRights) {
            return true;
        }

        if (userRightName) {
            // duplicated code (ref userRight-check)
            const userRightValue = _.get(userRight, `basic.${userRightName}`) || _.get(userRight, `special.${userRightName}`)
            switch (userRightValue) {
                case true:
                case 'delete':
                    return true;
                case 'edit':
                    return ['view', 'edit'].includes(requiredLevel);
                case 'view':
                    return ['view'].includes(requiredLevel);
                default:
                    return false;
            }
        } else {
            const recursiveHasUserRight = function(key) {
                if (key === "_id") return false;

                const userRightCheck = _.get(userRight, key)
                if (!userRightCheck) {
                    return false;
                }
                if (typeof userRightCheck === 'object') {
                    for (const nestedKey in userRightCheck) {
                        if (recursiveHasUserRight(`${key}.${nestedKey}`)) {
                            return true;
                        }
                    }
                } else {
                    return userRightCheck && userRightCheck !== 'hide';
                }

                return false;
            }
            for(const name in userRight) {
                if (recursiveHasUserRight(name)) {
                    return true;
                }
            }

            return false;
        }
    }
    return {
        hasPrivileges: hasPrivileges,
        hasPayPlan: hasPayPlan,
        hasUiSettings: hasUiSettings,
        hasCampaignsSettings: hasCampaignsSettings,
        hasCollaboratorsSettings: hasCollaboratorsSettings,
        userHasRights: userHasRights,
    };
}])

.factory('$dateFormat', function() {
    return {
        date: function() {
            var d = new Date();
            return d.getDate() + '.' + (d.getMonth() + 1) + '.' + d.getFullYear();
        }
    };
})

.factory('Server', ["$http", "$q", "$timeout", "ToasterService", "CacheFactory", function ($http, $q, $timeout, ToasterService, CacheFactory) {

    var base_url = '';
    var debugRequests = false;
    var debugDelay = 0;

    base_url = '';


    function Server() {
        var me = this;

        /**
         * 
         * @param {'GET'|'POST'|'PATCH'|'DELETE'|'PUT'} method http method
         * @param {string} path api route path
         * @param {Object} data request body
         * @param { ServerRequestOptions } [options]
         * @returns 
         */
        me.request = function(method, path, data, options) {
            var deferred = $q.defer();
            if (!options) {
                options = {}
            }
            var params = {
                url: base_url + '/' + path,
                method: method,
                data: data,
                timeout: options.timeout
            };

            if (method == 'GET') {
                params.params = data;
                const cache = CacheFactory.get("$http");
                if (cache && cache.get && cache.get(params.url)) {
                    params.headers = {
                        "Cache-Control": "max-age=60, stale-while-revalidate, stale-if-error, must-revalidate"
                    };
                    params.cache = true;
                } else {
                    params.headers = {
                        "Cache-Control": "no-cache"
                    };
                    params.cache = false;
                }
            } else {
                if (!options.preserveCache) {
                    const cache = CacheFactory.get("$http");
                    //TODO: check if we can do smarter cache removal, based on matching the start of the POST url to the cached keys
                    if(cache && cache.removeAll) {
                        cache.removeAll();
                    }
                }
            }

            if (debugRequests)
                console.log(params);

            $http( params).then(
                function(response) {
                    if (!response || !response.data) {
                        if (debugRequests)
                            console.log('error_unknown', response);
                        deferred.reject(response);
                        return;
                    }

                    if (response.data.error) {
                        if (debugRequests)
                            console.log('error_val', response);

                        if (response.data.error == -1000 || response.data.error == -1001) {
                            // unauthorized, not logged in
                            console.log('unauthorized err');
                            window.location.replace('/login');
                            //window.location.replace('/login.html');
                        }

                        if (response.data.error == -1002) {
                            // user is suspended
                            window.location.replace('/signin?suspended=true');
                        }
                        
                        if (response.data.error == -1003) {
                            // user is suspended
                            let adminQuery = ''
                            if (response.data.adminUserEmail) {
                                adminQuery = `&admin=${response.data.adminUserEmail}`
                            }
                            window.location.replace('/signin?unauthorized=true'+adminQuery);
                        }

                        deferred.reject(response.data);
                        return;
                    }

                    if (debugRequests)
                       console.log('done', response);

                    if (!debugDelay) {
                        deferred.resolve(response.data);
                    } else {
                        $timeout(function () {
                            deferred.resolve(response.data);
                        }, debugDelay);
                    }

                },
                function(response) {
                    if (debugRequests) {
                        console.log('error', response);
                    }

                    if (response.status === -1 && location.pathname !== '/') {
                        window.location.replace('/');
                    }
                    if (response.data && response.data.error && response.data.error.redirect) {
                        window.location.replace(response.data.error.redirect);
                    }
                    
                    deferred.reject(response);
                }
            );

            return deferred.promise;
        };

        me.makeUrl = function(path) {
            return base_url + '/' + path;
        };
        me.makeResourceUrl = function(path) {
            if (path.indexOf('://') > 0)
                return path;

            return base_url + '/uploads/' + path;
        };

        me.createObject = function(objClass, obj) {
            return me.request('POST', objClass, obj);
        };

        me.getObjects = function(objClass, filter) {
            return me.request('GET', objClass, filter);
        };

        me.updateObject = function(objClass, obj) {
            return me.request('PUT', objClass + '/' + obj._id, obj);
        };

        me.getObject = function(objClass, id) {
            return me.request('GET', objClass + '/' + id);
        };

        me.archiveObject = function(objClass, id) {
            return me.request('POST', objClass + '/' + id + '/archive');
        };
        
        me.unarchiveObject = function(objClass, id) {
            return me.request('POST', objClass + '/' + id + '/unarchive');
        };

        me.deleteObject = function(objClass, id) {
            return me.request('DELETE', objClass + '/' + id);
        };

        /**
         * 
         * @param {string} path 
         * @param {Object} data 
         * @param {ServerRequestOptions} [options] 
         * @returns { Promise }
         */
        me.post = function(path, data, options) {
            return me.request('POST', path, data, options);
        };

        /**
         * 
         * @param {string} path 
         * @param {Object} data 
         * @param {ServerRequestOptions} [options] 
         * @returns { Promise }
         */
        me.patch = function(path, data, options) {
            return me.request('PATCH', path, data, options);
        };

        /**
         * 
         * @param {string} path 
         * @param {Object} data 
         * @param {ServerRequestOptions} [options] 
         * @returns { Promise }
         */
        me.delete = function(path, data, options) {
            return me.request('DELETE', path, data, options);
        };

        me.postWithTimeout = function(path, data, timeout) {
            return me.request('POST', path, data, { timeout: timeout });
        };

        me.get = function(path, data) {
            return me.request('GET', path, data);
        };

        /**
         * 
         * @param {string} path 
         * @param {Object} data 
         * @param {ServerRequestOptions} [options] 
         * @returns { Promise }
         */
        me.put = function(path, data, options) {
            return me.request('PUT', path, data, options);
        };

    }

    return new Server();
}])


.factory('PopupService', ["$fancyModal", "Server", "$q", "overlaySpinner", "Translate", "clipboard", "$rootScope", "toaster", "ToasterService", "Util", "Upload", "socketListener", "igbPublicationFactory", "StageFactory", "NG_QUILL_CONFIG_EMAILS", "MailTemplates", "EventTracker", "multiSelect", "$filter", "$state", function($fancyModal, Server, $q, overlaySpinner, Translate, clipboard, $rootScope, toaster, 
    ToasterService, Util, Upload, socketListener, igbPublicationFactory, StageFactory, NG_QUILL_CONFIG_EMAILS,
    MailTemplates, EventTracker, multiSelect,$filter, $state) {

    var splitEmails = function(txt) {
        var list = [];
        if (txt) {
            txt = txt.replace(/;/g, ',');

            var ar = txt.split(',');
            for (var i = 0; i < ar.length; ++i) {
                if (ar[i] && ar[i].indexOf('@') > 0 && ar[i].indexOf('.') > 0) {
                    var email = ar[i].trim();

                    if (email.indexOf('<') >= 0 && email.indexOf('>') > 0) {
                        email = email.substr(email.indexOf('<') + 1);
                        email = email.substr(0, email.indexOf('>'));
                        email = email.trim();

                    }

                    if (email.length && list.indexOf(email) == -1) {
                        if (!(email.indexOf(';') >= 0 || email.indexOf(' ') >= 0) || email.indexOf('>') >= 0 || email.indexOf('<') >=0) {
                            list.push(email);
                        }
                    }
                }
            }
        }

        return list;
    };

    var serverPostEmails = function(path, emailsList, message, body = {}) {
        var deferred = $q.defer();
        var emails = JSON.parse(JSON.stringify(emailsList));

        var onSendError = function(err) {
            return deferred.reject(err);
        };

        var send = function(response) {
            if (emails.length == 0) {
                deferred.resolve(response);
                return;
            }

            var email = emails.pop();
            Server.post(path, {email: email, text: message, ...body})
                .then(send, onSendError);
            console.log('sending to ' + email);
        };

        send();

        return deferred.promise;
    };


    var service = {
        openAldeliaArchiveCampaign: function(scope, campaign) {
            var deferred = $q.defer();
            campaignStatusValues = [
                'aldelia_campaign_status_successful_placement',
                'aldelia_campaign_status_from_competitor',
                'aldelia_campaign_status_found_themselves',
                'aldelia_campaign_status_cancelled',
                'aldelia_campaign_status_no_feedback',
                'aldelia_campaign_status_internal',
                'aldelia_campaign_status_never_signed',
                'aldelia_campaign_status_duplication',
            ];

            var config = {
                modalClass: 'modal--aldelia-archive-campaign',
                errors: {
                    campaignStatus: ''
                },
                submit: function() {
                    if (!scope.modal.campaignStatus) {
                        scope.modal.errors.campaignStatus = 'campaign_status_required';
                        return;
                    }

                    const overlay = overlaySpinner.show('modal');
                    Server.post('campaigns/' + campaign._id + '/archive', { campaignStatus: scope.modal.campaignStatus })
                        .then(res => {
                            overlay.hide()
                            deferred.resolve(res);
                            scope.modalHandle.close();
                        })
                        .catch(err => {
                            overlay.hide()
                            ToasterService.failure(err, 'err_0_error_occurred');
                        })
                },
                campaign: campaign,
                campaignStatusOptions: campaignStatusValues.map(val => ({ text: Translate.getLangString(val), value: val })),
            }

            service.openGenericPopup(scope, config, 'templates/modal-aldelia-archive-campaign.html', {});

            return deferred.promise;
        },
        assignStatusToACampaign: function(scope, campaign) {
            var deferred = $q.defer();
        
            Server.get('users/' + $rootScope.user.id + '/campaign-status').then(function(response) {
                var campaignStatusValues = response;
                campaignStatusValues.unshift({ _id: 'noCampaignStatusId', label: Translate.getLangString('campaign_no_status_filter') });
                var selectedCampaignStatusId = campaign.campaignStatusId || 'noCampaignStatusId';
                var config = {
                    modalClass: 'modal--assign-campaign-status',
                    errors: {
                        campaignStatus: ''
                    },
                    submit: function() {
                        if (scope.modal.selectedCampaignStatusId === undefined) {
                            scope.modal.errors.campaignStatus = 'campaign_status_required';
                            return;
                        }
                        const overlay = overlaySpinner.show('modal');
                        Server.patch('campaigns/' + campaign._id + '/' + $rootScope.user._id + '/assign-campaign-status/' + scope.modal.selectedCampaignStatusId)
                        .then(res => {
                            overlay.hide();
                            
                            let status = campaignStatusValues.find(s => s._id === res.campaign.campaignStatusId);
                            campaign.campaignStatusId = res.campaign.campaignStatusId;
                            campaign.campaignStatus = campaign.campaignStatus || {};
                            campaign.campaignStatus.label = status?.label;

                            ToasterService.success('campaign_status_assigned');
                            scope.modalHandle.close();

                            deferred.resolve(res);
                        })
                        .catch(err => {
                            overlay.hide();
                            ToasterService.failure(err, 'err_0_error_occurred');
                            deferred.reject(err);
                        });
                    },
                    campaign: campaign,
                    campaignStatusOptions: campaignStatusValues,
                    selectedCampaignStatusId: selectedCampaignStatusId,
                };
                service.openGenericPopup(scope, config, 'templates/modal-assign-campaign.html', {});
            }).catch(function(err) {
                console.error('Failed to load campaign statuses', err);
                deferred.reject(err);
            });
            return deferred.promise;
        },
        requestCampaignValidation: function(scope, campaign) {
            var deferred = $q.defer();
        
            Server.get('campaignsValidators/' + ($rootScope.user.adminId || $rootScope.user.id)).then(function(response) {
                var validatorListOptions = response;
                var config = {
                    modalClass: 'modal--request-campaign-validation',
                    errors: {
                        validatorList: ''
                    },
                    updateSelectedValidatorList: function() {
                        var selectedList = validatorListOptions.find(list => list._id === scope.modal.selectedValidatorListId);
                        scope.modal.selectedValidatorList = selectedList || { validators: [] };
                    },
                    submit: function(recruiterComment) {
                        if (!scope.modal.selectedValidatorListId) {
                            scope.modal.errors.validatorList = 'validator_list_required';
                            return;
                        }
                        const overlay = overlaySpinner.show('modal');
                        Server.post('campaignValidationRequest', { 
                            campaignId: campaign._id, 
                            validatorListId: scope.modal.selectedValidatorListId,
                            recruiterComment: recruiterComment,
                            requesterId: $rootScope.user._id,
                        })
                        .then(res => {
                            overlay.hide();
        
                            campaign.campaignValidatorsListId = scope.modal.selectedValidatorListId;
                            campaign.validationRequestId = res._id;
        
                            ToasterService.success('validation_request_sent');
                            scope.modalHandle.close();
        
                            deferred.resolve(res);
                        })
                        .catch(err => {
                            overlay.hide();
                            ToasterService.failure(err, 'err_0_error_occurred');
                            deferred.reject(err);
                        });
                    },
                    campaign: campaign,
                    validatorListOptions: validatorListOptions,
                    selectedValidatorListId: scope?.modal?.selectedValidatorListId || null,
                    selectedValidatorList: { validators: [] }
                };
                scope.modal = config;
                scope.modal.updateSelectedValidatorList();
                service.openGenericPopup(scope, config, 'templates/modal-request-campaign-validation.html', {});
            }).catch(function(err) {
                console.error('Failed to load validator lists', err);
                deferred.reject(err);
            });
            return deferred.promise;
        },             

        showCampaignValidationStatus: function(scope,campaign, requesterId) {
        
            const validationRequest = {
                status: campaign.validationRequestStatus,
                date: campaign.validationRequestDate,
                voters: campaign.validationRequestVoters.map(voter => {
                    let voterName = Util.userFullName(voter);
                    if ($rootScope.user._id === voter.userId) {
                        voterName += ' ' + Translate.getLangString('ratings_content_you');
                    }
                    return {
                        ...voter,
                        name: voterName,
                        reminderSent: false
                    };
                }),
                recruiterComment: campaign.validationRequestRecruiterComment,
                name: campaign.validationRequestValidatorsListName,
            };

            const isRequester = $rootScope.user._id === requesterId;
            var config = {
                modalClass: 'modal-show-request-campaign-validation-status',
                validationRequest: validationRequest,
                isRequester: isRequester,
                toggleComment: function(validator) {
                    validator.showComment = !validator.showComment;
                },
                emailReminder: async function(validator) {
                    if (confirm(Translate.getLangString('reminder_confirm_to_send').replace('...', validator.name))) {
                        try {
                            const response = await this.sendCampaignValidationEmailReminder(validator.userId, campaign.validationRequestId);
                            validator.reminderSent = true;
                            validator.lastReminderSent = response.lastReminderSent;
                            scope.$apply();
                        
                        } catch (error) {
                            console.error('Error sending reminder', error);
                            ToasterService.failure('reminder_failed');
                        }
                    }
                }.bind(this),
            };
            this.openGenericPopup($rootScope, config, 'templates/modal-show-request-campaign-validation-status.html');
        },

        voteOnCampaignValidationRequest: function(scope, campaign, decision) {
            const userId = $rootScope.user._id;
            scope.modal = config;
            var config = {
                modalClass: 'modal-vote-campaign-validation-request',
                campaign: campaign,
                decision: decision,
                recruiterComment: '',
                submitVote: function(validatorComment) {
                    Server.post('campaignValidationRequest/vote/' + campaign.validationRequestId, {
                        userId,
                        vote: decision,
                        comment: validatorComment
                    })
                    .then(function (response) {
                        ToasterService.success('validation_vote_submitted');
                        scope.modalHandle.close();

                        const voter = campaign.validationRequestVoters.find(voter => voter.userId === userId);
                        if (voter) {
                            voter.vote = decision;
                            voter.comment = validatorComment;
                        } else {
                            campaign.validationRequestVoters.push({
                                userId: userId,
                                vote: decision,
                                comment: validatorComment
                            });
                        }
        
                        const currentUserVote = campaign.validationRequestVoters.find(voter => voter.userId === userId);
                        if (currentUserVote) {
                            scope.currentUserVote = {
                                ...currentUserVote,
                                showVoteButtons: false
                            };
                        }
                        $state.reload()
                    })
                    .catch(function (error) {
                        console.error('Error submitting vote', error);
                        ToasterService.failure('validation_vote_failed');
                    });
                }
                
            }
            this.openGenericPopup($rootScope, config, 'templates/modal-vote-campaign-validation-request.html');
        },

        sendCampaignValidationEmailReminder : function(validatorId, validationRequestId) {
            return Server.post('campaignValidationRequest/send-email-reminder', { validatorId: validatorId, validationRequestId: validationRequestId, currentUserId: $rootScope.user._id })
                .then(res => {
                    ToasterService.success('reminder_email_sent_successfully');
                    console.log("response = ", res);
                    return res;
                })
                .catch(err => {
                    console.error('Failed to send reminder email', err);
                    ToasterService.failure(err, 'failed_to_send_reminder_email');
                });
        },

        openCollaboratorInvite: function(scope, options) {

            var deferred = $q.defer();

            var config = {
                modalClass: 'modal--collaborator-invite',
                submit: function() {
                    console.log('sending invite mail to collaborator: ' + scope.modal.contact);
                    var emails = splitEmails(scope.modal.contact);
                    if (!emails.length || !scope.modal.message) {
                        scope.modal.errorField = 'contact';
                        return;
                    }

                    const newUserData = { rights: scope.modal.rights };
                    if (!scope.modal.rights || !scope.$root.fns.userHasRights(null, null, newUserData)) {
                        ToasterService.failure({}, 'collaborator_needs_user_rights');
                        return;
                    }

                    overlaySpinner.show('modal');

                    serverPostEmails('collaborators/invite', emails, scope.modal.message, newUserData)
                    .then(function(response) {
                        console.log('invite mail sent: ' + emails);
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                        deferred.resolve();
                        ToasterService.success('collaborator_invite_success');
                    }, function(err) {
                        console.log('invite mail not sent ! err=', err);
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                        deferred.reject();
                        ToasterService.failure(err, 'user_not_updated');
                    });
                },
                message: Translate.getLangString('hello') + ",\n\n" + Translate.getLangString('collaborator_invite_template_body'),
                rights: {},
            };

            if (options) {
                angular.extend(config, options);
            }

            service.openGenericPopup(scope, config, 'templates/modal-invite-collaborator.html', {});

            return deferred.promise;
        },

        openCandidateInvite: function(scope, candidates) {
            var deferred = $q.defer();

            function loadCampaigns () {
                Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true&fetchCompanyInformation=true')
                .then(function(campaigns) {
                    campaigns = Util.sortByDisplayedTitleAndArchiveStatus(campaigns, $rootScope.user);
                    campaigns = campaigns
                        .filter(campaign => campaign.isActive)
                        .map(campaign => ({
                            label: Util.getDisplayedTitleAndArchiveStatus(campaign, $rootScope.user),
                            ...campaign
                        }));
                    scope.modal.ddInviteToCampaignOptions = campaigns;
                });
            };

            function ddSelectCampaign(selectedCampaign) {
                const companyName = selectedCampaign.customization.employerBranding.companyInformation.name;
                const campaignTitle = selectedCampaign.title[selectedCampaign.language];
                scope.modal.message = Translate.getLangString('hello', selectedCampaign.language)
                + ",\n\n" + Translate.getLangString('candidate_invite_template_body', selectedCampaign.language);
                scope.modal.message = scope.modal.message.replace('...', companyName); 
                scope.modal.message = scope.modal.message.replace('...', campaignTitle);
            };

            var config = {
                modalClass: 'modal--candidate-invite',
                candidatesEmails: candidates.map(candidate => candidate.email).join(', '),
                loadCampaigns: loadCampaigns,
                ddSelectedInviteToCampaign: candidates.length <= 1 ? {
                    label: Util.getDisplayedTitleAndArchiveStatus(candidates[0].campaign, $rootScope.user),
                    ...candidates[0].campaign
                } : undefined,
                ddSelectCampaign: ddSelectCampaign,
                submit: function() {
                    scope.modal.errors = {};
                    var emails = splitEmails(scope.modal.candidatesEmails);
                    if (!emails.length) {
                        scope.modal.errors.contact = true;
                        return;
                    }
                    if (!scope.modal.ddSelectedInviteToCampaign) {
                        scope.modal.errors.campaign = true;
                        return;
                    }
                    if (!scope.modal.message) {
                        scope.modal.errors.message = true;
                        return;
                    }

                    overlaySpinner.show('modal');

                    serverPostEmails('campaigns/' + scope.modal.ddSelectedInviteToCampaign._id + '/invite', emails, scope.modal.message)
                    .then(function() {
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                        deferred.resolve();
                        toaster.success({
                            body: Translate.getLangString('candidate_invitation_well_sent'),
                        });
                    });
                },
            };

            // if (options) {
            //     angular.extend(config, options);
            // }

            service.openGenericPopup(scope, config, 'templates/modal-invite-candidate.html', {});

            return deferred.promise;
        },

        opentInviteToAssessFirst: function(scope, candidates, platform, cb) {
            var deferred = $q.defer();

            let alreadyInvitedCandidates;
            let notYetInvitedCandidates;
            let makeInviteRoute;
            let makeReinviteRoute;
            let isAssFir = false;
            let isDocimo = false;
            if(platform === 'assessfirst') {
                isAssFir = true
                makeInviteRoute = candidateId => `assessfirst-integration/${candidateId}/createUser`;
                makeReinviteRoute = candidateId => `assessfirst-integration/${candidateId}/reinvite`;
                alreadyInvitedCandidates = candidates.filter(c => c.assessfirstUser);
                notYetInvitedCandidates = candidates.filter(c => !c.assessfirstUser);
            } else if (platform === 'docimo') {
                isDocimo = true;
                makeInviteRoute = candidateId => `docimo-integration/${candidateId}/invite`;
                makeReinviteRoute = candidateId => `docimo-integration/${candidateId}/reinvite`;
                alreadyInvitedCandidates = candidates.filter(c => c.docimoUser);
                notYetInvitedCandidates = candidates.filter(c => !c.docimoUser);
            }

            var config = {
                modalClass: 'modal--candidate-invite',
                candidates: candidates,
                alreadyInvitedCandidates: alreadyInvitedCandidates,
                notYetInvitedCandidates: notYetInvitedCandidates,
                isAssFir: isAssFir, 
                isDocimo: isDocimo,
                submit: async function() {
                    overlaySpinner.show('modal');
                    let invitedCandidates = [];
                    let reInvitedCandidates = [];
                    let errors = [];
                    for(let i = 0 ; i < notYetInvitedCandidates.length ; i++) {
                        await Server.get(makeInviteRoute(candidates[i]._id))
                        .then(function(invitedCandidate) {
                            invitedCandidates.push(invitedCandidate);
                        }, function(err) {
                            errors.push(err);
                        });
                    }
                    if(scope.modal.sendReminder) {
                        for(let i = 0 ; i < alreadyInvitedCandidates.length ; i++) {
                            await Server.get(makeReinviteRoute(candidates[i]._id))
                            .then(function(reInvitedCandidate) {
                                reInvitedCandidates.push(reInvitedCandidate);
                            }, function(err) {
                                errors.push(err);
                            });
                        }
                    }

                    if(invitedCandidates.length) {
                        if(invitedCandidates.length === 1) {
                            ToasterService.success('submission_assessment_toaster_invitation_success', [Util.userFullName(invitedCandidates[0])]);
                        } else {
                            ToasterService.success('submission_assessment_toaster_bulk_invite', [invitedCandidates.length]);
                        }
                    }
                    if(reInvitedCandidates.length) {
                        if(reInvitedCandidates.length === 1) {
                            ToasterService.success('submission_assessment_toaster_reminder_success', [Util.userFullName(reInvitedCandidates[0])]);
                        } else {
                            ToasterService.success('submission_assessment_toaster_bulk_reminders', [reInvitedCandidates.length]);
                        }
                    }
                    if(errors.length) {
                        if(errors.length === 1) {
                            ToasterService.failure(errors[0], 'submission_assessment_toaster_re_invite_error', [Util.userFullName(invitedCandidates[0])]);
                        } else {
                            console.error('errors on invite', errors);
                            ToasterService.failure({}, 'submission_assessment_toaster_bulk_errors', [errors.length]);
                        }
                    }
                    cb(invitedCandidates.concat(reInvitedCandidates));
                    scope.modalHandle.close();
                    overlaySpinner.hide('modal');
                },
            };
            service.openGenericPopup(scope, config, 'templates/modal-invite-to-assessfirst.html', {});
            return deferred.promise;
        },
        
        openCandidateCopy: function(scope, candidates) {
            var deferred = $q.defer();

            Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true')
            .then(function(allCampaigns) {
                scope.modal.ddInviteToCampaignOptions = Util.sortByDisplayedTitleAndArchiveStatus(allCampaigns, $rootScope.user);
                scope.modal.ddInviteToCampaignOptions = scope.modal.ddInviteToCampaignOptions
                    .filter(campaign => {
                        return $rootScope.campaign ? campaign._id !== $rootScope.campaign._id : true;
                    })
                    .map(campaign => ({
                        label: Util.getDisplayedTitleAndArchiveStatus(campaign, $rootScope.user),
                        ...campaign
                    }));
            });

            var config = {
                modalClass: 'modal--candidate-copy',
                submit: function() {
                    let campaign = scope.modal.ddSelectedCopyToCampaign;
                    if (!campaign || !candidates || !candidates.length) {
                        console.error('!campaign || !candidates || !candidates.length');
                        return;
                    }
                    overlaySpinner.show('modal');
                    var chainedPromise = $q.when();
                    let errors = [];
                    
                    _.forEach(candidates, candidate => {
                        chainedPromise = chainedPromise
                        .then(function() {
                            return Server.put('candidates/' + candidate._id + '/campaigns/'+ campaign._id);
                        }).catch(function(err) {
                            console.error(err);
                            errors.push(err);
                        });
                    });

                    return chainedPromise
                    .then(function () {
                        if(errors && errors.length == candidates.length) {
                            // FAILURE
                            console.log('errors.length = ', errors.length);
                            if(candidates.length > 1) {
                                ToasterService.failure(undefined, 'candidates_not_copied', [candidates.length]);
                            } else {
                                ToasterService.failure(errors[0], 'candidate_not_copied');
                            }
                        } 
                        else if(errors && errors.length) {
                            // PARTIAL SUCCESS
                            ToasterService.warning('candidates_partially_copied', [candidates.length - errors.length, candidates.length, Util.getDisplayedTitle(campaign, $rootScope.user)]);
                            scope.modalHandle.close();
                        }
                        else if (!errors || !errors.length) {
                            // SUCCESS
                            if(candidates.length > 1) {
                                ToasterService.success('candidates_well_copied', [candidates.length, Util.getDisplayedTitle(campaign, $rootScope.user)]);
                            } else {
                                ToasterService.success('candidate_well_copied', [Util.getDisplayedTitle(campaign, $rootScope.user)]);
                            }
                            scope.modalHandle.close();
                        }
                        overlaySpinner.hide('modal');
                    });
                },
            };

            service.openGenericPopup(scope, config, 'templates/modal-copy-candidate.html', {});

            return deferred.promise;
        },

        openAddCandidatePopUp: function(scope, options, campaign) {
            var deferred = $q.defer();
            createNewCandidate();
            loadStages();

            scope.resetErrors = function() {
                scope.errorFields = {};
            };

            (function setGenders() {
                scope.genders = [
                    {value: 'f', label: Translate.getLangString('gender_female')},
                    {value: 'm', label: Translate.getLangString('gender_male')},
                    {value: 'x', label: Translate.getLangString('gender_x')},
                ];
            })();

            function createNewCandidate () {
                Server.post(`campaigns/${campaign._id}/candidates`, {step: 0})
                .then(function (candidate) {
                    scope.newCandidate = candidate;
                    setNewCandidateStage();
                });
            }

            function setNewCandidateStage(stage) {
                if (!scope.newCandidate || !scope.stages) {
                    return;
                }
                if (!stage) {
                    stage = scope.stages.filter(stg => stg.accessible)[0];
                }
                scope.newCandidate.currentStage = stage || scope.stages[0];
                scope.newCandidate.stage = scope.newCandidate.currentStage ? scope.newCandidate.currentStage.key : undefined;
            }

            function loadStages() {
                if (!scope.stages) {
                    StageFactory.getAllStagesDd()
                        .then(stages => {
                            scope.stages = stages.map(stg => ({
                                ...stg,
                                id: stg._id,
                                style: stg.customization,
                            }))
                            setNewCandidateStage();
                        })
                } else {
                    setNewCandidateStage()
                }
            }

            function saveCandidate() {
                overlaySpinner.show('modal');
                if (!scope.validateInputs()) {
                    EventTracker.trackCampaignCandidateSave();
                    scope.newCandidate.step = 5;
                    scope.newCandidate.referralSource = 'recruiterManuallyAdded';
                    scope.newCandidate.addedByUserId = $rootScope.user._id;
                    Server.put(`candidates/${scope.newCandidate._id}`, scope.newCandidate)
                        .then(function (candidate) {
                            ToasterService.success('candidate_well_updated');
                            scope.modalHandle.close();
                            overlaySpinner.hide('modal');
                            window.location.reload();
                        },function (err) {
                            ToasterService.failure(err, 'candidate_not_updated');
                            overlaySpinner.hide('modal');
                        });
                }
                overlaySpinner.hide('modal');
            }

            scope.validateInputs = function() {
                scope.resetErrors();
                var emailConstraints = {from: {email: true}};
                if(!scope.newCandidate.email || validate({from: scope.newCandidate.email}, emailConstraints)) {
                    scope.errorFields.email = true;
                }
                if (scope.newCandidate.address && scope.newCandidate.address.name && !scope.newCandidate.address.country) {
                    // if there is no country it means that no google address options has been selected
                    scope.errorFields.address = true;
                }
                return !_.isEmpty(scope.errorFields);
            }

            // duplicate code (reference 1345)
            scope.uploadDoc = function (files) {
                if (!files || files.length === 0) {
                    return;
                }
                Upload
                    .upload({
                        url: Server.makeUrl('users/' + $rootScope.user._id + '/addDocumentToSubmission/' + scope.newCandidate._id),
                        data: { files: files },
                        arrayKey: '[]',
                    })
                    .then(function (resp) {
                        scope.newCandidate.documents = resp.data.documents;
                    }, function (err) {
                        ToasterService.failure(err)
                    });
            };
            scope.docFilterFn = function (doc) {
                if($rootScope.fns.hasUiSettings(['showDuplicatePdf'])) {
                    return doc.downloadFilename;
                }
                return doc.downloadFilename && !doc.isPdfConversion;
            };

            var config = {
                modalClass: 'modal--edit-candidate',
                ddStageClick: setNewCandidateStage,
                submit: function() {
                    if (scope.newCandidate.currentStage && !scope.newCandidate.currentStage.accessible) {
                        //scope.modalScope = scope.$root.$new();
                        service.openGenericPopup(scope, {
                            submit: async function () {
                                scope.modalHandle.close();
                                saveCandidate();
                            },
                            warningText: Translate.getLangString('candidate_to_forbidden_stage_warning', null, [scope.newCandidate.currentStage.text]),
                            yesText: Translate.getLangString('set_stage'),
                            noText: Translate.getLangString('cancel')
                        }, 'templates/modal-confirm-warning.html', {});
                    }
                    else {
                        saveCandidate();
                    }
                }
            };

            if (options) {
                angular.extend(config, options);
            }
            service.openGenericPopup(scope, config, 'templates/modal-add-candidate.html', {});
            return deferred.promise;
        },

        openCandidateEdit: function(scope, options, candidateId) {

            var deferred = $q.defer();
            initEditCandidate();
            scope.resetErrors = function() {
                scope.errorFields = {};
            }
            scope.resetLocation = function() {
                scope.editCandidate.address = {name: scope.editCandidate.address.name};
            }
            scope.validateInputs = function() {
                scope.resetErrors();
                var emailConstraints = {from: {email: true}};
                if(!scope.editCandidate.email || validate({from: scope.editCandidate.email}, emailConstraints)) {
                    scope.errorFields.email = true;
                }
                if (scope.editCandidate.address && scope.editCandidate.address.name && !scope.editCandidate.address.country) {
                    // if there is no country it means that no google address options has been selected
                    scope.errorFields.address = true;
                }
                return !_.isEmpty(scope.errorFields);
            }
            function initEditCandidate() {
                scope.editCandidate = JSON.parse(JSON.stringify(scope.candidate));
            }
            Util.loadReferralSources(true)
            .then(function(refSources){
                scope.refSources = refSources;
            });
            (function setGenders() {
                scope.genders = [
                    {value: 'f', label: Translate.getLangString('gender_female')},
                    {value: 'm', label: Translate.getLangString('gender_male')},
                    {value: 'x', label: Translate.getLangString('gender_x')},
                ];
            })();
            var config = {
                modalClass: 'modal--edit-candidate',
                submit: function() {
                    overlaySpinner.show('modal');
                    if (!scope.validateInputs()) {
                        Server.put('candidates/'+ candidateId, scope.editCandidate)
                            .then(function (candidate) {
                                ToasterService.success('candidate_well_updated');
                                scope.candidate = candidate;
                                initEditCandidate();
                                scope.modalHandle.close();
                                overlaySpinner.hide('modal');
                            },function (err) {
                                ToasterService.failure(err, 'candidate_not_updated');
                                overlaySpinner.hide('modal');
                            });
                    }
                    overlaySpinner.hide('modal');
                }
            };

            if (options) {
                angular.extend(config, options);
            }
            service.openGenericPopup(scope, config, 'templates/modal-edit-candidate.html', {});
            return deferred.promise;
        },

        openCollectCandidatesPopUp: function(scope, options, campaign) {

            var deferred = $q.defer();
            scope.campaign = campaign;
            const companyName = campaign?.customization?.employerBranding?.companyInformation?.name;
            var invitationEmailBody = Translate.getLangString('hello', campaign.language)
            + "\n\n" + Translate.getLangString('candidate_invite_template_body', campaign.language);
            invitationEmailBody = invitationEmailBody.replace('...', companyName);
            invitationEmailBody = invitationEmailBody.replace('...', campaign.title[campaign.language]);
            let igbDataLoaded = false;
            scope.selectedCollectionMethodId = 'publicLink';
            let brandingLoaded = false;
            options.employerBrandings = [];

            scope.changeCollectionMethod = function(collectionMethodId){
                scope.selectedCollectionMethodId = collectionMethodId;
                if(collectionMethodId == 'jobboards') {
                    loadIgbData();
                }
                if (collectionMethodId === 'talentplug') {
                    scope.modal.talentplug.loadTalentplugData();
                }
                scope.modal.activeTab = collectionMethodId;
                let growDiv = $(`#${collectionMethodId}`).parents(".candidate-collection-method");
                let allDivs = $(".candidate-collection-method");
                _.forEach(allDivs, function(div){
                    // $(div).css('display', 'none');
                    $(div).css('max-height', '120px');
                    $(div).css('z-index', '0');
                    $(div).css('opacity', '0');
                    $(div).css('padding', '0');
                    $(div).css('position', 'absolute');
                    $(div).css('transition', 'max-height 1s linear, opacity 0s ease-in-out');
                });
                // growDiv.css('display', 'block');
                growDiv.css('min-height', '190px');
                growDiv.css('max-height', '1500px');
                growDiv.css('z-index', '1');
                growDiv.css('opacity', '1');
                growDiv.css('padding', '20px');
                growDiv.css('position', 'relative');
                growDiv.css('transition', 'max-height 0.8s linear, opacity 0.8s ease-in-out');
            };

            function getIgbPublicationStatus() {
                Server.get('igb-integration/getCampaignPublicationStatus/' + campaign._id)
                .then(function(res) {
                    scope.modal.jobboards.publicationStatus = igbPublicationFactory.getStatusInArray(res.publicationStatus);
                },
                function(err) {
                    ToasterService.failure(err, 'err_43_igb_status_error');
                });
                Server.get('campaigns/' + campaign._id + '?loadJobPostingPublicationStatus=eager')
                .then(function(campaignFull) {
                    scope.campaign.igbStatus = igbPublicationFactory.getStatus(campaignFull?.igb?.publicationStatus?.status);
                });
            }

            function getIgbLinkToCampaignPosting() {
                Server.get('igb-integration/getLinkToCampaignPosting/' + campaign._id)
                .then(function(res) {
                    $('#igbIframe').attr('src', res.link);
                },
                function(err) {
                    ToasterService.failure(err, 'err_40_igb_unknown_error');
                });
                getIgbPublicationStatus();
            }

            function loadBrandingData() {
                if (brandingLoaded)
                    return;

                Server.get('employer-brandings')
                    .then(res => {
                        config.careerPage.employerBrandings = res.map(branding => {
                            branding.checked = scope.campaign.careerPage.employerBrandingsIds.includes(branding._id);
                            return branding;
                        });
                        brandingLoaded = true;
                    })
                    .catch(err => {
                        ToasterService.failure(err, 'load_brandings_error');
                    })
            }

            function loadIgbData() {
                if (igbDataLoaded)
                    return;
                igbDataLoaded = true;
                getIgbPublicationStatus();
                getIgbLinkToCampaignPosting();
            }

            function onPopUpLoaded() {
                scope.changeCollectionMethod(options.activeTab || 'publicLink');
            }

            setTimeout(onPopUpLoaded, 500);

            var config = {
                modalClass: 'modal--collect-candidates',
                activeTab: options.activeTab || 'publicLink',
                jobboards: {
                    publicationStatus: {},
                    showPublicationStatus: function() {
                        var growDiv = document.getElementById('status__table');
                        if (growDiv.clientHeight) {
                            growDiv.style.height = 0;
                        } else {
                            growDiv.style.height = growDiv.scrollHeight + "px";
                        }
                    },
                    refreshStatus: getIgbPublicationStatus,
                    refreshIgbIframe: getIgbLinkToCampaignPosting,
                },
                talentplug: {
                    offer: undefined,
                    offerUrl: undefined,
                    errors: undefined,
                    offerId: undefined,
                    setErrors: function(responseData) {
                        if (!responseData || !responseData.errors || !responseData.errors.length) {
                            scope.modal.talentplug.errors = [];
                        }
                        else {
                            scope.modal.talentplug.errors = responseData.errors.map(x => x.reason);
                        }
                    },
                    loadTalentplugData: function() {
                        const offerId = scope.modal.talentplug.offerId || _.get(scope.campaign, 'talentplug.offerId');
                        if (!offerId) {
                            return;
                        }

                        const overlay = overlaySpinner.show("talentplug")
                        Server.get('talentplug-integration/offers/'+ offerId)
                            .then((res) => {
                                scope.modal.talentplug.offer = res;
                                scope.modal.talentplug.offerId = res.offerKeyId;
                                scope.modal.talentplug.setErrors();
                                overlay.hide();
                            }).catch(err => {
                                ToasterService.failure(err, 'talentplug_load_error');
                                scope.modal.talentplug.offer = null;
                                scope.modal.talentplug.setErrors(err.data);
                                overlay.hide();
                            });
                    },
                    closeModal: function() {
                        scope.modal.talentplug.offerUrl = null;
                        $('#talentplugIframe').attr('src', scope.modal.talentplug.offerUrl);
                        document.getElementById('talentplugModal').close();
                        scope.modal.talentplug.setErrors();
                        scope.modal.talentplug.loadTalentplugData();
                    },
                    postCampaign: function() {
                        const overlay = overlaySpinner.show("talentplug")
                        Server.post('talentplug-integration/post-campaign/' + scope.campaign._id)
                            .then((res) => {
                                overlay.hide();
                                scope.modal.talentplug.setErrors();
                                scope.modal.talentplug.offerId = res.offerKeyId;
                                _.set(scope.campaign, 'talentplug.offerId', res.offerKeyId);
                                scope.modal.talentplug.offerUrl = res.offerUrl;
                                document.getElementById('talentplugModal').showModal();
                                $('#talentplugIframe').attr('src', scope.modal.talentplug.offerUrl);
                            }).catch(err => {
                                scope.modal.talentplug.offer = null;
                                scope.modal.talentplug.setErrors(err.data);
                                overlay.hide();
                            });
                    },
                    republishOffer: function() {
                        service.openGenericPopup(scope.$new(), {
                            submit: function () {
                                this.close();
                                const overlay = overlaySpinner.show("talentplug")
                                Server.post('talentplug-integration/republish-campaign/' + scope.campaign._id)
                                    .then((res) => {
                                        overlay.hide();
                                        ToasterService.success('talentplug_offer_republished');
                                        scope.modal.talentplug.offerUrl = null;
                                        document.getElementById('talentplugModal').showModal();
                                        $('#talentplugIframe').attr('src', scope.modal.talentplug.offerUrl);
                                        scope.modal.talentplug.setErrors();
                                        scope.modal.talentplug.loadTalentplugData();
                                    })
                                    .catch(err => {
                                        overlay.hide();
                                        ToasterService.failure(err, 'talentplug_offer_republish_failed');
                                        scope.modal.talentplug.setErrors(err.data);
                                    });
                            },
                            title: Translate.getLangString('confirmation'),
                            warningText: Translate.getLangString('republish_offer_confirmation_warning'),
                            yesText: Translate.getLangString('confirm'),
                            noText: Translate.getLangString('cancel')
                        }, 'templates/modal-confirm-warning.html', {});
                    },
                    deleteOffer: function() {
                        service.openGenericPopup(scope.$new(), {
                            submit: function () {
                                this.close();
                                const overlay = overlaySpinner.show("talentplug")
                                Server.delete('talentplug-integration/unpublish-campaign/' + scope.campaign._id)
                                    .then((res) => {
                                        overlay.hide();
                                        ToasterService.success('talentplug_offer_deleted');
                                        scope.modal.talentplug.setErrors();
                                        scope.modal.talentplug.offer = null;
                                        scope.modal.talentplug.offerId = null;
                                        scope.modal.talentplug.offerUrl = null;
                                        scope.campaign.talentplug.offerId = res.offerKeyId;
                                    }).catch(err => {
                                        scope.modal.talentplug.setErrors(err.data);
                                        ToasterService.failure(err, 'talentplug_offer_deleted_failed');
                                        overlay.hide();
                                    });
                            },
                            title: Translate.getLangString('confirmation'),
                            warningText: Translate.getLangString('delete_offer_confirmation_warning'),
                            yesText: Translate.getLangString('confirm'),
                            noText: Translate.getLangString('cancel')
                        }, 'templates/modal-confirm-warning.html', {});
                    },
                },
                publicLink: {
                    showAdvanced: false,
                    copyLink: function() {
                        clipboard.copyText(scope.campaign.inviteLink);
                        EventTracker.trackCampaignPostJobPublicLink();
                        toaster.success({
                            body: Translate.getLangString('link_copied'),
                            timeout: 2000,
                        });
                    },
                    copyFormLink: function() {
                        clipboard.copyText(scope.campaign.inviteFormLink);
                        toaster.success({
                            body: Translate.getLangString('link_copied'),
                            timeout: 2000,
                        });
                    },
                },
                careerPage: {
                    employerBrandings: [],
                    onCheckboxChange: function(brandingId) {
                        overlaySpinner.show('modal');
                        return Server.post(`campaigns/${scope.campaign._id}/careerPage/${brandingId}`).then(function(res) {
                            overlaySpinner.hide('modal');
                            if (res.employerBrandingsIds.includes(brandingId)) {
                                EventTracker.trackCampaignPostJobCareerPage();
                                scope.campaign.careerPage = res;
                                ToasterService.success('candidate_collection_career_page_toaster_publish');
                            } else {
                                scope.campaign.careerPage = res;
                                ToasterService.success('candidate_collection_career_page_toaster_remove');
                            }
                        });
                    },
                },
                qrCode: {
                    url: window.location.origin + '/campaigns/' + campaign._id + '/qrCode',
                    filename: 'QR Code - ' + campaign.title[campaign.language] + '.png',
                    trackDownloadQrCode: function() {
                        EventTracker.trackCampaignPostJobQrCode();
                    }
                },
                invitationEmail: {
                    body: invitationEmailBody,
                    send: function() {
                        EventTracker.trackCampaignPostJobInviteEmail();
                        var emails = splitEmails(scope.modal.invitationEmailContactAddress);
                        if (!emails.length || !scope.modal.invitationEmail.body) {
                            scope.modal.errorField = 'contact';
                            return;
                        }
                        overlaySpinner.show('modal');
                        serverPostEmails('campaigns/' + campaign._id + '/invite', emails, scope.modal.invitationEmail.body)
                            .then(function() {
                                toaster.success({
                                    body: Translate.getLangString('candidate_invitation_well_sent'),
                                });
                                scope.reloadCandidatesList();
                                overlaySpinner.hide('modal');
                                scope.modalHandle.close();
                                deferred.resolve();
                            });
                    },
                },
                marketingHR: {
                    region: campaign?.location?.city,
                    profile: campaign.title[campaign.language],
                    numberOfJobs: 1,
                    startDate: '',
                    additionalInformation: '',
                    send: function() {
                        EventTracker.trackCampaignPostJobHRMarketing()
                        if (!scope.modal.marketingHR.region) {
                            scope.modal.errorField = 'region';
                            return;
                        }
                        if (!scope.modal.marketingHR.profile || scope.modal.marketingHR.profile.length > 128) {
                            scope.modal.errorField = 'profile';
                            return;
                        }
                        if (!scope.modal.marketingHR.numberOfJobs) {
                            scope.modal.errorField = 'numberOfJobs';
                            return;
                        }
                        overlaySpinner.show('modal');
                        Server.post('campaigns/' + campaign._id + '/marketingHR', {
                            region: scope.modal.marketingHR.region,
                            profile: scope.modal.marketingHR.profile,
                            numberOfJobs: scope.modal.marketingHR.numberOfJobs,
                            startDate: scope.modal.marketingHR.startDate,
                            additionalInformation: scope.modal.marketingHR.additionalInformation,
                        })
                        .then(function() {
                            toaster.success({
                                body: Translate.getLangString('candidate_collection_marketing_hr_confirmation'),
                            });
                            overlaySpinner.hide('modal');
                            scope.modalHandle.close();
                            deferred.resolve();
                        }, function (err) {
                            ToasterService.failure(err, 'err_0_error_occurred');
                            overlaySpinner.hide('modal');
                        });
                    },
                },
            };

            if (options) {
                angular.extend(config, options);
            }

            Promise.all([
                loadBrandingData()
            ]).then(() => {
                service.openGenericPopup(scope, config, 'templates/modal-collect-candidates.html', {});
            })

            return deferred.promise;
        },

        openCsvImportPopup: function(scope, options) {

            var deferred = $q.defer();

            let importSteps = [
                'step-0-import',
                'step-1-group',
                'step-2-end',
            ];

            let groupActions = [{
                    value: 'import_action_add_to_existing',
                    label: Translate.getLangString('csv_import_step_1_option_add_to_existing'),
                },{
                    value: 'import_action_create',
                    label: Translate.getLangString('csv_import_step_1_option_create_new'),
                },{
                    value: 'import_action_dont_add',
                    label: Translate.getLangString('csv_import_step_1_option_dont_add'),
                },
            ];

            var config = {
                modalClass: 'modal--import-csv',
                importSteps: importSteps,
                importStep: importSteps[0],
                csvId: '',
                csvStatus: '',
                csvGroups: [],
                headerErrors: '',
                errorCsvFileName: '',
                errorCsvString: '',
                numberOfCandidates: 0,
                templateCsvLink: window.location.origin + '/users/me/csv-imports/downloadTemplate',
                groupActions: groupActions,
                importCsv: function(file) {
                    if(!file) {
                        return;
                    }
                    EventTracker.trackCandidateCsvImport();
                    overlaySpinner.show('candidates');
                    scope.modal.headerErrors = '';
                    scope.modal.errorCsvFileName = '';
                    scope.modal.errorCsvString = '';

                    Upload.upload({
                        url: Server.makeUrl(`users/${scope.user._id}/csv-imports`),
                        data: {
                            file: file
                        }
                    }).then(function (resp) {
                        if (resp && resp.status == 201 && resp.data && resp.data.id) {
                            scope.modal.csvId = resp.data.id;
                            scope.modal.csvStatus = resp.data.status;

                            if(resp.data.status === 'waitingGroupsAssociations') {
                                scope.modal.csvGroups = resp.data.groups;
                                scope.modal.numberOfCandidates = resp.data.numberOfCandidates;
                                scope.modal.importStep = importSteps[1];
                                Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true').then(function(allCampaigns) {
                                    scope.modal.campaigns = Util.sortByDisplayedTitleAndArchiveStatus(allCampaigns, $rootScope.user);
                                })
                            }
                        }
                        overlaySpinner.hide('candidates');
                    }, function (resp) {
                        if(resp && resp.data) {
                            if(_.isArray(resp.data)) {
                                scope.modal.headerErrors = resp.data;
                            } else {
                                scope.modal.errorCsvFileName = file.name.slice(0, -4) + " (+ Errors)" + file.name.slice(-4);;
                                scope.modal.errorCsvString = resp.data;
                            }
                        }
                        overlaySpinner.hide('candidates');
                    });
                },
                onGroupActionChange: function(group) {
                    if(group.action === 'import_action_create') {
                        let myGroup = _.find(scope.modal.csvGroups, function(csvGroup) {
                            return csvGroup._id == group._id;
                        });
                        myGroup.campaignName = group.groupName;
                    }
                },
                associateGroups: function(isValid) {

                    scope.modal.triedToSubmit = true;

                    if (!isValid)
                        return;

                    Server.put(`users/${scope.user._id}/csv-imports/${scope.modal.csvId}/groups`, {
                        groups: scope.modal.csvGroups
                    })
                    .then(function (resp) {
                        console.log('resp', resp)
                        ToasterService.success('csv_import_step_2_import_done');
                        scope.modal.importStep = importSteps[2];
                    }, function (err) {
                        ToasterService.failure(err, 'err_50_csv_import_group');
                        console.log('error', err)
                    });
                },
            };

            if($rootScope.user) {
                socketListener.subscribe($rootScope.user._id);
            }

            scope.$on('socketRecruiterEvent', function(event, socketData) {
                if(socketData.message = 'csv_import_update') {
                    scope.modal.afterImportMsg = socketData;
                    scope.$apply('modal.afterImportMsg');
                    scope.reloadCandidatesList();
                }
            });

            if (options) {
                angular.extend(config, options);
            }

            service.openGenericPopup(scope, config, 'templates/modal-import-csv.html', {});

            return deferred.promise;
        },

        openCollaboratorMessage: function(scope, options, collaboratorId) {

            var deferred = $q.defer();

            var config = {
                modalClass: 'modal--collaborator-message',
                submit: function() {

                    if (!scope.modal.message) {
                        scope.modal.errorField = 'message';
                        return;
                    }

                    overlaySpinner.show('modal');
                    Server.post('collaborators/' + collaboratorId + '/message', {text: scope.modal.message}).then(function() {
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                        deferred.resolve();
                        toaster.success({
                            body: Translate.getLangString('email_well_send'),
                        });
                    });
                }
            };

            if (options) {
                angular.extend(config, options);
            }

            service.openGenericPopup(scope, config, 'templates/modal-message-collaborator.html', {});

            return deferred.promise;
        },

        openUserRights: function(scope, user) {
            var deferred = $q.defer();

            var config = {
                modalClass: 'modal--collaborator-invite',
                title: Translate.getLangString('manage_user_rights'),
                submit: function() {
                    if (!scope.modal.user.rights || !scope.$root.fns.userHasRights(null, null, scope.modal.user)) {
                        ToasterService.failure({}, 'collaborator_needs_user_rights');
                        return;
                    }

                    overlaySpinner.show('modal');
                    const rights = scope.modal.user.rights;
                    Server.put('users/' + scope.modal.user._id + '/rights', { rights })
                        .then(function() {
                            overlaySpinner.hide('modal');
                            ToasterService.success('user_rights_saved');
                            scope.modalHandle.close();
                            scope.modal.user = undefined;
                            deferred.resolve(rights);
                        }).catch(err => {
                            overlaySpinner.hide('modal');
                            ToasterService.failure(err, 'failed_to_update_user_rights');
                        });
                },
                user,
            };

            service.openGenericPopup(scope, config, 'templates/modal-user-rights.html', {});

            return deferred.promise;
        },

        /**
         * 
         * @param {any} scope 
         * @param {{
         *  modalTitle: string,
         *  sourceItem: any,
         *  targets: any[],
         *  getLabel: (target) => string,
         *  isAssigned: (source, target) => boolean,
         *  onAssign: (targetId: string) => Promise<void>,
         *  onUnassign: (targetId: string) => Promise<void>
         * }} options 
         * @returns { Promise<void> }
         */
        openAssignableData: function(scope, { modalTitle, sourceItem, targets, hasFullAccess, fullAccessMessage, showListWithFullAccess, getLabel, isAssigned, onAssign, onUnassign }) {
            var deferred = $q.defer();
            const targetOptions = targets.map(target => {
                return {
                    ...target,
                    label: getLabel(target),
                    checked: isAssigned(sourceItem, target),
                }
            });

            var config = {
                modalClass: 'modal--campaign-selection',
                modalTitle: modalTitle,
                hasFullAccess,
                fullAccessMessage,
                showListWithFullAccess,
                close: function() {
                    scope.modalHandle.close();
                    deferred.resolve(targetOptions.filter(camp => camp.checked));
                },
                saveTarget: function(target) {
                    if (target.checked) {
                        return onAssign(target._id);
                    } else {
                        return onUnassign(target._id);
                    }
                },
                toggleTargetSelection: async function(target) {
                    const overlay = overlaySpinner.show('campaign-selection');
                    const togglePromise = $q.defer();
                    try {
                        const result = await scope.modal.saveTarget(target)
                        if (result && result.then) {
                            result.then(() => {
                                overlay.hide();
                                ToasterService.success(target.checked ? 'assign_items_success' : 'unassign_items_success', [target.label]);
                            }).catch(err => {
                                overlay.hide();
                                ToasterService.failure(err, target.checked ? 'assign_items_fail' : 'unassign_items_fail', [target.label])
                                target.checked = !target.checked;
                                scope.modal.syncSelectAll();
                            });
                        } else {
                            overlay.hide();
                            ToasterService.success(target.checked ? 'assign_items_success' : 'unassign_items_success', [target.label]);
                        }
                    } catch (err) {
                        overlay.hide();
                        ToasterService.failure(err, target.checked ? 'assign_items_fail' : 'unassign_items_fail', [target.label])
                        target.checked = !target.checked;
                    }
                    scope.modal.syncSelectAll();
                    togglePromise.resolve();
                    return togglePromise.promise;
                },
                filterTargets: function () {
                    scope.modal.filteredTargets = targetOptions.filter(camp => camp.label.toLowerCase().includes(scope.modal.targetsFilter.toLowerCase()))
                    scope.modal.syncSelectAll();
                },
                selectAll: async function() {
                    const selectAllPromise = $q.defer();
                    const promises = []
                    const errors = []
                    const overlay = overlaySpinner.show('campaign-selection');
                    for (const target of scope.modal.filteredTargets) {
                        if (target.checked !== scope.modal.allChecked) {
                            target.checked = scope.modal.allChecked;
                            const result = scope.modal.saveTarget(target);
                            if (result && result.then) {
                                const promise = result.catch(err => {
                                    target.checked = !target.checked;
                                    errors.push(err)
                                })
                                promises.push(promise);
                                await promise;
                            } else {
                                const deferred = $q.defer();
                                promises.push(deferred.promise);
                                deferred.resolve();
                            }
                        }
                    }

                    Promise.all(promises)
                        .then(() => {
                            overlay.hide();
                            const successLength = promises.length - errors.length;
                            if (errors.length) {
                                ToasterService.failure(errors[0], scope.modal.allChecked ? 'assign_items_multi_success' : 'unassign_items_multi_success', [errors.length])
                                scope.modal.syncSelectAll();
                            }
                            if (successLength > 0) {
                                ToasterService.success(scope.modal.allChecked ? 'assign_items_multi_success' : 'unassign_items_multi_success', [successLength]);
                            }
                            selectAllPromise.resolve();
                        }).catch((err) => {
                            overlay.hide();
                            ToasterService.failure(err, scope.modal.allChecked ? 'assign_items_multi_fail' : 'unassign_items_multi_fail', [promises.length])
                            selectAllPromise.resolve();
                        })
                    return selectAllPromise.promise;
                },
                syncSelectAll: function() {
                    if (scope.modal.filteredTargets.every(p => p.checked)) {
                        scope.modal.allChecked = true;
                    } else if (scope.modal.filteredTargets.every(p => !p.checked)) {
                        scope.modal.allChecked = false;
                    } else {
                        scope.modal.allChecked = null;
                    }
                },
                targetsFilter: '',
                sourceItem,
                targetOptions: targetOptions,
                filteredTargets: targetOptions,
                allChecked: targetOptions.every(p => !p.checked) ? false : targetOptions.every(p => p.checked) ? true : null,
            };

            service.openGenericPopup(scope, config, 'templates/modal-assign.html', {
                closeOnEscape: false,
                closeOnOverlayClick: false,
            });

            return deferred.promise;
        },

        openCollaboratorCampaignSelection: function(scope, collaborator, campaigns) {
            var deferred = $q.defer();
            const campaignOptions = campaigns.map(campaign => {
                return {
                    ...campaign,
                    checked: campaign.collaborators.some(c => (c._id || c) === collaborator._id)
                }
            })

            const canAccessAllCampaigns = scope.$root.fns.userHasRights("accessAllCampaigns", true, collaborator)

            var config = {
                modalClass: 'modal--campaign-selection',
                close: function() {
                    scope.modalHandle.close();
                    deferred.resolve(campaignOptions.filter(camp => camp.checked));
                },
                saveCampaign: function(campaign) {
                    if (campaign.checked) {
                        return Server.post('campaigns/' + campaign._id + '/assign/' + scope.modal.collaborator._id)
                    } else {
                        return Server.post('campaigns/' + campaign._id + '/unassign/' + scope.modal.collaborator._id)
                    }
                },
                toggleCampaignSelection: function(campaign) {
                    const overlay = overlaySpinner.show('campaign-selection');
                    scope.modal.saveCampaign(campaign)
                        .then(() => {
                            overlay.hide();
                            ToasterService.success(campaign.checked ? 'collaborator_add_to_campaign_success' : 'collaborator_remove_from_campaign_success');
                        }).catch(err => {
                            overlay.hide();
                            ToasterService.failure(err, campaign.checked ? 'collaborator_add_to_campaign_fail' : 'collaborator_remove_from_campaign_fail')
                            campaign.checked = !campaign.checked;
                            scope.modal.syncSelectAll();
                        });
                    scope.modal.syncSelectAll();
                },
                filterCampaigns: function () {
                    scope.modal.filteredCampaigns = campaignOptions.filter(camp => camp.title[camp.language].toLowerCase().includes(scope.modal.campaignsFilter.toLowerCase()))
                    scope.modal.syncSelectAll();
                },
                selectAll: function() {
                    const promises = []
                    const errors = []
                    const overlay = overlaySpinner.show('campaign-selection');
                    scope.modal.filteredCampaigns.forEach(campaign => {
                        if (campaign.checked !== scope.modal.allChecked) {
                            campaign.checked = scope.modal.allChecked;
                            promises.push(
                                scope.modal.saveCampaign(campaign).catch(err => {
                                    campaign.checked = !campaign.checked;
                                    errors.push(err)
                                })
                            );
                        }
                    })
                    Promise.all(promises)
                        .then(() => {
                            overlay.hide();
                            const successLength = promises.length - errors.length;
                            if (errors.length) {
                                ToasterService.failure(errors[0], scope.modal.allChecked ? 'collaborator_add_to_multi_campaign_fail' : 'collaborator_remove_from_multi__campaign_fail', [errors.length])
                                scope.modal.syncSelectAll();
                            }
                            if (successLength > 0) {
                                ToasterService.success(scope.modal.allChecked ? 'collaborator_add_to_multi_campaign_success' : 'collaborator_remove_from_multi_campaign_success', [successLength]);
                            }
                        }).catch((err) => {
                            overlay.hide();
                            ToasterService.failure(err, scope.modal.allChecked ? 'collaborator_add_to_campaign_fail' : 'collaborator_remove_from_campaign_fail')
                        })
                },
                syncSelectAll: function() {
                    if (scope.modal.filteredCampaigns.every(p => p.checked)) {
                        scope.modal.allChecked = true;
                    } else if (scope.modal.filteredCampaigns.every(p => !p.checked)) {
                        scope.modal.allChecked = false;
                    } else {
                        scope.modal.allChecked = null;
                    }
                },
                campaignsFilter: '',
                collaborator,
                campaignOptions: campaignOptions,
                filteredCampaigns: Util.sortByDisplayedTitleAndArchiveStatus(campaignOptions, $rootScope.user),
                allChecked: campaignOptions.every(p => !p.checked) ? false : campaignOptions.every(p => p.checked) ? true : null,
                canAccessAllCampaigns,
            };

            service.openGenericPopup(scope, config, 'templates/modal-campaign-selection.html', {});

            return deferred.promise;
        },

        openStageDate: function(scope, candidates, selectedStage) {
            var deferred = $q.defer();

            let stageCurrentDate;
            if(candidates.length === 1) {
                stageCurrentDate = candidates[0]?.stageCurrent?.dateOfSet
            }

            var config = {
                modalClass: 'modal--stage-date',
                initialDate: stageCurrentDate || (new Date()).toString(),
                // date: stageCurrentDate || new Date(),
                submit: function() {
                    let date = Util.getDateFromString(scope.modal.date);
                    StageFactory.ddClick(candidates, selectedStage, date)
                    .then(function() {
                        scope.modalHandle.close();
                    });
                }
            };

            service.openGenericPopup(scope, config, 'templates/modal-stage-date.html', {});

            return deferred.promise;
        },

        openCandidateMessage: async function(scope, candidates, options) {

            let subject;
            scope.editorModules = NG_QUILL_CONFIG_EMAILS.modules;
            const allEqual = arr => arr.every( v => v === arr[0] )
            let msgLang = allEqual(_.map(candidates, 'language')) ? candidates[0].language : $rootScope.user.language;
            let campaign = allEqual(_.map(candidates, 'campaignId')) ? candidates[0].campaign : null;
            let emails = _.map(candidates, 'email');
            var deferred = $q.defer();
            if(campaign) {
                subject = `${Translate.getLangString('subject_candidate_message', msgLang)} ${Translate.getLangString('subject_candidate_message_for', msgLang)} ${campaign.title[campaign.language]}`;
            } else {
                subject = `${Translate.getLangString('subject_candidate_message', msgLang)}`;
            }

            const mailTemplates = await MailTemplates.getMailTemplates(msgLang);

            var config = {
                modalClass: 'modal--candidate-message',
                from: ($rootScope.me && $rootScope.me.email) || '',
                contact: _.map(candidates, Util.userFullName).join(', '),
                errorField: '',
                showSubject: true,
                subject: subject,
                showMsgTemplates: true,
                showMsgReplacements: true,
                ddLangs: Translate.getLangDropdownObject(),
                editLang: msgLang,
                templateLanguageChanged: async function() {
                    return $q(async (resolve, reject) => {
                        scope.modal.mailTemplates = await MailTemplates.getMailTemplates(scope.modal.editLang).catch(reject);
                        if (scope.modal.activeMsgTemplate) {
                            scope.modal.activeMsgTemplate = MailTemplates.msgTemplateClick(scope.modal.mailTemplates, scope.modal.activeMsgTemplate.key);
                            if (scope.modal.activeMsgTemplate) {
                                scope.modal.message = scope.modal.activeMsgTemplate.body;
                            }
                        }
                        resolve();
                    })
                },
                mailTemplates: mailTemplates,
                msgTemplateClick: function(templateKey) {
                    scope.modal.activeMsgTemplate = MailTemplates.msgTemplateClick(scope.modal.mailTemplates, templateKey);
                    if (scope.modal.activeMsgTemplate) {
                        scope.modal.message = scope.modal.activeMsgTemplate.body;
                    }
                },
                preSelectedMsgTemplateKey: options.preSelectedMsgTemplateKey,
                onEditorCreated: function(quill) {
                    const addCFN = document.getElementById("add-candidate-first-name");
                    const addCLN = document.getElementById("add-candidate-last-name");
                    const addCP = document.getElementById("add-campaign-title");
                    addCFN.addEventListener("click", function() {
                        insertToEditor(quill, '[candidate.firstName]')
                    });
                    addCLN.addEventListener("click", function() {
                        insertToEditor(quill, '[candidate.lastName]')
                    });
                    addCP.addEventListener("click", function() {
                        insertToEditor(quill, '[campaign.title]')
                    });
                    function insertToEditor (quill, str) {
                        quill.focus();
                        const cursorPosition = quill.getSelection()?.index || 0;
                        quill.editor.insertText(cursorPosition, str);
                        quill.setSelection(cursorPosition + str.length);
                    }
                },
                clearErrors: function () {
                    scope.modal.errorField = '';
                },
                submit: function() {
                    if (!scope.modal.message) {
                        scope.modal.errorField = 'message';
                        return;
                    }

                    overlaySpinner.show('modal');

                    var promises = [];

                    candidates.forEach(function (candidate){
                        promises.push(
                            Server.post(`candidates/${candidate._id}/message`, {
                                text: scope.modal.message, 
                                from: scope.modal.from,
                                subject: scope.modal.subject,
                            })
                        );
                    });

                    $q.all(promises).then(function() {
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                        deferred.resolve();
                        ToasterService.success('email_well_send');
                    });
                },
                ddCandidateEmailWindow: [{
                    text: Translate.getLangString('candidate_open_email_window'),
                    value: 'openCandidateEmailWindow'
                }],
                ddCandidateEmailWindowClick: function(selected) {
                    switch(selected.value) {
                        case 'openCandidateEmailWindow':  
                            const mailto = emails.join(';').replaceAll('+', '%2b');
                            const subject = scope.modal.subject;
                            const body = scope.modal.message ? encodeURI(scope.modal.message) : '';  // otherwise line breaks are lost
                            const url = 'mailto:' + '' + '?bcc=' + mailto + '&subject=' + subject + '&body=' + body;
                            Util.openCenteredWindow({url: url, w: 600, h: 400});
                            overlaySpinner.hide('modal');
                            scope.modalHandle.close();
                            break;
                    }
                },
            };

            if (options) {
                angular.extend(config, options);
            }

            service.openGenericPopup(scope, config, 'templates/modal-message-candidate.html', {});

            return deferred.promise;
        },

        openAskAvailability: async function(scope, options, candidateId) {
            var deferred = $q.defer();
            var config = {
                modalClass: 'modal--candidate-message',
                contact: Translate.getLangString('talentis_contact_2'),
                errorField: '',
                clearErrors: function () {
                    scope.modal.errorField = '';
                },
                submit: function() {

                },
            };
            if (options) {
                angular.extend(config, options);
            }
            service.openGenericPopup(scope, config, 'templates/modal-ask-availability.html', {});
            return deferred.promise;
        },

        openSupportMessage: async function(scope, options, message) {
            var deferred = $q.defer();
            var config = {
                modalClass: 'modal--candidate-message',
                contact: Translate.getLangString('talentis_contact_2'),
                from: ($rootScope.me && $rootScope.me.email) || '',
                errorField: '',
                message: message,
                allowAttachments: false,
                showMsgTemplates: false,
                showMsgReplacements: false,
                showMailTemplates: false,
                clearErrors: function () {
                    scope.modal.errorField = '';
                },
                submit: function() {
                    if (!scope.modal.message) {
                        scope.modal.errorField = 'message';
                        return;
                    }
                    overlaySpinner.show('modal');
                    Server.post('users/me/help', {text: scope.modal.message, from: scope.modal.from, })
                    .then(function() {
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                        deferred.resolve();
                        toaster.success({
                            body: Translate.getLangString('email_well_send'),
                        });
                    });
                },
            };
            if (options) {
                angular.extend(config, options);
            }
            service.openGenericPopup(scope, config, 'templates/modal-message-candidate.html', {});
            return deferred.promise;
        },

        openSubmissionShare: function(scope, candidateId) {

            const title = Translate.getLangString('submission_share_title');
            const body = Translate.getLangString('submission_share_link_desc_before')
                + scope.userFullName(scope.candidate)
                + Translate.getLangString('submission_share_link_desc_after');

            Server.get('candidates/' + candidateId + '/link').then(function(result) {
                if (!result || !result.link) {
                    return;
                }

                var config = {
                    modalClass: 'modal--submission-share',
                    title: title,
                    body: body,
                    link: result.link,
                    copyLink: function() {
                        clipboard.copyText(scope.modal.link);
                        toaster.success({
                            body: Translate.getLangString('link_copied'),
                            timeout: 2000,
                        });
                    }
                };

                service.openGenericPopup(scope, config, 'templates/modal-submission-share.html', {});
            });
        },

        openStageHistory: function(scope, candidate) {
                
            const config = {
                modalClass: 'modal--stage-history',
                lang: Translate.currentLanguage(),
            };

            service.openGenericPopup(scope, config, 'templates/modal-candidate-history.html', {});
        },

        openInviteExternal: async function(scope, session) {

            const interviewPublicLink = `${location.origin}/live-interview?id=${session._id}&id2=external&id3=0`;

            const mailTemplates = await MailTemplates.getMailTemplates(Translate.currentLanguage(), 'live-invite'); // there's at least one in the DB
            const interviewMailTemplate = MailTemplates.msgTemplateClick(mailTemplates, 'live-invite-external')  // and it's got the key 'live-invite-external'

            const config = {
                interviewPublicLink: interviewPublicLink,
                copyInterviewPublicLink: function() {
                    clipboard.copyText(interviewPublicLink);
                    toaster.success({
                        body: Translate.getLangString('link_copied'),
                        timeout: 2000,
                    });
                },
                externalEmails: '',
                message: interviewMailTemplate?.body || '',
                subject: interviewMailTemplate?.title || '',
                submit: function() {
                    if (!scope.modal.message) {
                        scope.modal.errorField = 'message';
                        return;
                    }
                    overlaySpinner.show('modal');
                    let user = $rootScope.user || $rootScope.me;
                    Server.post(`users/${user._id}/session/${session._id}/collaborator/external`, {
                        externalEmails: scope.modal.externalEmails.split(','),
                        subject: scope.modal.subject,
                        message: scope.modal.message,
                    })
                    .then(function() {
                        overlaySpinner.hide('modal');
                        ToasterService.success('email_well_send');
                        scope.modalHandle.close();
                        deferred.resolve();
                    });
                }
            };

            service.openGenericPopup(scope, config, 'templates/modal-interview-invite-external.html', {});
        }, 

        openSessionEditor: function(scope, date, session, mode, user,campaign, candidate) {


            /* depending on where this is called from, the campaign, candidate and translation are not available */
            let rootscopecandidate = candidate;
            let rootscopecampaign = campaign;
            scope.texts = $rootScope.texts;

            var deferred = $q.defer();

            var newSessionSubmit = function() {
                overlaySpinner.show('modal');

                scope.modal.errors = {};
                let emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
                if (!scope.modal.ddSelectedCampaign || _.isEmpty(scope.modal.ddSelectedCampaign)) {
                    scope.modal.errors.selectedCampaign = true;
                }
                if (!scope.modal.contact || _.isEmpty(scope.modal.contact)) {
                    scope.modal.errors.contact = true;
                }
                if (!scope.modal.contactFromCampaign && mode !== 'edit') {
                    scope.modal.errors.contact = true;
                    scope.modal.errors.contactFromCampaign = true;
                }                        
                if (!scope.modal.subject || _.isEmpty(scope.modal.subject)) {
                    scope.modal.errors.subject = true;
                }
                if (!scope.modal.message || _.isEmpty(scope.modal.message)) {
                    scope.modal.errors.message = true;
                }
                if (!scope.modal.ddSelectedInterviewType || _.isEmpty(scope.modal.ddSelectedInterviewType)) {
                    scope.modal.errors.selectedInterviewType = true;
                }
                if (scope.modal.ddSelectedInterviewType.id == scope.modal.ddSelectTypeInterviewOptions[0].id) {
                    if (!scope.modal.address.name) {
                        scope.modal.errors.address = true;
                    }
                }
                if (!emailRegex.test(scope.modal.contact)) {
                    scope.modal.errors.contact = true;
                }
                if (scope.modal.ddSelectcustomInterviewLink && !scope.modal.customInterviewLink) {
                    scope.modal.errors.customInterviewLink = true;
                }
                if (!_.isEmpty(scope.modal.errors)) {
                    overlaySpinner.hide('modal');
                    return;
                }
                
                let campaignId = scope.modal.ddSelectedCampaign.id || 0;
                let language = (scope.modal.ddSelectedCampaign.language >= 0 ? scope.modal.ddSelectedCampaign.language : 0);
                let isPhysicalInterview = scope.modal.ddSelectedInterviewType && scope.modal.ddSelectedInterviewType.id === 0;
                let profileId = scope.modal.profileId || $rootScope.user?.cronofySessions?.[0]?.linking_profile?.profile_id;

                const body = {
                    candidate: {
                        email: scope.modal.contact,
                        campaignId: campaignId,
                        language: language
                    },
                    subject: scope.modal.subject,
                    message: scope.modal.message,
                    isPhysicalInterview: isPhysicalInterview,
                    customInterviewLink: scope.modal.ddSelectcustomInterviewLink ? scope.modal.customInterviewLink : undefined,
                    location: isPhysicalInterview ? scope.modal.address : undefined,
                    schedule: getDateTime(scope.modal.date, scope.modal.startTime),
                    startTime: getDateTime(scope.modal.date, scope.modal.startTime),
                    endTime: getDateTime(scope.modal.date, scope.modal.endTime),
                    profile_ids: [profileId],
                };

                if (mode === 'edit' && session) {
                    Server.patch('users/me/sessions/' + session._id, body)
                    .then(function(session) {
                        ToasterService.success('interview_edit_well_done');
                        scope.reloadCandidate && scope.reloadCandidate();
                        overlaySpinner.hide('modal');
                        deferred.resolve(session);
                        scope.modalHandle.close();
                    })
                    .catch(err => {
                        console.error('err', err);
                        ToasterService.failure(err, 'err_0_error_occurred');
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                    });
                } else {
                    EventTracker.trackInterviewInviteSend();
                    Server.post('users/me/sessions', body)
                    .then(function(session) {
                        ToasterService.success('interview_invitation_well_sent');
                        scope.reloadCandidate && scope.reloadCandidate();
                        overlaySpinner.hide('modal');
                        deferred.resolve(session);
                        scope.modalHandle.close();
                    })
                    .catch(err => {
                        console.error('err', err);
                        ToasterService.failure(err, 'err_0_error_occurred');
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                    });
                }
            };

            var roundToNextHour = function (date) {
                p = 60 * 60 * 1000; // milliseconds in an hour
                return new Date(Math.round(date.getTime() / p ) * p + p);
            };

            var ddSelectCampaign = function(selected) {
                scope.modal.contact = '';
                scope.modal.searchContactsList = [];
                scope.modal.searchContactsListnames = [];
                scope.modal.searchContactsListFound = [];
                scope.modal.ddSelectedCampaign = selected;

                var id = selected.id;

                Server.get('campaigns/' + id + '?fetchCandidates=true').then(function(campaignFull) {
                    campaignFull.candidates.forEach(function(candidate) {
                        if (!candidate.isArchived) {
                            scope.modal.searchContactsList.push(candidate.email);
                            scope.modal.searchContactsListNames.push((candidate.firstName || '') + ' ' + (candidate.lastName || '').trim());
                        }
                    });
                });
            };

            var ddSelectInterviewType = function(selected) {
                scope.modal.ddSelectedInterviewType = selected;
            };

            var contactFieldChange = function(searchText) {
                scope.modal.contactFromCampaign = false;
                if (!searchText) {
                    searchText = scope.modal.contact;
                }
                scope.modal.searchContactsListFound = [];
                if (!searchText) {
                    return;
                }
                scope.modal.searchContactsListNames.forEach(function(name, index) {
                    var email = scope.modal.searchContactsList[index];
                    if (name && name.indexOf(searchText) >= 0 && !email.startsWith('email_not_found')) {
                        scope.modal.searchContactsListFound.push({
                            name: name,
                            email: email,
                            text: "'" + name + "' <" + email + '>'
                        });
                    }
                });
                scope.modal.searchContactsList.forEach(function(email) {
                    if (email.indexOf(searchText) >= 0 && !email.startsWith('email_not_found')) {
                        if (!scope.modal.searchContactsListFound.find(function(item) {return item.email.indexOf(email) >= 0; })) {
                            scope.modal.searchContactsListFound.push({
                                email: email,
                                text: email
                            });
                        }
                    }
                });
                scope.errorField = false;
            };

            var selectContact = function(item) {
                scope.modal.contactFromCampaign = true;
                scope.modal.contact = item.email;
                scope.errorField = false;
                scope.modal.searchContactsListFound = [];
            };

            var selectCampaignFromCandidateId = function(candidateId) {
                Server.get('candidates/' + candidateId).then(function(candidate) {
                    if (!candidate.campaignId) {
                        scope.modal.ddSelectCampaignOptions = [{}];
                        scope.modal.ddSelectedCampaign = scope.modal.ddSelectCampaignOptions[0];
                        return;
                    }

                    Server.get('campaigns/' + candidate.campaignId).then(function(campaign) {
                        scope.modal.ddSelectCampaignOptions = [{
                            id: campaign._id,
                            text: $rootScope.fns.getDisplayedTitle(campaign, user),
                            language: campaign.language
                        }];
                        scope.modal.ddSelectedCampaign = scope.modal.ddSelectCampaignOptions[0];
                    });
                });
            };

            var sortCampaignsByAlphabetic = function(campaigns, user) {
                campaigns.sort(function(campaignA, campaignB) {
                    var titleA = Util.getDisplayedTitle(campaignA, user).toLowerCase();
                    var titleB = Util.getDisplayedTitle(campaignB, user).toLowerCase();
                    return (titleA < titleB) ? -1 : (titleA > titleB) ? 1 : 0;
                });
            }
            var loadCampaigns = function() {
                Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true').then(function(allCampaigns) {
                    sortCampaignsByAlphabetic(allCampaigns, user);
                    scope.modal.ddSelectCampaignOptions = allCampaigns.map(function(campaign) {
                        return {
                            id: campaign._id,
                            text: $rootScope.fns.getDisplayedTitle(campaign, user),
                            language: campaign.language
                        }
                    });
                });
            };

            var loadMailTemplate = function() {
                return MailTemplates.getMailTemplates(Translate.currentLanguage(), 'live-invite')
                .then(templates => {
                    const liveInviteTemplate = templates.filter(t => t.key === 'live-invite')[0];
                    if (liveInviteTemplate) {
                        scope.modal.subject = liveInviteTemplate.title;
                        scope.modal.message = liveInviteTemplate.body;
                        scope.$apply();
                    }
                }).catch(err => {
                    console.error('error loading email templates: ' + err);
                });
            }

            var checkInputDisabled = function() {
                console.log(scope.modal.ddSelectedCampaign);
                if (! scope.modal.ddSelectedCampaign || _.isEmpty(scope.modal.ddSelectedCampaign) || rootscopecandidate) {
                    return true;
                } else {
                    return false;
                }
            }

            var getInterviewType = function() {
                return [
                    { id: 0, text: Translate.getLangString('interview_physique') },
                    { id: 1, text: Translate.getLangString('interview_live')}
                ];
            };

            var loadAddress = function() {
                var address= { name:'',city:'',state:'',country:'',zip:'',latitude:'',longitude:''};
                if (rootscopecampaign) {
                    if (rootscopecampaign.location) {
                        address.name =rootscopecampaign.location.name;
                        if (rootscopecampaign.location.city) {
                            address.city = rootscopecampaign.location.city;
                            address.state = rootscopecampaign.location.state;
                            address.country = rootscopecampaign.location.country;
                            address.zip = rootscopecampaign.location.zip;
                            address.latitude = rootscopecampaign.location.latitude;
                            address.longitude = rootscopecampaign.location.longitude;
                        }
                    }
                } else if ($rootScope.user) {
                    if ($rootScope.user.address) {
                        address.name = $rootScope.user.address.name;
                        if ($rootScope.user.address.city) {
                            address.city = $rootScope.user.address.city;
                            address.state = $rootScope.user.address.state;
                            address.country = $rootScope.user.address.country;
                            address.zip = $rootScope.user.address.zip;
                            address.latitude = $rootScope.user.address.latitude;
                            address.longitude = $rootScope.user.address.longitude;
                        }
                    }
                } 
                return address;
            };

            function onEndTimeChange() {
                let startMoment = moment(getDateTime(scope.modal.date, scope.modal.startTime));
                let endMoment = moment(getDateTime(scope.modal.date, scope.modal.endTime));
                scope.modal.duration = endMoment.diff(startMoment, 'minutes');
            }
            function onStartTimeChange() {
                let startMoment = moment(getDateTime(scope.modal.date, scope.modal.startTime));
                scope.modal.endTime = startMoment.add(scope.modal.duration, 'minutes').toDate();
            }

            var defaults = {
                modalClass: 'modal--new-session',
                errorField: '',
                timePickerOptions: {
                    step: 5,
                    timeFormat: 'H : i'
                },
                isActive: false,
                contact: '',
                lockCandidateInput: false,
                mode: mode,
                submit: newSessionSubmit,
                contactFieldChange: contactFieldChange,
                selectContact: selectContact,
                verifyAvailability: verifyAvailability,
                showInterviewTypeOptions: $rootScope.user.settings.ui.userSpecificFields.includes('bosa'),
                ddSelectCampaign: ddSelectCampaign,
                checkInputDisabled: checkInputDisabled,
                ddSelectInterviewType: ddSelectInterviewType,
                ddSelectTypeInterviewOptions: getInterviewType(),
                ddSelectcustomInterviewLink: false,
                customInterviewLink: '',
                onStartTimeChange: onStartTimeChange,
                onEndTimeChange: onEndTimeChange,
                subject: (rootscopecandidate ? Translate.getLangString('interview_subject_default_campaign', rootscopecandidate.language) : Translate.getLangString('interview_subject_default_campaign')),
                message: (rootscopecandidate ? Translate.getLangString('interview_message_default', rootscopecandidate.language) : Translate.getLangString('interview_message_default')),
                date: date || (new Date()),
                initialDate: (date ? date.toString() : (new Date()).toString()),
                startTime: roundToNextHour(new Date()),
                endTime: Util.addMinutes(roundToNextHour(new Date()), 30),
                duration: 30,
                ddSelectedCampaign: {},
                searchContactsList: [],
                ddSelectCampaignOptions: [],
                searchContactsListNames: [],
                searchContactsListFound: [],
                onEditorCreated: function(quill) {
                    const addCFN = document.getElementById("add-candidate-first-name");
                    const addCLN = document.getElementById("add-candidate-last-name");
                    const addCP = document.getElementById("add-campaign-title");
                    addCFN.addEventListener("click", function() {
                        insertToEditor(quill, '[candidate.firstName]')
                    });
                    addCLN.addEventListener("click", function() {
                        insertToEditor(quill, '[candidate.lastName]')
                    });
                    addCP.addEventListener("click", function() {
                        insertToEditor(quill, '[campaign.title]')
                    });
                    function insertToEditor (quill, str) {
                        quill.focus();
                        const cursorPosition = quill.getSelection()?.index || 0;
                        quill.editor.insertText(cursorPosition, str);
                        quill.setSelection(cursorPosition + str.length);
                    }
                },
            };

            function verifyAvailability(){
                const startTime = getDateTime(scope.modal.date, scope.modal.startTime);
                const endTime = getDateTime(scope.modal.date, scope.modal.endTime);
                const profileIds = ['pro_X9je-cFjewC5HaEf'];
                let url = `users/calendar-sync/verifyAvailability?startTime=${startTime}&endTime=${endTime}&profile_ids=${profileIds.join(',')}`;
                Server.get(url)
                    .then(res => {
                        console.log('res', res);
                        alert("res = " + JSON.stringify(res.available_periods));
                    });
            };

            /**
             * @param { String } modalDate - formatted like "DD / mm / yyyy"
             * @param { Date } modalTime - has the right time (hours) but not the right date (day)
             * @returns { Date } - the date of modalDate with the time of modalTime
             */
            function getDateTime(modalDate, modalTime) {
                let date = Util.getDateFromString(modalDate);
                let time, h, m;
                if (modalTime) {
                    time = modalTime;
                    h = time.getHours();
                    m = time.getMinutes();
                }
                if (time) {
                    date.setHours(h);
                    date.setMinutes(m);
                } else {
                    return 'errors.time';
                }
                return date;
            }
            
            if (mode == 'edit' && session) {
                defaults.lockCandidateInput = true;
                defaults.contact = session.participants[0].email;
                selectCampaignFromCandidateId(session.participants[0].foreignUserId);
                defaults.subject = session.subject;
                defaults.message = session.description;
                defaults.ddSelectedInterviewType = session.isPhysicalInterview ? (getInterviewType())[0] : (getInterviewType())[1],
                defaults.date = new Date(session.schedule);
                defaults.initialDate = (new Date(session.schedule)).toString();
                defaults.startTime = new Date(session.schedule);
                defaults.initialStartTime = new Date(session.schedule);
                defaults.endTime = Util.addMinutes(new Date(session.schedule), session.duration);
                defaults.initialEndTime = Util.addMinutes(new Date(session.schedule), session.duration);
                defaults.duration = session.duration;
                defaults.ddSelectcustomInterviewLink = !!session.customInterviewLink;
                defaults.customInterviewLink = session.customInterviewLink;
            } else if (mode == 'liveinvite' && rootscopecampaign && rootscopecandidate) {
                defaults.ddSelectCampaignOptions = [{
                    id: rootscopecampaign._id,
                    text: $rootScope.fns.getDisplayedTitle(rootscopecampaign, user),
                    language: rootscopecampaign.language
                }];
                defaults.ddSelectedCampaign = defaults.ddSelectCampaignOptions[0];
                defaults.contactFromCampaign = true;
                defaults.ddSelectedInterviewType = defaults.ddSelectTypeInterviewOptions[1];
                defaults.contact = rootscopecandidate.email;
                defaults.lockCandidateInput = true;
                defaults.isActive = true;
                loadMailTemplate();
            } else {
                loadCampaigns();
                loadMailTemplate();
                defaults.ddSelectedInterviewType = defaults.ddSelectTypeInterviewOptions[1];
            }

            service.openGenericPopup(scope, defaults, 'templates/modal-new-session.html', {});

            return deferred.promise;
        },
        viewSession: function(scope, date, session, mode, user) {
        // Date formatting
        session.date = $filter('date')(session.schedule, 'dd/MM/yyyy');
        session.time = $filter('date')(session.schedule, 'HH:mm');
        
            var config = {
                modalClass: 'modal--view-session',
                title: 'view',
                session: session, 
                mode: mode, 
                closeButtonText: 'Close', 
                close: function() {
                    scope.modalHandle.close();
                }
            };
        
            this.openGenericPopup(scope, config, 'templates/modal-interview-view.html');
        },

        downloadDocuments: function(scope, candidates, isCampaign) {
            const candidateIds = candidates.map(candidate => candidate._id);
                                                
            const campaignName = isCampaign ? $rootScope.campaign.title[$rootScope.campaign.language] : '';
            const endPoint = `candidates/download-documents`;
            
            function initiateDownload(endpoint, bodyContent, successMessageKey) {
                overlaySpinner.show('modal');
                fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(bodyContent)
                })
                .then(response => {
                    const filename = response.headers.get('X-Filename') || response.headers.get('Content-Disposition').split('filename=')[1].replace(/"/g, '') || 'Beehire Export.zip';
                    return response.blob().then(blob => ({ blob, filename }));
                })
                .then(({blob, filename}) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove();
                    ToasterService.success(successMessageKey);
                    overlaySpinner.hide('modal');
                    scope.modalHandle.close();
                })
                .catch(err => {
                    console.error('Download error: ', err);
                    ToasterService.failure(err, 'err_73_error_while_downloading_documents');
                    overlaySpinner.hide('modal');
                });
            }
            
            function initiateDocuments(documentType){
                const bodyContent = {
                    candidateIds: candidateIds,
                    documentType: documentType,
                    isCampaign: isCampaign,
                    campaignName: campaignName,
                };
                initiateDownload(endPoint, bodyContent, 'download_documents_success');
            }
        
            let candidatesWithoutCV = candidates.filter(candidate => !candidate.cvDocument || !candidate.cvDocument.path);
            let candidatesWithoutCVString = candidatesWithoutCV.map(candidate => candidate.firstName + ' ' + candidate.lastName).join(', ');
        
            let modalOptions = {
                candidates: candidateIds,
                candidatesWithoutCV: candidatesWithoutCV,
                candidatesWithoutCVString: candidatesWithoutCVString,
                title: Translate.getLangString('download_documents'),
                bodyMessage: Translate.getLangString('download_documents_body_message').replace('...', candidates.length),
                downloadCVs: Translate.getLangString('download_only_cvs'),
                downloadAllDocuments: Translate.getLangString('download_all_documents'),
                download: Translate.getLangString('download'),
                cancel: Translate.getLangString('cancel'),
                submit: function() {
                    if (scope.modal.downloadOption === 'cv') {
                        initiateDocuments('cv');
                    } else if (scope.modal.downloadOption === 'allDocuments') {
                        initiateDocuments('allDocuments');
                    } else {
                        ToasterService.warning('select_download_option');
                    }
                },                
            };
            this.openGenericPopup(scope, modalOptions, 'templates/modal-candidate-multiple-cvs-download.html');
        },
        exportCampaignsToCsv: function(scope, campaignsIds) {
            let modalOptions = {
                modalClass: 'modal--export-campaigns',
                campaignsIds: campaignsIds,
                title: Translate.getLangString('export_campaigns_to_csv'),
                bodyMessage: Translate.getLangString('export_campaigns_to_csv_body_message').replace('...', campaignsIds.length),
                download: Translate.getLangString('download'),
                cancel: Translate.getLangString('cancel'),
                fields: {
                    title: true,
                    privateTitle: true,
                    location: { name: true, country: true },
                    created: true,
                    candidateInfoForm: { quickApply: true },
                    isArchived: true,
                    campaignStatus: true,
                    documents: { name: true },
                    jobCategoriesIds: true,
                    language: true,
                    inviteLink: true,
                    createdBy: true,
                    details: {
                        contract: {
                            vacanciesNumber: true,
                            duration: true,
                            type: true,
                            remote: true
                        }
                    },
                    deadline: true
                },
                submit: function(fields, exportAll) {
                    if (exportAll) {
                        angular.forEach(fields, function(value, key) {
                            fields[key] = true;
                        });
                    }
                    initiateCsv(campaignsIds,fields);
                },
            };
            function initiateCsv(campaignsIds,fields){
                Server.post('campaigns/exportCampaignsToCsv', {campaignsIds: campaignsIds, fields: fields}).then(function(response) {
                    var blob = new Blob([response], {type: 'text/csv'});
                    var downloadUrl = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = downloadUrl;
                    a.download = "Exported_Campaigns.csv";
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function() {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(downloadUrl);
                        scope.modalHandle.close();
                    }, 100);
                }).catch(function(error) {
                    console.error("Error downloading the file:", error);
                });
                
            }
            
            scope.toggleExportAll = function(exportAll) {
                let fields = modalOptions.fields;
            
                // Recursive function to update the state (checked or unchecked) of all fields
                function updateFields(fieldSet, newValue) {
                    angular.forEach(fieldSet, function(value, key) {
                        if (typeof value === 'object') {
                            updateFields(value, newValue);
                        } else {
                            fieldSet[key] = newValue;
                        }
                    });
                }
                
                // Recursive function to check if all fields are currently checked
                function checkAllChecked(fieldSet) {
                    var allChecked = true;
                    angular.forEach(fieldSet, function(value, key) {
                        if (typeof value === 'object') {
                            allChecked = allChecked && checkAllChecked(value);
                        } else {
                            if (!value) allChecked = false;
                        }
                    });
                    return allChecked;
                }
            
                var allChecked = checkAllChecked(fields); 

                var newCheckValue;
                if (exportAll) {
                    if (allChecked) {
                        newCheckValue = false;
                    } else {
                        newCheckValue = true;
                    }
                } else {
                    newCheckValue = false;
                }
            
                updateFields(fields, newCheckValue);
            
                modalOptions.exportAll = newCheckValue;
            };

            // Disable the export to csv button if no fields are selected
            function isAtLeastOneFieldChecked(field) {
                var anyTrue = false;
                angular.forEach(field, function(value, key) {
                    if (typeof value === 'object') {
                        anyTrue = anyTrue || isAtLeastOneFieldChecked(value);
                    } else {
                        anyTrue = anyTrue || value;
                    }
                });
                return anyTrue;
            }
            scope.canExportToCsv = isAtLeastOneFieldChecked(modalOptions.fields);

            // Watch group to monitor changes to all individual fields and adjust the 'Export All' checkbox accordingly
            scope.$watchGroup([
                'modal.fields.title',
                'modal.fields.privateTitle',
                'modal.fields.location.name',
                'modal.fields.location.country',
                'modal.fields.created',
                'modal.fields.candidateInfoForm.quickApply',
                'modal.fields.isArchived',
                'modal.fields.campaignStatus',
                'modal.fields.documents.name',
                'modal.fields.jobCategoriesIds',
                'modal.fields.language',
                'modal.fields.inviteLink',
                'modal.fields.createdBy',
                'modal.fields.details.contract.vacanciesNumber',
                'modal.fields.details.contract.duration',
                'modal.fields.details.contract.type',
                'modal.fields.details.contract.remote',
                'modal.fields.deadline',
            ], function(newValues) {
                var allChecked = newValues.every(function(value) { return value === true; });
                scope.canExportToCsv = newValues.some(function(value) { return value === true; });
                scope.modal.exportAll = allChecked;
            });

            this.openGenericPopup(scope, modalOptions, 'templates/modal-campaigns-export-csv.html');
        },
        
        openAssessmentInvite: function(scope, candidates, assessment) {
            var deferred = $q.defer();

            function loadAssessments() {
                if (assessment) {
                    scope.modal.ddInviteToAssessmentOptions = [{
                        label: assessment.title[assessment.language || 0],
                        ...assessment
                    }];
                    scope.modal.ddSelectedInviteToAssessment = scope.modal.ddInviteToAssessmentOptions[0];
                    return;
                }

                scope.modal.loading = true;
                Server.get('assessments')
                    .then(assessments => {
                        const mapped = assessments.map((assessment) => ({
                            label: assessment.title[assessment.language || 0],
                            ...assessment
                        }));
                        scope.modal.ddInviteToAssessmentOptions = mapped;
                        scope.modal.loading = false;
                    })
                    .catch(err => {
                        ToasterService.failure(err, 'load_assessments_error');
                    });
            }

            function sendInvite() {
                scope.modal.errors = {};
                scope.modal.loading = true;
                if (!scope.modal.ddSelectedInviteToAssessment._id) {
                    ToasterService.failure({}, 'no_assessments_selected');
                    scope.modal.loading = false;
                    scope.modal.errors.selectedAssessment = true;
                    return;
                }
                if (!scope.modal.candidatesIds || scope.modal.candidatesIds.length === 0) {
                    ToasterService.failure({}, 'candidate_required');
                    scope.modal.loading = false;
                    return;
                }
                Server.post(`assessments/${scope.modal.ddSelectedInviteToAssessment._id}/invite`, {
                    candidateIds: scope.modal.candidatesIds
                }).then(res => {
                    scope.modal.loading = false;
                    for(let i = 0; i < candidates.length; i++) {
                        candidates[i].assessments = res[i].assessments;
                    }
                    ToasterService.success('invite_candidate_success');
                    deferred.resolve();
                    scope.modalHandle.close();
                })
                .catch(err => {
                    scope.modal.loading = false;
                    ToasterService.failure(err, 'err_0_error_occurred');
                });
            }

            var defaults = {
                modalClass: 'modal--new-session',
                submit: sendInvite,
                loadAssessments: loadAssessments,
                candidates: candidates,
                assessment: assessment,
                candidatesIds: candidates ? candidates.map(c => c._id) : [],
                candidatesEmailsStr: candidates ? candidates.map(c => c.email).join('; ') : '',
                ddInviteToAssessmentOptions: [],
                ddSelectedInviteToAssessment: {},
                ddSelectAssessment: (selected) => scope.modal.ddSelectedInviteToAssessment = selected,
            };
            service.openGenericPopup(scope, defaults, 'templates/modal-invite-assessment.html', {});
            return deferred.promise;
        },
            openCandidateDocumentPrinter: function(scope, candidates) {

                let totalDocToPrint = candidates.reduce(function(acc, cand){
                    let existingDocs = cand.documents.filter(doc => {
                        return doc.downloadFilename && !doc.isPdfConversion
                    });
                    return acc + existingDocs.length;
                }, 0);
                let candidatesWithNoDocs = candidates.filter(cand => {
                    let existingDocs = cand.documents.filter(doc => doc.downloadFilename); 
                    return !existingDocs.length;
                });
                let body;
                noDocsToPrint = totalDocToPrint === 0;

                if(candidates.length === 1 && candidatesWithNoDocs.length === 0) {
                    body = Translate.getLangString('candidate_print_one_candidate_summary')
                        .replace('...', totalDocToPrint);
                }
                else if (candidates.length === 1 && noDocsToPrint) {
                    body = Translate.getLangString('candidate_print_one_candidate_no_docs_to_print');
                }
                else if (candidates.length > 1 && candidatesWithNoDocs.length === 0) {
                    body = Translate.getLangString('candidate_print_summary')
                        .replace('...', candidates.length).replace('...', totalDocToPrint);
                }
                else if (candidates.length > 1 && candidatesWithNoDocs.length > 0) {
                    body = Translate.getLangString('candidate_print_summary')
                        .replace('...', candidates.length).replace('...', totalDocToPrint)
                        + '<br><br>' + Translate.getLangString('candidate_print_candidates_no_docs_to_print')
                        + '<br> - ' + candidatesWithNoDocs.map(Util.userFullName).join('<br> - ');
                }

                if(!noDocsToPrint) {
                    if(totalDocToPrint > 100) {
                        body = body + '<br><br><span style="color: red">' + Translate.getLangString('candidate_print_click_for_email_time_warning')
                            .replace('...', Math.round(totalDocToPrint / 20)) + '</span>';
                    }

                    body = body + '<br><br>' + Translate.getLangString('candidate_print_click_for_email')
                        .replace('...', $rootScope.user.email);
                }

                let config = {
                    title: Translate.getLangString('candidate_print_header'),
                    body: body,
                    cta: Translate.getLangString('candidate_print_CTA'),
                    noDocsToPrint: noDocsToPrint,
                    modalClass: 'modal--candidate-document-printer',
                    printDocuments: function() {
                        overlaySpinner.show('modal');
                        Server.post(`users/${$rootScope.user._id}/candidates-documents`, {candidateIds: _.map(candidates, '_id')})
                        .then(res => {
                            ToasterService.success('ok_printing');
                            overlaySpinner.hide('modal');
                            scope.modalHandle.close();
                        })
                        .catch(err => {
                            ToasterService.failure(err, 'err_71_error_while_printing');
                            overlaySpinner.hide('modal');
                        })
                    }
                };

                service.openGenericPopup(scope, config, 'templates/modal-candidate-document-printer.html', {});
            },

        openResetVideoQuestionsPopup: function(scope, candidates) {
            const showCheckbox = !!candidates.find(candidate => Util.hasVideoAnswers(candidate));
            const isCheckboxMandatory = candidates.every(candidate => Util.hasVideoAnswers(candidate));
            service.openGenericPopup(scope, {
                modalClass: 'modal--reset-video-question',
                submit: function () {
                    if (scope.modal.isCheckboxMandatory && !scope.modal.includeCandWithVideoAnswers) {
                        return;
                    }
                    const overlayCampaign = overlaySpinner.show('submission');
                    Server.post('candidates/reset-video-questions', {
                        candidates: candidates.map(c => c._id),
                        includeCandWithVideoAnswers: scope.modal.includeCandWithVideoAnswers
                    })
                    .then(response => {
                        overlayCampaign.hide();
                        ToasterService.success('email_well_send');
                        scope.modalHandle.close();
                    }).catch(err => {
                        overlayCampaign.hide();
                        ToasterService.failure(err, 'err_0_error_occurred');
                        scope.modalHandle.close();
                    });
                },
                title: Translate.getLangString('candidate_reset_video_popup_title'),
                messageText: Translate.getLangString('candidate_reset_video_popup_message'),
                checkboxText: Translate.getLangString('candidate_reset_video_popup_checkbox'),
                submitText: Translate.getLangString('candidate_reset_video_popup_confirm'),
                noText: Translate.getLangString('cancel'),
                candidates: candidates,
                includeCandWithVideoAnswers: false,
                showCheckbox,
                isCheckboxMandatory
            }, 'templates/modal-reset-video-questions.html', {});
        },

        openVideoClips: function(scope, question) {
            const options = {
                question: question,
            }
            service.openGenericPopup(scope, options, 'templates/modal-video-clips.html', {});
        },

        openStripeCheckoutPopup: function(scope, { clientSecret, returnUrl, price, campaignId, orderId }) {
            scope.modal = {
                modalClass: 'modal--default',
                close: function() {
                    scope.modalHandle.close();
                }
            };

            scope.modalHandle = $fancyModal.open({
                templateUrl: 'templates/modal-stripe-checkout.html',
                scope: angular.extend(scope, { clientSecret, returnUrl, price, campaignId, orderId })
            });
        },

        openCreateJobCategoryPopup: function(scope) {
            const buildJobCategoriesList = (target) => {
                if (!scope.jobCategories) {
                    scope.jobCategories = []
                }
                const userJobCategories = scope.jobCategories.filter(cat => cat.isUserJobCategory);
                const campaignJobCategories = scope.jobCategories.filter(cat => cat.assigned);
                if (target) {
                    target.userJobCategories = userJobCategories;
                    target.campaignJobCategories = campaignJobCategories;
                }
                return {
                    userJobCategories,
                    campaignJobCategories,
                };
            }
            scope.jobCategoryContextDiv = `<div id='contextmenu-node'><ul ><li class='contextmenu-item' ng-click='modal.deleteJobCategory($event.originalEvent, jobCategory)'>${Translate.getLangString('delete_job_category')}</li></ul></div>`;

            const jobCategoriesLists = buildJobCategoriesList();
            var config = {
                modalClass: 'modal--job-category',
                userJobCategories: jobCategoriesLists.userJobCategories,
                campaignJobCategories: jobCategoriesLists.campaignJobCategories,
                labelField: [],
                toggleJobCategory: function(jobCategory) {
                    if (jobCategory.assigned) {
                        scope.unassignJobCategory(jobCategory)
                            .then(() => buildJobCategoriesList(scope.modal));
                    } else {
                        scope.assignJobCategory(jobCategory)
                            .then(() => buildJobCategoriesList(scope.modal));
                    }
                },
                deleteJobCategory: function(event, jobCategory) {
                    event.cancelBubble = true;
                    service.openGenericPopup(scope.$new(), {
                        submit: function () {
                            this.close();
                            Server.delete(`users/${$rootScope.user._id}/job-categories/${jobCategory._id}`)
                                .then(scope.loadJobCategories)
                                .then(() => buildJobCategoriesList(scope.modal))
                        },
                        title: Translate.getLangString('delete_confirmation_title'),
                        warningText: Translate.getLangString('delete_confirmation_warning'),
                        messageText: Translate.getLangString('delete_job_category_confirmation_message'),
                        yesText: Translate.getLangString('delete_job_category'),
                        noText: Translate.getLangString('cancel')
                    }, 'templates/modal-confirm-warning.html', {});
                },
                createJobCategory: async function() {                    
                    if (!scope.modal.labelField || scope.modal.labelField.length === 0) {
                        return;
                    }

                    const newLabelFieldValue = [];
                    for (let jobCategory of scope.modal.labelField) {
                        let newJobCategory = scope.modal.userJobCategories.find((el) => el.label === jobCategory.label);
                        if (newJobCategory) {
                            scope.modal.labelField = '';
                            ToasterService.failure(null,'err_110a_category_already_exists');
                            continue;
                        }
                        if (!newJobCategory) {
                            await Server.post(`users/${$rootScope.user._id}/job-categories`, { label: jobCategory.label })
                            .then(response => {
                                newJobCategory = response;
                            }).catch(err => {
                                ToasterService.failure(err, 'failed_create_job_category');
                            });
                            scope.jobCategories.push(newJobCategory);
                            scope.modal.userJobCategories.push(newJobCategory);
                        }
                        if (!newJobCategory) {
                            newLabelFieldValue.push(jobCategory);
                            continue;
                        }

                        newJobCategory.isUserJobCategory = true;
                        
                        if (typeof rootscopecampaign !== 'undefined' && rootscopecampaign) {
                            let isInCampaignjobCategory = !!scope.modal.campaignJobCategories.find((el) => el.label === jobCategory.label);
                            if (!isInCampaignjobCategory) {
                                scope.modal.campaignJobCategories.push(newJobCategory);
                                await scope.assignJobCategory(jobCategory);
                            }
                        }
                    }
                    scope.modal.labelField = newLabelFieldValue;

                    await scope.loadJobCategories();
                    buildJobCategoriesList(scope.modal);
                }
            };

            service.openGenericPopup(scope, config, 'templates/modal-create-job-category.html', {});
        },

        openCrudCreateTaskPopup: function(scope, modalTitle, initialValue) {
            const deferred = $q.defer();

            const multiSelectln = JSON.parse(JSON.stringify(multiSelect));
            const multiselectSettings = {
                ...multiSelectln.objectSettings,
                idProperty: "id",
                dynamicTitle: true,
                smartButtonMaxItems: 5,
            }
            const singleselectSettings = {
                ...multiselectSettings,
                selectionLimit: 1,
                showCheckAll: false,
                showUncheckAll: false,
                checkBoxes: false,
            };
            const singleselectSettingsNoScroll = {
                ...singleselectSettings,
                scrollable: false,
            }

            const priorityOptions = [
                { id: 5, label: Translate.getLangString('priority_low') },
                { id: 3, label: Translate.getLangString('priority_medium') },
                { id: 1, label: Translate.getLangString('priority_high') },
            ];
            const reminderOptions = [
                { id: 0, label: Translate.getLangString('no_reminder')},
                { id: 1, label: Translate.getLangString('reminder_at_time'), value: { period: 0, range: 'minutes' } },
                { id: 3, label: Translate.getLangString('reminder_30_min_before'), value: { period: 30, range: 'minutes' } },
                { id: 4, label: Translate.getLangString('reminder_1_hour_before'), value: { period: 1, range: 'hours' } },
                { id: 5, label: Translate.getLangString('reminder_1_day_before'), value: { period: 1, range: 'days' } },
                { id: 6, label: Translate.getLangString('reminder_2_day_before'), value: { period: 2, range: 'days' } },
            ];

            const newTask = {};
            const date = moment(new Date()).add(1, 'day');
            newTask.date = date.toDate().toISOString();
            newTask.time = date.hour(9).minute(0);
            newTask.priority = 5;
            newTask.selectedPriority = [priorityOptions[0]];
            newTask.selectedReminder = [reminderOptions[1]];
            newTask.collaboratorsIds = [$rootScope.user._id];
            if (initialValue) {
                for (const prop of Object.keys(initialValue)) {
                    newTask[prop] = initialValue[prop];
                }
            }
            
            const config = {
                modalClass: 'modal--create-task-from-message',
                title: modalTitle,
                priorityOptions,
                reminderOptions,
                singleselectSettings,
                singleselectSettingsNoScroll,
                task: newTask,
                submit: function(task) {
                    scope.modal.errorFields = scope.modal.validate(task);
                    if (!_.isEmpty(scope.modal.errorFields)) {
                        return;
                    }

                    const overlay = overlaySpinner.show('modal');
                    const taskDto = JSON.parse(JSON.stringify(task));
                    return Server.post(`tasks`, taskDto)
                        .then((res) => {
                            overlay.hide();
                            ToasterService.success('task_created');
                            task._id = res._id;
                            scope.modalHandle.close();
                            deferred.resolve(task);
                        })
                        .catch(err => {
                            overlay.hide();
                            ToasterService.failure(err, 'task_not_created');
                            deferred.reject(err);
                        });
                },
                validate: function(task) {
                    task.campaignId = $rootScope.campaign._id || $rootScope.candidate.campaignId;
                    task.candidateId = $rootScope.candidate._id;
                    
                    if (task.selectedPriority && task.selectedPriority.length > 0) {
                        task.priority = task.selectedPriority[0].id ? task.selectedPriority[0].id : undefined;
                    }
                    if (task.selectedReminder && task.selectedReminder.length > 0) {
                        task.singleNotif = task.selectedReminder[0].value;
                    }
            
                    const errorFields = {};
                    if (!task.title) {
                        errorFields.title = true;
                    }
                    if (!task.date) {
                        errorFields.date = true;
                    }
                    return errorFields;
                },
                syncTimeDates: function(task) {
                    if (task.date && task.time) {
                      task.date = moment(task.date).toISOString(true).slice(0, 10);
                      task.date += moment(task.time).toISOString(true).slice(10, 16);
                      task.time = moment(task.date).toDate();
                    }
                },
                collaboratorAssigned: function(task, collaboratorId) {
                    task.collaboratorsIds = [...new Set([...task.collaboratorsIds, collaboratorId])];
                },
                collaboratorRemoved: function(task, collaborator) {
                    task.collaboratorsIds = task.collaboratorsIds.filter(collabId => collabId !== collaborator._id);
                },
            };

            Object.defineProperty(newTask, 'dateString', {
                get: () => {
                    const date = newTask.date;
                    return date ? moment(date).toDate().toLocaleDateString('en-GB') : '';
                },
                set: (value) => {
                    if (value) {
                        newTask.date = moment(value, 'DD/MM/yyyy').toDate().toISOString();
                    } else {
                        newTask.date = '';
                    }
                }
            });
            config.syncTimeDates(newTask);

            service.openGenericPopup(scope, config, 'templates/modal-task-from-message.html', {});
            //service.openCrudPopup(scope, modalTitle, config, { ...newItem, ...initialValue })

            return deferred;
        },

        openCrudPopup: function(scope, modalTitle, options, initialValue) {
            const deferred = $q.defer();
            const config = {
                modalClass: 'modal--crud',
                title: modalTitle,
                identifier: 'modal-crud',
                items: [],
                submit: function() {
                    overlaySpinner.hide('modal');
                    scope.modalHandle.close();
                    deferred.resolve();
                },
                cancel: function () {
                    scope.modalHandle.close();
                    deferred.resolve();
                },
                onInitialized: function(crudList) {
                    crudList.addItem(initialValue);
                },
                ...options
            }
            service.openGenericPopup(scope, config, 'templates/modal-crud-create.html', {});
        },

        openGenericPopup: function(scope, options, templateUrl, modalOptions) {
            overlaySpinner.hide('modal');

            scope.modal = {
                modalClass: 'modal--default',
                close: function() {
                    overlaySpinner.hide('modal');
                    scope.modalHandle.close();
                },
                submit: function() {
                    overlaySpinner.hide('modal');
                    scope.modalHandle.close();
                }
            };

            if (options) {
                angular.extend(scope.modal, options);
            }

            //console.log(scope.modal);
            if (templateUrl.charAt(0) != '/') {
                templateUrl = '/' + templateUrl;
            }

            scope.modalHandle = $fancyModal.open(angular.extend({
                templateUrl: templateUrl,
                scope: scope
            }, modalOptions));
        }
    };

    return service;
}])

.factory('htmlTemplates', function() {
    /**
     * 
     * @param {string} fileName 
     * @param {any} [variables] object with variables for replacement
     * @returns {string} html from the file
     */
    async function getHtml(fileName, variables) {
        return new Promise((resolve, reject) => {
            const url = '/templates/html/'+fileName;
            fetch(url).then((resp) => {
                const text = resp.text()
                resolve(variables ? replaceVariables(text, variables) : text);
            }).catch((err) => {
                console.log('error loading html file ' + fileName, err);
                reject(err);
            });
        });
    }
    
    /**
     * 
     * @param {string} text html with variables in the format %variablename%
     * @param {Object} variables object with properties matching the name of the variables in the file
     */
    function replaceVariables(text, variables) {
        return text.replace(/%([^%]+)%/g, (matchedText, variableName) => {
            if (!variables || variables[variableName] === undefined) {
                return matchedText;
            }
            return variables[variableName];
        })
    }

    return {
        getHtml,
        replaceVariables,
    };
})

.factory('MailTemplates', ["Server", function(Server) {

    async function getMailTemplates(lang, category = 'recruiterToCandidate') {

        let dbMailTemplates = (await Server.get(`mailTemplates`)).filter(mailTemplate => {
            return mailTemplate.categories && _.includes(mailTemplate.categories, category);
        });
        const mailTemplates = 
        _.chain(dbMailTemplates)
        .map(mailtemp => {
            if(!mailtemp.title[lang] || !mailtemp.body[lang]) {
                return null;
            }
            return {
                ...mailtemp,
                title: mailtemp.title[lang],
                body: mailtemp.body[lang],
            };
        })
        .omitBy(_.isNil)
        .sortBy(m => m.title.toLowerCase())
        .value();

        return mailTemplates;
    }

    function msgTemplateClick(mailTemplates, templateKey) {
        let template = templateKey && _.find(mailTemplates, function(tmpl) {
            return tmpl.key == templateKey;
        });
        return template;
    }

    return {
        getMailTemplates: getMailTemplates,
        msgTemplateClick: msgTemplateClick,
    };
}])  

.factory('VideoService', ["$q", "Server", "Util", function($q, Server, Util) {

    var cfg = {};
    var mediaSource;
    var sourceBuffer;
    var context, analyser;
    var isAudioMeterWorking;

    var requestAnimFrame = (function(){
        return  window.requestAnimationFrame   ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame    ||
            window.oRequestAnimationFrame      ||
            window.msRequestAnimationFrame     ||
            function( callback ){
                window.setTimeout(callback, 1000 / 60);
            };
    })();

    var mediaRecorder;
    var recordedBlobs;
    var superBuffer, lastObjectUrl;
    var Video;

    var isUsingPlugin = false;
    var servers = null;
    var peerConnection;
    var handle;

    return {

        config: function(paramsObj) {
            cfg = paramsObj;
            return this;
        },

        openCamera: function() {
            var deferred = $q.defer();

            var constraints = {
                audio: true,
                video: true
            };

            var gumInit = function() {
                if (navigator && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                    var promise = navigator.mediaDevices.getUserMedia(cfg.constraints || constraints);
                    promise.then(function (stream) {
                        console.log('getUserMedia() got stream: ', stream);
                        window.stream = stream;
                        deferred.resolve();
                    },function (err) {
                        console.log('navigator.getUserMedia error: ', err);
                    });
                } else if (navigator.getUserMedia) {
                    navigator.getUserMedia(cfg.constraints || constraints, function(stream) {
                        console.log('getUserMedia() got stream: ', stream);
                        window.stream = stream;
                        deferred.resolve();
                    }, function(err) {
                        console.log(err);
                    });
                }
            };

            window.stream = false;

            if (window.AdapterJS && AdapterJS.WebRTCPlugin && AdapterJS.WebRTCPlugin.setLogLevel && AdapterJS.WebRTCPlugin.PLUGIN_LOG_LEVELS) {
                AdapterJS.WebRTCPlugin.setLogLevel(AdapterJS.WebRTCPlugin.PLUGIN_LOG_LEVELS.VERBOSE);
            }

            if (window.AdapterJS) {
                AdapterJS.webRTCReady(function (isUsingPluginValue) {
                    isUsingPlugin = isUsingPluginValue;
                    gumInit();
                });
            } else {
                gumInit();
            }

            return deferred.promise;
        },

        openMediaSource: function() {
            mediaSource = new MediaSource();

            mediaSource.addEventListener('sourceopen', function(event) {
                console.log('MediaSource opened');
                sourceBuffer = mediaSource.addSourceBuffer('video/mp4; codecs="vp8"');
                console.log('Source buffer: ', sourceBuffer);
            }, false);
        },

        connectVideoElement: function() {
            if (!window.stream)
                return;

            Video = document.querySelector(cfg.videoTargetSelector);
            if (!Video)
                return;

            if (isUsingPlugin) {
                attachMediaStream(Video, window.stream);
                return;
            };

            /* try to be compatible with older browser versions */
            if (window.URL) {
                try {
                    Video.src = window.URL.createObjectURL(window.stream);
                } catch (e) {
                    Video.src = window.stream;
                }
            } else {
                Video.src = window.stream;
            }
            if (Video.src.indexOf('[') > 0) {
                Video.srcObject = window.stream;
            }
            /* or delete above section and just do this: */
            Video.srcObject = window.stream;


            Video.muted = true;
        },

        connectAudioMeter: function() {
            if (!window.stream || !(window.AudioContext || window.webkitAudioContext)) {
                return;
            }

            context = new (window.AudioContext || window.webkitAudioContext)();

            if (!context || !context.createMediaStreamSource || !context.createBiquadFilter) {
                return;
            }

            window.inputSource = context.createMediaStreamSource(window.stream);
            var filter = context.createBiquadFilter();
            filter.frequency.value = 60.0;
            console.log(filter);
            filter.type = filter.NOTCH || 'notch';
            filter.Q = 10.0;

            analyser = context.createAnalyser();

            // Connect graph.
            window.inputSource.connect(filter);
            filter.connect(analyser);

            var updateFn = function() {
                if (!window.stream)
                    return;

                var times = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteTimeDomainData(times);
                var max = 0;
                for (var i = 0; i < times.length; i++) {
                    var value = Math.abs(times[i] - 128);
                    if (max < value) {
                        max = value;
                    }
                }

                cfg.audioMeterCallback(max);

                requestAnimFrame(updateFn)
            };

            requestAnimFrame(updateFn);
            isAudioMeterWorking = true;
        },

        isAudioMeterWorking: function() {
            return isAudioMeterWorking;
        },

        startRecording: function() {
            if (!window.MediaRecorder) {
                isUsingPlugin = true;
            }

            if (isUsingPlugin) {
                this.startBroadcasting();
                return;
            }

            recordedBlobs = [];

            function handleDataAvailable(event) {
                if (event.data && event.data.size > 0) {
                    recordedBlobs.push(event.data);
                }
            }

            function handleStop(event) {
                console.log('Recorder stopped: ', event);
            }

            var options = {mimeType: 'video/webm;codecs=vp9'};
            if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                console.log(options.mimeType + ' is not Supported');
                options = {mimeType: 'video/webm;codecs=vp8,opus'}; // Firefox uses VP8, but it crashed with 'codecs=vp8' only. Therefore needed to add audio codec specification. see https://bugzilla.mozilla.org/show_bug.cgi?id=1594466
                if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                    console.log(options.mimeType + ' is not Supported');
                    options = {mimeType: 'video/webm'};
                    if (!MediaRecorder.isTypeSupported(options.mimeType)) {
                        console.log(options.mimeType + ' is not Supported');
                        options = {mimeType: ''};
                    }
                }
            }
            try {
                mediaRecorder = new MediaRecorder(window.stream, options);
            } catch (e) {
                console.error('Exception while creating MediaRecorder: ' + e);
                //alert('Exception while creating MediaRecorder: '
                //    + e + '. mimeType: ' + options.mimeType);
                return;
            }
            console.log('Created MediaRecorder', mediaRecorder, 'with options', options);
            mediaRecorder.onstop = handleStop;
            mediaRecorder.ondataavailable = handleDataAvailable;
            mediaRecorder.start(10); // collect 10ms of data
            console.log('MediaRecorder started', mediaRecorder);
        },

        startBroadcasting: function() {
            console.log('Start broadcasting...');
            Server.get('webrtc/handle').then(function(result) {

                handle = result.handle;
                console.log(result);

                peerConnection = new RTCPeerConnection(servers);

                var addIceCandidates = function(iceCandidatesArray) {
                    console.log('addIceCandidates', iceCandidatesArray);
                    if (iceCandidatesArray && iceCandidatesArray.length) {
                        iceCandidatesArray.forEach(function(iceCandidate) {
                            var candidate = new RTCIceCandidate(iceCandidate);
                            console.log('addIceCandidate', candidate);
                            peerConnection.addIceCandidate(candidate);
                        });
                    }
                };

                peerConnection.onicecandidate = function onIceCandidate(event) {
                    if (!event || !event.candidate) {
                        return;
                    }
                    console.log('onIceCandidate ',  event.candidate.candidate);
                    var candidate = {
                        candidate: event.candidate.candidate,
                        sdpMLineIndex: event.candidate.sdpMLineIndex,
                        sdpMid: event.candidate.sdpMid
                    };

                    Server.post('webrtc/icecandidate', {
                        data: candidate,
                        handle: handle
                    }).then(function(result) {
                        addIceCandidates(result.serverIceCandidates);
                    });
                };

                peerConnection.addStream(window.stream);

                peerConnection.createOffer(function onCreateOfferSuccess(desc) {
                        peerConnection.setLocalDescription(desc,
                            function onSetLocalSuccess(pc) {
                                console.log('onSetLocalSuccess',desc);
                                Server.post('webrtc/sdpoffer', {
                                    data: desc,
                                    handle: handle
                                }).then(function processAnswer(result) {
                                    console.log('sdpAnswer', result.sdpAnswer);

                                    addIceCandidates(result.serverIceCandidates);

                                    var answer = new RTCSessionDescription({
                                        type: 'answer',
                                        sdp: result.sdpAnswer
                                    });

                                    if (peerConnection.signalingState === 'closed') {
                                        console.error('PeerConnection is closed');
                                        return;
                                    }

                                    peerConnection.setRemoteDescription(answer, function() {
                                        console.log('setRemoteDescription');

                                    }, function (error) {
                                        console.log('setRemoteDescriptionError ' + error.toString());
                                    });
                                });
                            },
                            function onSetSessionDescriptionError(error) {
                                console.log('onSetSessionDescriptionError ' + error.toString());
                            }
                        );
                    },
                    function onCreateSessionDescriptionError(error) {
                        console.log('onCreateSessionDescriptionError ' + error.toString());
                    },
                    {
                        offerToReceiveAudio: 1,
                        offerToReceiveVideo: 1
                    }
                );
            });
        },

        stopRecording: function() {
            if (isUsingPlugin) {
                return this.stopBroadcasting();
            }

            if (!window.stream || !mediaRecorder)
                return;

            mediaRecorder.stop();
            //console.log('Recorded Blobs: ', recordedBlobs);
            superBuffer = new Blob(recordedBlobs, {type: 'video/webm'});
            lastObjectUrl = window.URL.createObjectURL(superBuffer);

            return lastObjectUrl;
        },

        stopBroadcasting: function() {
            console.log('Stop broadcasting...');

            if (peerConnection) {
                console.log('closing peer connection');
                peerConnection.close();
                //peerConnection = null;
            }

            return handle;
        },

        getObjectUrl: function() {
            return lastObjectUrl;
        },

        getBlobBuffer: function() {
            return superBuffer;
        },

        getHandle: function() {
            return handle;
        },

        play: function() {
            var recordedVideo = document.querySelector(cfg.playbackVideoSelector);

            recordedVideo.addEventListener('error', function(ev) {
                console.error('MediaRecording.recordedMedia.error()');
                console.log('Your browser can not play\n\n' + recordedVideo.src + '\n\n media clip. event: ' + JSON.stringify(ev));
            }, true);

            recordedVideo.src = lastObjectUrl;
        },

        downloadLocal: function(filename) {
            Util.downloadFile(lastObjectUrl, filename);
        },

        uploadVideoEx: function(uploadUrl, method, fileField) {

            var formData = new FormData();
            formData.append(fileField || 'file', superBuffer);
            var oReq = new XMLHttpRequest();
            //oReq.addEventListener("load", reqListener);
            oReq.open(method || 'POST', uploadUrl);
            oReq.send(formData);

            return oReq;
        },

        stop: function() {
            if (!window.stream)
                return;

            if (window.stream.getAudioTracks) {
                window.stream.getAudioTracks().forEach(function (track) {
                    track.stop();
                });
            }

            if (window.stream.getVideoTracks) {
                window.stream.getVideoTracks().forEach(function (track) {
                    track.stop();
                });
            }

            if (window.stream.stop) {
                window.stream.stop();
            }

            window.stream = false;
        }


    };
}])

.factory('overlaySpinner', ["bsLoadingOverlayService", "$templateCache", function(bsLoadingOverlayService, $templateCache) {

    $templateCache.put('bsLoadingOverlaySpinJs', '<div class="bs-overlay-custom-class" us-spinner="{{bsLoadingOverlayTemplateOptions}}"></div>');

    bsLoadingOverlayService.setGlobalConfig({
        templateUrl: 'bsLoadingOverlaySpinJs'
    });

    bsLoadingOverlayService.setGlobalConfig({
        templateOptions: {
            radius: 8,
            width: 2,
            length: 6,
            lines: 12,
            color: 'gray'
        }
    });

    if (!bsLoadingOverlayService.keyTimeout) {
        bsLoadingOverlayService.keyTimeout = {};
    };

    return {
        show: function(referenceId, id2, timeout = 1000) {
            const refId = referenceId + (id2 ? '-' + id2 : '');
            const showTimeout = setTimeout(() => {
                bsLoadingOverlayService.keyTimeout[refId] = bsLoadingOverlayService.keyTimeout[refId].filter(key => key !== showTimeout);
                bsLoadingOverlayService.start({
                    referenceId: refId
                });
            }, timeout);
            if (!bsLoadingOverlayService.keyTimeout[refId]) {
                bsLoadingOverlayService.keyTimeout[refId] = [];
            }
            bsLoadingOverlayService.keyTimeout[refId].push(showTimeout);

            return {
                id: refId,
                hide: () => {
                    bsLoadingOverlayService.keyTimeout[refId] = bsLoadingOverlayService.keyTimeout[refId].filter(key => key !== showTimeout);
                    clearTimeout(showTimeout);
                    if (bsLoadingOverlayService.keyTimeout[refId].length <= 0) {
                        bsLoadingOverlayService.stop({
                            referenceId: refId
                        });
                    }
                }
            }
        },

        showWithoutTimeout: function(referenceId, id2) {
            return this.show(referenceId, id2, 0);
        },

        hide: function(referenceId, id2) {
            const refId = referenceId + (id2 ? '-' + id2 : '');
            if (bsLoadingOverlayService.keyTimeout[refId]) {
                bsLoadingOverlayService.keyTimeout[refId].forEach(clearTimeout);
                bsLoadingOverlayService.keyTimeout[refId] = [];
            }
            bsLoadingOverlayService.stop({
                referenceId: refId
            });
        }
    };
}])

.factory('socketListener', ["$rootScope", function($rootScope) {

    let isListening = false;

    function subscribe(evtName){
        if (isListening)
            return

        let socket = connectToSocket();
        socket.on(evtName, (data) => {
            console.log('socket data = ', data);
            $rootScope.$broadcast('socketRecruiterEvent', data);
        });
        isListening = true;
    }

    function connectToSocket() {
        const hostname = window.location.hostname === 'localhost' ? window.location.hostname + ':8000' : window.location.hostname;
        const host = window.location.protocol + '//' + hostname;
        let socket = io.connect(host, { secure: true, reconnect: true, rejectUnauthorized : false, transports: ["websocket"] });
        return socket;
    }

    return {
        subscribe: subscribe,
    };
}])

.factory('igbPublicationFactory', ["Translate", function(Translate) {

    /**
     * @param {*} publicationStatus - returned from the back end, in IGB's original format. Example below
     *      {jobboards: {
     *          vdab: "",
     *          jooble_be: "Publication OK",
     *          linkedin_shares_company: "Unavailable for user",
     *          leforemacc: "Publication not active",
     *      }}
     * @returns {*} example below
     *     {
     *         label: "Posted"
     *         class: "badge--posting-posted"
     *         tooltip: "This campaign is posted on at least one job board"
     *     }
     */
    function getStatus(publicationStatus) {

        let igbStatus = {};
        igbStatus.label = Translate.getLangString('dashboard_card_posting_status_not_posted');
        igbStatus.class = 'badge--posting-not-posted';
        igbStatus.tooltip = Translate.getLangString('dashboard_card_posting_tooltip_not_posted');

        if (!publicationStatus?.jobboards || !Object.values(publicationStatus.jobboards)?.length) {
            return igbStatus;
        }

        let statuses = Object.values(publicationStatus.jobboards)
        let numberOfOkPublications = _.sumBy(statuses, status => status === "Publication OK")
        // list of status in https://admin.igb.jobs/post/status/index.php
        let isExpired = _.some(statuses, status => _.includes(status, "xpired") || _.includes(status, "reached"));

        if(isExpired) {
            igbStatus.label = Translate.getLangString('dashboard_card_posting_status_expired');
            igbStatus.class = 'badge--posting-expired';
            igbStatus.tooltip = Translate.getLangString('dashboard_card_posting_tooltip_expired');
        } else if (numberOfOkPublications && numberOfOkPublications > 0) {
            igbStatus.label = Translate.getLangString('dashboard_card_posting_status_posted');
            igbStatus.class = 'badge--posting-posted';
            igbStatus.tooltip = Translate.getLangString('dashboard_card_posting_tooltip_posted');
        }

        return igbStatus;
    }

   /**
    * 
    * @param {*} publicationStatus 
    * @returns {Array}  [  {
        boardKey: "indeed",
        status: "Not mapped",
        expiryDate: "02/04/2021",
        link: "https://indeed.com/vacancy/12345"
    },{ ... } ]
    */
    function getStatusInArray(publicationStatus) {
        let publicationStatusArray = []
        _.forEach(publicationStatus.jobboards, (status, boardKey) => {
            let statusIsValid = status !== "Unavailable for user" && status !== "Not available for user"
            if(status && statusIsValid) {
                publicationStatusArray.push({
                    boardKey: boardKey,
                    status: status,
                    expiryDate: (publicationStatus.expected_expiration && typeof publicationStatus.expected_expiration[boardKey] === 'string') ? moment(publicationStatus.expected_expiration[boardKey], 'Y-M-D H:m:s').format('D/M/YY') : '',
                    link: publicationStatus.job_detail_url[boardKey],
                });
            }
        })
        return publicationStatusArray;
    }

    return {getStatus, getStatusInArray};
}])

.factory('talentplugPublicationFactory', ["Translate", function(Translate) {
    
    /**
     * @param {*} publicationStatus - returned from the back end, in IGB's original format. Example below
     *      "jobBoards": [
     *          {
     *              "id": "90",
     *              "status": "Offre en cours",
     *              "reason": "Annonce en cours de diffusion",
     *              "name": "MeteoJob"
     *          },
     *          {
     *              "id": "57301",
     *              "status": "Offre en cours",
     *              "reason": "Annonce en cours de diffusion",
     *              "name": "Pole Emploi Démo Sales"
     *          }
     *      ],
     * @returns {*} example below
     *     {
     *         label: "Posted"
     *         class: "badge--posting-posted"
     *         tooltip: "This campaign is posted on at least one job board"
     *     }
     */
    function getStatus(publicationStatus) {

        let tpStatus = {};
        tpStatus.label = Translate.getLangString('dashboard_card_posting_status_not_posted');
        tpStatus.class = 'badge--posting-not-posted';
        tpStatus.tooltip = Translate.getLangString('dashboard_card_posting_tooltip_not_posted');
        
        if (!publicationStatus?.data?.jobBoards || !publicationStatus.data.jobBoards.length) {
            return tpStatus;
        }

        let numberOfOkPublications = publicationStatus.data.jobBoards.filter(board => board.status === "Offre active").length;
        // Check if at least one job board is active
        if (numberOfOkPublications > 0){
            tpStatus.label = Translate.getLangString('dashboard_card_posting_status_posted');
            tpStatus.class = 'badge--posting-posted';
            tpStatus.tooltip = Translate.getLangString('dashboard_card_posting_tooltip_posted');
        } else {
            // If no job board is active, check if at least one job board is pending
            let isPending = publicationStatus.data.jobBoards.some(jb => jb.status === "Offre en cours");
            if (isPending){
            tpStatus.label = Translate.getLangString('dashboard_card_posting_status_pending');
            tpStatus.class = 'badge--posting-pending';
            tpStatus.tooltip = Translate.getLangString('dashboard_card_posting_tooltip_pending');
            } else {
                // If no job board is active or pending, check if every job board is expired
                let isInactive = publicationStatus.data.jobBoards.every(jb => _.includes(jb.status, "Offre inactive") || _.includes(jb.status, "reached"));
                if(isInactive) {
                    tpStatus.label = Translate.getLangString('dashboard_card_posting_status_inactive');
                    tpStatus.class = 'badge--posting-inactive';
                    tpStatus.tooltip = Translate.getLangString('dashboard_card_posting_tooltip_inactive');
                }
            }
        }

        return tpStatus;
    }

    return {getStatus};
}])

.filter('minutes', function() {
    return function(seconds) {
        if (!seconds || seconds < 0) {
            return '0:00';
        }
        var minutes = Math.floor(seconds / 60);
        var secLeft = Math.floor(seconds % 60);
        if (secLeft < 10) {
            secLeft = '0' + secLeft;
        }
        return minutes + ':' + secLeft;
    }
})

.filter('minutes2', function() {
    return function(seconds) {
        if (!seconds || seconds < 0) {
            return '00:00';
        }
        var minutes = Math.floor(seconds / 60);
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        var secLeft = Math.floor(seconds % 60);
        if (secLeft < 10) {
            secLeft = '0' + secLeft;
        }
        return minutes + ':' + secLeft;
    }
})

.filter('bytes', ["Util", function(Util) {
    return Util.formatBytes
}])

.filter('mentions', ["$sce", function($sce) {
    var regex = new RegExp('(<@([^>]*)>)', 'g');

    return function(str) {
        //console.log(str);
        return $sce.trustAsHtml(str.replace(regex, function(match, full, name) {
            return '<span class="mention">' + name + '</span>';
        }));
    };
}])

.filter('longDateTime', ["Translate", function(Translate) {

    return function(date) {
        if (date) {
            if (typeof(date) == 'string') {
                date = new Date(date);
            }
            return Translate.getMonthName(date.getMonth()) + ' ' + date.getDate() + ', ' + date.getFullYear() + " " + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2);
        }
        return '';
    };
}])

.filter('longDate', ["Translate", function(Translate) {

    return function(date) {
        if (date) {
            if (typeof(date) == 'string') {
                date = new Date(date);
            }
            return date.getDate() + ' ' + Translate.getMonthName(date.getMonth()) + ' ' + date.getFullYear();
        }
        return '';
    };
}])

.filter('timeOnly', ["Translate", function(Translate) {

    return function(date) {
        if (date) {
            if (typeof(date) == 'string') {
                date = new Date(date);
            }
            return date.getHours() + ':' + ('00' + date.getMinutes()).slice(-2);
        }
        return '';
    };
}])

.filter('replaceFirstParagraphBySpan', ["$sce", function($sce) {
    return function(input) {
        if (typeof input !== 'string' || !input) {
            return '';
        }
        var replaced = input.replace(/<p>(.*?)<\/p>/, '<span>$1</span>');
        return replaced;
    };
}])

.factory('env', function() {
    return window.__env || {}
})

.factory('Util', ["$rootScope", "Translate", "overlaySpinner", "Upload", "Server", "privilegeFactory", function($rootScope, Translate, overlaySpinner, Upload, Server, privilegeFactory) {

    let exportedFunctions = {};

    exportedFunctions.userFullName = function(user) {
        if (!user)
            return '';
        if (user.name && user.name.trim()) {
            return user.name;
        }
        if (user.firstName || user.lastName) {
            return (user.firstName || '') + ' ' + (user.lastName || '');
        }
        if (user.username && user.username.trim()) {
            return user.username;
        }
        return user.email;
    }

    exportedFunctions.profilePhoto = function(photoSrc, upload) {
        if (!photoSrc) {
            if (upload) {
                return '/imgs/profile-default-upload.png';
            }
            return '/imgs/profile-default.png';
        }
        return photoSrc;
    }

    exportedFunctions.editProfilePhoto = function(scope, me, PopupService, uploadUrl, getUrl, customization) {
        PopupService.openGenericPopup(scope, {
            modalClass: 'modal--profile-photo',
            upload: function (dataUrl, name, file) {
                overlaySpinner.show('modal');
                Upload.upload({
                    url: Server.makeUrl(uploadUrl),
                    data: {
                        originalFilename: file ? file.name : name,
                        file: file ? file : Upload.dataUrltoBlob(dataUrl, name),
                        documentIndex: 0
                    }
                }).then(function (response) {
                    scope.modal.result = response.data;
                    Server.get(getUrl)
                    .then(function(data) {
                        if (data && data.photoSrc) {
                            me.photoSrc = data.photoSrc + '?' + new Date().getTime();
                        }
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                    });
                }, function (response) {
                    if (response.status > 0) {
                        scope.modal.errorMsg = response.status + ': ' + response.data;
                    } else {
                        overlaySpinner.hide('modal');
                        scope.modalHandle.close();
                    }
                }, function (evt) {
                    scope.modal.progress = parseInt(100.0 * evt.loaded / evt.total);
                });
            },
            customization: customization || {}
        }, 'templates/modal-photo.html', {});
    }

    exportedFunctions.downloadFile = function(href, filename) {
        var a = document.createElement('a');
        a.style.display = 'none';
        a.href = href;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        setTimeout(function() {
            document.body.removeChild(a);
        }, 100);
    }

    exportedFunctions.checkPassword = function(pwd1, pwd2) {

        if (!pwd1) {
            return Translate.getLangString('must_enter_password');
        }

        let pwdStrengthPercent = zxcvbn(pwd1).score * 25; // Score of the password strength on a scale from 0 to 100
        const pwdStrengthLimit = 75;

        if (pwd1 != pwd2) {
            return Translate.getLangString('passwords_dont_match');
        } else if (pwdStrengthPercent < pwdStrengthLimit) {
            return Translate.getLangString('password_too_weak') + pwdStrengthPercent + '%';
        } else {
            return null;
        }
    }

    exportedFunctions.matchingFcts = {
        qUses: function(question) {
            // question uses matching
            // true if the question has a positive matching coefficient
            return question
                && question.matching
                && (typeof question.matching.coef == 'number')
                && (question.matching.coef != 0)
                && (question.mode == 1 || question.mode == 2 || question.mode == 3);
        },
        cUses: function(candidate) {
            // candidate uses matching
            // true if the candidate has a computed score, even 0
            return candidate && candidate.matching && (typeof candidate.matching.score == 'number');
        },
        getResultWPercent: function(question) {
            if(this.qUses(question) && (typeof question.matching.resultW == 'number')) {
                return (question.matching.resultW * 100).toString().split('.')[0];
            } else {
                return '';
            }
        },
        getScorePercent: function(candidate) {
            if(this.cUses(candidate)) {
                return (candidate.matching.score * 100).toString().split('.')[0];
            } else {
                return '';
            }
        }
    }

    exportedFunctions.countWords = function (str) {
        str = str.replace(/(^\s*)|(\s*$)/gi, "");
        str = str.replace(/[ ]{2,}/gi, " ");
        str = str.replace(/\n /, "\n");
        return str.split(/\s/).length;
    }

    /**
     * from https://stackoverflow.com/questions/4068373/center-a-popup-window-on-screen
     */
    exportedFunctions.openCenteredWindow = function(opts) {
        const url = opts.url;
        const w = opts.w;
        const h = opts.h;
        // Fixes dual-screen position                             Most browsers      Firefox
        const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
        const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

        const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
        const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

        const systemZoom = width / window.screen.availWidth;
        const left = (width - w) / 2 / systemZoom + dualScreenLeft
        const top = (height - h) / 2 / systemZoom + dualScreenTop
        const newWindow = window.open(url, '_blank', 'scrollbars=yes, width=' + w / systemZoom + ', height=' + h / systemZoom + ', top=' + top + ', left=' + left);

        if (window.focus) newWindow.focus();
    }

    function getCampaignTitle(campaign, user) {
        if (!campaign) {
            return '';
        }
        if (user?.settings?.ui?.usesPrivateCampaignTitle && campaign.privateTitle) {
            return campaign.privateTitle;
        } else if (campaign.title && campaign.title[campaign.language ?? 0]) {
            return campaign.title[campaign.language ?? 0];
        } else if (typeof campaign.title === "string") {
            return campaign.title;
        } else {
            return Translate.getLangString('campaign_name_empty');
        }
    }

    exportedFunctions.getDisplayedTitle = function(campaign, user) {
        return getCampaignTitle(campaign, user);
    }

    exportedFunctions.getDisplayedTitleAndEmployerBranding = function(campaign, user) {
        let campaignTitle = getCampaignTitle(campaign, user);
        let shouldAddEBName = campaign.customization?.employerBranding?.isMainEB !== true;
        let employerBrandingName = shouldAddEBName ? campaign.customization?.employerBranding?.name : '';
        if (employerBrandingName) {
            campaignTitle += " (" + employerBrandingName + ")";
        }
    
        return campaignTitle;
    }

    exportedFunctions.getDisplayedTitleAndArchiveStatus = function(campaign, user) {
        let statusTextKey = ''
        if (campaign.isDeleted) {
            statusTextKey = 'deleted_campaign';
        } else if (campaign.isArchived) {
            statusTextKey = 'archived_campaign';
        }
        return exportedFunctions.getDisplayedTitle(campaign, user)
        + (statusTextKey ? ` [${Translate.getLangString(statusTextKey)}]` : '');
    }

    exportedFunctions.realCandidatesFilter = function(candidate) {
        return candidate.step >= 2;
    }

    exportedFunctions.sortByDisplayedTitleAndArchiveStatus = function(campaigns, user) {
        campaigns = _.map(campaigns, function (campaign) {
            return {
                _tmp_is_archived: campaign.isArchived,
                _tmp_displayed_title: exportedFunctions.getDisplayedTitle(campaign, user).toLowerCase(),
                ...campaign
            };
        });
        campaigns = _.sortBy(campaigns, '_tmp_displayed_title');
        campaigns = _.sortBy(campaigns, c => c._tmp_is_archived ? 2 : 1);
        return campaigns;
    }

    /**
     * @param { String } dateString in format ' DD / MM / YYYY'
     * @return { Date } 
     */
    exportedFunctions.getDateFromString = function(dateString) {
        let date, ar, dd, mm, yy;
        ar = String(dateString).split('/');
        if (ar[0] && ar[1] && ar[2]) {
            dd = parseInt(ar[0].trim().slice(-2), 10);
            mm = parseInt(ar[1].trim().slice(-2), 10);
            yy = parseInt(ar[2].trim().slice(-2), 10);
            if (dd > 0 && dd < 32 && mm > 0 && mm < 13 && yy >= 16) {
                date = new Date(2000 + yy, mm - 1, dd);
            }
        }
        return date;
    }

    exportedFunctions.getRelativeDate = function(date) {
        const isToday = moment(date).isSame(moment(), 'day'); 
        const isYesterday = moment(date).isSame(moment().subtract(1, 'day'), 'day'); 
        const isTomorrow = moment(date).isSame(moment().add(1, 'day'), 'day'); 
        if (isToday) {
            return moment(date).format('HH:mm');
        } else if (isYesterday) {
            return Translate.getLangString('yesterday')
        } else if (isTomorrow) {
            return Translate.getLangString('tomorrow')
        } else {
            return moment(date).format('D MMM yyyy');
        }
    }

    exportedFunctions.getRelativeDateTime = function(date) {
        const dateStr = exportedFunctions.getRelativeDate(date);
        const timeStr = moment(date).format('HH:mm');
        if (dateStr === timeStr) {
            return timeStr;
        } else {
            return `${dateStr} ${Translate.getLangString('at')} ${timeStr}`;
        }
    }

    exportedFunctions.getCompleteDate = function(date) {
        return moment(date).format(`D MMMM YYYY [${Translate.getLangString('at')}] HH:mm`);
    };

    exportedFunctions.getFormattedDate = function(date) {
        return moment(date).format('D MMM YYYY');
    };
    
    exportedFunctions.listTimezones = async function() {
        try {
            return await Server.get('timezones');
        } catch (err) {
            ToasterService.failure(err, 'err_0_error_occurred');
        }
    }

    exportedFunctions.getRemainingTime = function(start, minutesLimit) {
        const limitMs = minutesLimit * 1000 * 60;
        const ellapsedMs = new Date().getTime() - start.getTime();
        if (ellapsedMs >= limitMs) {
            return '00:00:00'
        }
        const duration = moment.duration(limitMs - ellapsedMs);
        if(duration.hours()) {
            return `${duration.hours().toString().padStart(2, '0')}:${duration.minutes().toString().padStart(2, '0')}`;    
        } else if(duration.minutes() >= 10) {
            return `${duration.minutes().toString().padStart(2, '0')} m`;
        } else if(duration.minutes() < 10) {
            return `${duration.minutes().toString().padStart(2, '0')}:${duration.seconds().toString().padStart(2, '0')}`;
        };
    }

    exportedFunctions.getNextComingSession = function(sessions) {
        if(!(sessions && sessions.length))
            return;
        let futureSessions = _.filter(sessions, session => moment(session.schedule).isAfter(moment()));
        if(!(futureSessions && futureSessions.length))
            return;
        return futureSessions[0];
    }

    exportedFunctions.makeSessionTooltip = function(session) {
        if(!session)
            return;
        var datestring = moment(session.schedule).format('DD/MM/YYYY');
        var timestring = moment(session.schedule).format('HH:mm');
        let plannedOnText = ` ${Translate.getLangString('event_meetingcontent_full')} ${datestring} ${Translate.getLangString('at')} ${timestring}`;
        if(session.isPhysicalInterview) {
            return Translate.getLangString('interview_physique') + plannedOnText;
        } else {
            return Translate.getLangString('interview_live') + plannedOnText;
        }
    }

    exportedFunctions.addMinutes = function(date, minutes) {
        return new Date(date.getTime() + minutes*60000);
    }

    /**
     *  see https://gist.github.com/niyazpk/f8ac616f181f6042d1e0
     */
    exportedFunctions.updateUrlParameter = function(uri, key, value) {
        // remove the hash part before operating on the uri
        var i = uri.indexOf('#');
        var hash = i === -1 ? ''  : uri.substr(i);
        uri = i === -1 ? uri : uri.substr(0, i);
    
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = uri.indexOf('?') !== -1 ? "&" : "?";
    
        if (value === null) {
            // remove key-value pair if value is specifically null
            uri = uri.replace(new RegExp("([?&]?)" + key + "=[^&]*", "i"), '');
            if (uri.slice(-1) === '?') {
                uri = uri.slice(0, -1);
            }
            // replace first occurrence of & by ? if no ? is present
            if (uri.indexOf('?') === -1) uri = uri.replace(/&/, '?');
        } else if (uri.match(re)) {
            uri = uri.replace(re, '$1' + key + "=" + value + '$2');
        } else {
            uri = uri + separator + key + "=" + value;
        }
        return uri + hash;
    }

    exportedFunctions.escapeRegex = function (string) {
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    };

    /**
     * 
     * @param { String } field - field to look in
     * @param { String } searchStr - string typed by the user to search
     * @returns 
     */
    exportedFunctions.searchInField = function (field, searchStr) {
        if (!searchStr) {
            return true;
        }
        return field && (new RegExp(searchStr, 'i')).test(field)
    };

    /**
     * 
     * @param { Object } translatedField - Object with values {0: ..., 1: ..., } for each language
     * @param { String } searchStr - string typed by the user to search 
     * @returns 
     */
    exportedFunctions.searchInTranslatedField = function (translatedField, searchStr) {
        const languageCount = Translate.languageCount;
        let stringIsInField = false;
        for (var i = 0; i < languageCount; ++i) {
            if(exportedFunctions.searchInField(translatedField[i], searchStr)) {
                stringIsInField = true;
            }
        }
        return stringIsInField;
    };

    /**
     * 
     * @param {[String]} simpleFields - simple text fields to be searched in
     * @param {[Object]} translatedFields - Objects with values {0: ..., 1: ..., } for each language 
     * @param {*} searchStr - string typed by the user to search
     * @returns 
     */
    exportedFunctions.userSearch = function (simpleFields = [], translatedFields = [], searchString = '') {
        let searchStrings = searchString.split(/,|;/);
        // split on "," or ";" and search separately. Must match all parts
        return _.every(searchStrings, function(searchStr) {
            for (let i = 0; i < simpleFields.length ; i++) {
                if (exportedFunctions.searchInField(simpleFields[i], searchStr)) {
                    return true;
                }
            }
            for (let i = 0 ; i < translatedFields.length; i++) {
                if (translatedFields[i]) {
                    if(exportedFunctions.searchInTranslatedField(translatedFields[i], searchStr)) {
                        return true;
                    }
                }
            }
        });
    };

    /**
     * 
     * @param {Boolean} isRecruiter 
     * @returns Object like {
     *  igbJobBoards: [{
     *      value: 'indeed', 
     *      label: 'Indeed'}, ...
     * ], 
     * internalSources: [{
     *      value: 'internal', 
     *      label: 'Internal'}, ...
     * ] }
     */
    exportedFunctions.loadReferralSources = function(isRecruiter) {
        return Server.get('referral-sources.json')
        .then(function(refSourcesRaw) {
            let refSources = {
                igbJobBoards: refSourcesRaw.igbJobBoards.map(key => ({
                    value: key,
                    label: Translate.getLangString('jb_' + key),
                })),
                internalSources: refSourcesRaw.internalSources.map(key => ({
                    value: key,
                    label: Translate.getLangString('jb_' + key),
                })),
            };
            if (isRecruiter) {
                refSources.recruiterImport = refSourcesRaw.recruiterImport.map(key => ({
                    value: key,
                    label: Translate.getLangString('jb_' + key),
                }))
            }
            return refSources;
        });
    };

    exportedFunctions.getCurrencySymbol = function(currencyISO) {
        switch(currencyISO) {
            case 'USD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return currencyISO;
        }
    };

    exportedFunctions.getQueryString = function(location) {
        const result = {};
        if (!location || !location.search) {
            return result;
        }
        let searchString = location.search.trim();
        if (searchString.startsWith('?')) {
            searchString = searchString.substr(1);
        }
        const parameters = searchString.split('&');
        for (let prm of parameters) {
            if (prm) {
                const keyVal = prm.split('=');
                if (keyVal && keyVal.length == 2) {
                    result[keyVal[0]] = keyVal[1];
                }
            }
        }
        
        return result;
    };

    exportedFunctions.getQueryStringFromHash = function(location) {
        const match = /#[^\?]+(\?.+)/.exec(location.hash);
        if (match) {
            return exportedFunctions.getQueryString({ search: match[1] });
        }

        return {}
    }

    exportedFunctions.parseJwt = function(jwt) {
        const base64Payload = jwt.split('.')[1]
        const payload = base64Payload.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(payload).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    exportedFunctions.jwtExpired = function(payload) {
        if (!payload.exp) {
            return false;
        }
        return new Date().getTime()/1000 > payload.exp;
    }

    exportedFunctions.isMobileDevice = function () {
        const regex1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
        const regex2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;
        const agent = navigator.userAgent || navigator.vendor || window.opera;
        return regex1.test(agent) || regex2.test(agent.substr(0, 4))
    }

    exportedFunctions.parseBytes = function (size) {
        const matches = /(\d+)(\w+)/.exec(size);
        if (!matches) {
            throw new Error('Invalid size for parsing ' + size);
        }

        const nr = parseFloat(matches[1])
        const unit = matches[2].toLowerCase()
        let pow = 0;
        switch (unit) {
            case 'b':
            case 'bytes':
                break;
            case 'kb':
                pow = 1;
                break;
            case 'mb':
                pow = 2;
                break;
            case 'gb':
                pow = 3;
                break;
            case 'tb':
                pow = 4;
                break;
            case 'pb':
                pow = 5;
                break;
            default:
                throw new Error('Invalid unit for parsing ' + size);
        }

        return nr * Math.pow(1024, pow);
    }

    exportedFunctions.formatBytes = function(bytes) {
        if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
        var units = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'],
            number = Math.floor(Math.log(bytes) / Math.log(1024));
        return (bytes / Math.pow(1024, Math.floor(number))).toPrecision(3) +  ' ' + units[number];
    }

    /**
     * 
     * @param {{ rights: any[] }} user 
     * @param { string } requiredUserRights when specified, it lists only the user rights where the user have this required access or higher
     * @return { {id: string, value: any}[] } array with all the rights a user has. The id joins the nested properties with a dot, and the value is one of the valid values for that permission
     * @example 
     * // A user that have permissions:
     * { basic: { candidate: { list: 'edit' }, campaigns: { list: 'view' } }, special: { accessAllCampaigns: true } }
     * // Would result on the object
     * [{ id: 'candidate.list', value: 'edit' }, { id: 'campaigns.list', value: 'view' }, { id: 'accessAllCampaigns', value: true }]
     * // The Object with id "campaigns.list" would not be returned if the parameter "requiredUserRights" was defined as "edit"
     */
    exportedFunctions.getUserRights = function(user, requiredUserRights) {
        const result = [];
        const getRecursive = function(schema, prefix) {
            for (const key of Object.keys(schema)) {
                const userRightName = (prefix ? `${prefix}.${key}` : key).replace(/^(basic|special)\./, '');
                if (schema[key] === undefined || schema[key] === null) {
                    result.push({
                        id: userRightName,
                        value: schema[key],
                    });
                } else if (typeof schema[key] === 'object') {
                    getRecursive(schema[key], userRightName)
                } else {
                    if (requiredUserRights) {
                        if (!privilegeFactory.userHasRights(userRightName, requiredUserRights, user)) {
                            continue;
                        }
                    }
                    result.push({
                        id: userRightName,
                        value: schema[key],
                    });
                }
            }
        }
        if (user && user.rights) {
            getRecursive(user.rights);
        }
        return result;
    }
    /**
     * 
     * @param {{ rights: any[], userType: number }} user 
     * @param { string } requiredUserRights when specified, it lists only the user rights where the user have this required access or higher
     * @returns { string } Returns a text based on the user rights, which can result in the translation for "no_user_rights", "is_admin" or "x user_rights"
     */
    exportedFunctions.getUserRightsText = function(user, requiredUserRights) {
        if (user.userType > 0) {
            return Translate.getLangString("account_owner");
        }

        const userRights = exportedFunctions.getUserRights(user, requiredUserRights);
        let visibleRights = userRights;
        if ($rootScope.userRights) {
            visibleRights = visibleRights.filter(right => {
                const mapping = _.get($rootScope.userRights, `basic.${right.id}`) || _.get($rootScope.userRights, `special.${right.id}`) || _.get($rootScope.userRights, right.id)
                return mapping && !mapping.isHidden
            })
        }
        if (userRights.find(m => m.id === "hasAdminRights")?.value === true) {
            return Translate.getLangString("is_admin")
        } else if (visibleRights.length > 0) {
            return `${visibleRights.length} ${Translate.getLangString("user_rights")}`
        } else {
            return Translate.getLangString("no_user_rights")
        }
    }
    
    exportedFunctions.hasVideoQuestions = function(campaign) {
        return campaign
            && campaign.questions
            && campaign.questions.some(function(question) {
                return !question.isWritten;
            });
    };

    // duplicate function in back end (ref 3429)
    exportedFunctions.hasVideoAnswers = function(candidate) {
        return candidate
            && candidate.questions
            && candidate.questions.some(function(question) {
                return !question.isWritten && question.filename;
            });
    };

    exportedFunctions.trackPageFocus = function(onActive, onInactive, onBeforeUnload) {
        window.onfocus = onActive;//_.debounce(onActive, 2000);
        window.onblur = onInactive;//_.debounce(onInactive, 2000);
        window.onbeforeunload = onBeforeUnload;//_.debounce(onUnload, 2000);
    }
    exportedFunctions.stopTrackPageFocus = function() {
        window.onfocus = undefined;
        window.onblur = undefined;
        window.onbeforeunload = undefined;
    }
    exportedFunctions.capitalizeFirstLetter = function(str) {
        if (!str || str.length === 0) {
            return str;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    exportedFunctions.toCamelCase = function(str) {
        if (!str) {
            return str;
        }
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
            return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
    }
    exportedFunctions.setPageTitle = function(pageName, identifier, navTitle) {
        if (!pageName) {
            pageName = pageName || Translate.getLangString("dashboard")
        }
        if (identifier) {
            document.title = `${exportedFunctions.capitalizeFirstLetter(identifier)} | ${exportedFunctions.capitalizeFirstLetter(pageName)}`;
        } else {
            document.title = exportedFunctions.capitalizeFirstLetter(pageName);
        }
        $rootScope.pageTitle = exportedFunctions.capitalizeFirstLetter(navTitle) || exportedFunctions.capitalizeFirstLetter(identifier) || exportedFunctions.capitalizeFirstLetter(pageName);
    }

    exportedFunctions.scrollToElement = function(selector, yOffset = 0, scrollableSelector = null) {
        let element = document.querySelector(selector);
        if (!element) {
            return;
        }
        let scrollable = document.querySelector(scrollableSelector || '.scrollable') || element.parentElement;
        if (!element) {
            console.warn(`no element found to scroll with selector ${selector}`);
            return;
        }
        const elementBoundary = element.getBoundingClientRect();
        let y = elementBoundary.top + scrollable.scrollTop + yOffset;
        scrollable.scrollTo({ top: y, behavior: 'smooth' });
    }

    exportedFunctions.getTextFromHtmlString = function(html) {
        const div = document.createElement('div')
        const replacedContent = html.replaceAll(/<\/?p>|<br\/>|\\n/g, ' ')
            .replaceAll(/\s{2,}/g, ' ')
            .trim();
        div.innerHTML = replacedContent;
        const text = div.textContent || div.innerText || '';
        div.remove();
        return text;
    }
    
    return exportedFunctions;
}])

.filter('videoClipThumbUrl', ["Server", function(Server) {

    return function (videoClipId) {
        if (!videoClipId)
            return '';

        return Server.makeResourceUrl('video-clip-' + videoClipId + '-thumbnail.jpg');
    }
}])

.filter('videoClipSrc', ["Server", "$sce", function(Server, $sce) {

    return function (videoClipId) {
        if (!videoClipId)
            return null;

        var basename = Server.makeResourceUrl('video-clip-' + videoClipId);

        return [
            // {src: $sce.trustAsResourceUrl(basename + '.webm'), type: "video/webm"},
            {src: $sce.trustAsResourceUrl(basename + '.mp4'), type: "video/mp4"}
        ];
    }
}])

.filter('videoFileSrc', ["Server", "$sce", function(Server, $sce) {

    return function (filename) {
        if (!filename)
            return null;

        var webmFile = Server.makeResourceUrl(filename);

        if (webmFile.indexOf('.webm') > 0) {
            return [
                // {src: $sce.trustAsResourceUrl(webmFile), type: "video/webm"},
                {src: $sce.trustAsResourceUrl(webmFile.replace('.webm', '.mp4')), type: "video/mp4"}
            ];
        } else {
            return [
                {src: $sce.trustAsResourceUrl(webmFile), type: "video/mp4"}
            ];
        }
    }
}])

.factory('VideoClipUploader', ["Server", "$q", "Upload", "VideoService", function(Server, $q, Upload, VideoService) {

    var onVideoUploaded = function(responseText, metaData, callback) {
        if (!responseText) {
            callback('onVideoUploadedErr!');
            return;
        }

        try {
            var result = JSON.parse(responseText);
        } catch (e) {
            callback('onVideoUploadedErr2!' + e.message);
            return;
        }

        if (!result._id) {
            callback('onVideoUploadedErr3!' + result);
            return;
        }

        var id = result._id;

        Server.post('users/me/videoClips/' + id, metaData).then(function(result) {
            callback(null, result);
        });
    };

    return {
        uploadFromVideoService: function(metaData) {

            var deferred = $q.defer();

            var req = VideoService.uploadVideoEx(Server.makeUrl('users/me/videoClips'));

            //req.addEventListener("progress", updateProgress);
            req.addEventListener("load", function() {
                onVideoUploaded(req.responseText, metaData, function(err, result) {
                    if (err) {
                        return deferred.resolve({errorMessage: err});
                    }

                    deferred.resolve(result);
                });
            });

            //req.addEventListener("error", transferFailed); overlaySpinner.hide('modal'); //TODO: display error msg here
            //req.addEventListener("abort", transferCanceled);

            return deferred.promise;
        },
        uploadFromFile: function(file, metaData) {

            console.log(file, metaData);

            var deferred = $q.defer();

            Upload.upload({
                url: Server.makeUrl('users/me/videoClips'),
                data: {
                    file: file
                }
            }).then(function (resp) {
                console.log('Success ' + resp.config.data.file.name + ' uploaded. Response: ' + resp.data, resp);
                // onVideoUploaded(req.responseText);

                if (resp && resp.status == 200 && resp.data && !resp.data.error && resp.data._id) {
                    var id = resp.data._id;

                    Server.post('users/me/videoClips/' + id, metaData).then(function(result) {
                        deferred.resolve(result);
                    });
                } else {
                    deferred.resolve({errorMessage: (resp && resp.data && resp.data.error ? 'Service error: ' + resp.data.error : 'Unknown error')});
                }

            }, function (resp) {
                deferred.resolve({errorMessage: 'Error status: ' + resp.status});
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });

            return deferred.promise;
        }
    }
}])

.factory('Translate', ["$rootScope", function($rootScope) {

    var texts = window.languageTexts;
    var languageCount = texts._SHORT.length; // texts._SHORT = ['en', 'fr', 'nl', 'de', 'pt']
    reverseLangObj = {}; // must be set to  {en: 0, fr: 1, nl: 2, de: 3, pt: 4}
    _.forEach(texts._SHORT, function(short, idx) {
        reverseLangObj[short] = idx;
    });

    $rootScope.texts = texts;

    var replace = function(text, replacements) {
        _.forEach(replacements, replacement => {
            text = text.replace('...', replacement);
        });
        return text;
    }

    var languageFromUrl = function() {
        for (var i = 0; i < languageCount; ++i) {
            if (document.URL.indexOf('/' + texts._SHORT[i] + '/') >= 0) {
                return i;
            }
        }
        return 0;
    };

    var getLanguagePriority = function(campaign) {
        const uiLanguage = currentLanguage();
        const campaignLanguage = campaign ? campaign.language : undefined;
        const adminUserLang = campaign && campaign.owner ? campaign.owner.language : undefined;
        const defaultPriority = [0, 1, 2, 3, 4]

        let result = [uiLanguage, campaignLanguage, adminUserLang, ...defaultPriority].filter(m => m !== undefined)
        result = new Array(...new Set(result))
        return result;
    }

    var currentLanguage = function() {
        let language = undefined
        if (_.isNumber($rootScope.visitorLang)) {
            language = $rootScope.visitorLang;
        } else if ($rootScope.user && $rootScope.user.language >= 0) {
            language = $rootScope.user.language;
        } else if ($rootScope.me && $rootScope.me.language >= 0) {
            language = $rootScope.me.language;
        }
        if (language >= 0) {
            if (window.localStorage && window.localStorage.lang != language) {
                window.localStorage.lang = language;
            }
            return language;
        }
        if (window.localStorage && window.localStorage.lang) {
            return parseInt(window.localStorage.lang, 10);
        }
        if ($rootScope.campaign && $rootScope.campaign.language >= 0) {
            return $rootScope.campaign.language;
        }

        return languageFromUrl();
    };

    var getLangStringFromObj = function(langTextObj, debugKey, forceLanguage) {
        var language;
        if (forceLanguage === 0 || forceLanguage > 0) {
            language = forceLanguage;
        } else {
            language = currentLanguage();
        }

        if (!langTextObj) {
            console.error('Lang key not found ' + debugKey);
            return '1??????';
        }

        if (!langTextObj[language] == undefined) {
            console.error('Lang language not found ' + language);
            return '2??????';
        }

        return langTextObj[language];
    };

    var getLangString = function(key, language, replacements) {
        let langString = getLangStringFromObj(texts[key], key, language);
        if (replacements && replacements.length > 0) {
            langString = replace(langString, replacements);
        }
        return langString;
    };

    var exists = function(key) {
        return !!texts[key];
    }

    var getMonthName = function(n) {
        return getLangString('month_name_' + n);
    };

    var getLangsArray = function() {
        var ar = [];
        for (var i = 0; i < languageCount; ++i) {
            ar.push(texts._SHORT[i]);
        }
        return ar;
    };

    /** Return [{text: 'en', value: 0, full: 'English'}, {text: 'fr', value: 1, full: 'French'}, ...] */
    var getLangDropdownObject = function() {
        return getLangsArray().map(function(short, idx) {
            return {text: short, value: idx, full: texts['lang_option_' + idx][currentLanguage()]};
        });
    }

    var changeVisitorLanguage = function(lang, availableLanguages = []) {
        if (availableLanguages.length === 0 || availableLanguages.includes(lang)) {
            $rootScope.visitorLang = lang;
            sessionStorage.setItem('visitorLang', lang);
        } else {
            sessionStorage.setItem('visitorLang', availableLanguages[0]);
        }
        setHtmlLang();
    }

    /**
     * {Number} defaultLanguage : fallback language
     * Return the visitor's language based on navigator parameters
     * */
    var getVisitorNavigatorLanguage = function(defaultLanguage) {

        return _.isNumber(getCompatibleBrowerLang()) ? getCompatibleBrowerLang() : defaultLanguage;

        /** Return navigator default langage as short string (ex: 'en' or 'fr') */
        function getNavigatorShort() {
            if(navigator.languages && navigator.languages.length) {
                return navigator.languages[0].slice(0,2);
            } else if (navigator.language || navigator.userLanguage) {
                return navigator.language || navigator.userLanguage
            } else {
                return;
            }
        }

        /** Return integer of the navigator language, or undefined if it doesn't match any language of ours */
        function getCompatibleBrowerLang() {
            return _.get(reverseLangObj, getNavigatorShort(), undefined);
        }
    }

    var initVisitorLanguageFromNavigator = function(defaultLanguage, availableLanguages = []) {

        const sessionLang = parseInt(sessionStorage.getItem('visitorLang'))
        if (sessionLang && !isNaN(sessionLang) && (availableLanguages.length === 0 || availableLanguages.includes(sessionLang))) {
            changeVisitorLanguage(sessionLang);
            return;
        }

        if(!_.isNumber($rootScope.visitorLang)) {
            const navLanguage = getVisitorNavigatorLanguage(defaultLanguage)
            if (availableLanguages.length === 0 || availableLanguages.includes(navLanguage)) {
                changeVisitorLanguage(navLanguage);
                return
            }
        }

        // if no avalilable language was found, set the first available one
        if (availableLanguages.length > 0) {
            changeVisitorLanguage(availableLanguages[0]);
        }
    }

    var getLangNumberFromShort = function(short) {
        if(texts._SHORT.indexOf(short) !== -1) {
            return texts._SHORT.indexOf(short);
        }
    }

    var getShortFromLangNumber = function(lang) {
        return texts._SHORT[lang];
    }

    /**
     * Gives the translation in the current used language, or in another language depending on the preference
     * @param  {Object} langTextObj - {0: 'Hello', 1: 'Bonjour', 2: 'Dag', 3: 'Gutentag'}
     * @param  {[Number]} languages
     * @return {String} - the best translation
     */
    let getTranslationCascade = function(langTextObj, languages = []) {
        const fallbackLanguages = languages.concat([currentLanguage(), 0, 1, 2, 3]);
        for(let i = 0 ; i < fallbackLanguages.length ; i++) {
            let lang = fallbackLanguages[i];
            console.log('lang', lang)
            if(langTextObj[lang]) {
                return langTextObj[lang]; 
            }
        }
        return '';
    }

    const setHtmlLang = function() {
        try {
            const htmlEl = document.querySelector('html');
            htmlEl.setAttribute('lang', texts._SHORT[currentLanguage()]);
        } catch(err) {
            console.warn('failed to set html lang');
        }
    }

    return {
        languageFromUrl: languageFromUrl,
        currentLanguage: currentLanguage,
        getLanguagePriority: getLanguagePriority,
        getLangStringFromObj: getLangStringFromObj,
        getLangString: getLangString,
        replace: replace,
        exists: exists,
        getMonthName: getMonthName,
        texts: texts,
        languageCount: languageCount,
        getLangsArray: getLangsArray,
        getLangDropdownObject: getLangDropdownObject,
        changeVisitorLanguage: changeVisitorLanguage,
        getLangNumberFromShort: getLangNumberFromShort,
        initVisitorLanguageFromNavigator: initVisitorLanguageFromNavigator,
        getTranslationCascade: getTranslationCascade,
        setHtmlLang: setHtmlLang,
        getShortFromLangNumber: getShortFromLangNumber,
    };
}])

.filter('translate', ["Translate", function(Translate) {

    return function(langTextObj, replacement) {

        let langString = Translate.getLangStringFromObj(langTextObj);

        if(replacement) {
            langString = Translate.replace(langString, [replacement]);
        }
        
        return langString;
    };
}])

/**
 * See 'getTranslationCascade' above
 */
.filter('translateCascade', ["Translate", function(Translate) {
    return function(langTextObj, mainLang) {
        return Translate.getTranslationCascade(langTextObj, [mainLang]);
    };
}])

.filter('relativeDate', ["Util", "Translate", function (Util, Translate) {
    moment.locale(Translate.getLangString('_SHORT'));
    return Util.getRelativeDate;
}])
.filter('relativeDateTime', ["Util", "Translate", function(Util, Translate) {
    moment.locale(Translate.getLangString('_SHORT'));
    return Util.getRelativeDateTime;
}])
.filter('completeDate', ["Util", "Translate", function(Util, Translate) {
    moment.locale(Translate.getLangString('_SHORT'));
    return Util.getCompleteDate;
}])
.filter('formattedDate', ["Util", "Translate", function(Util, Translate) {
    moment.locale(Translate.getLangString('_SHORT'));
    return Util.getFormattedDate;
}])
.filter('substractOneDay', function () {
    return function(date) {
        return moment(date).subtract(1, 'days').toDate();
    };
})

.directive('myEnter', function () {
    return function (scope, element, attrs) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    scope.$eval(attrs.myEnter);
                });
                event.preventDefault();
            }
        });
    };
})

.directive('onEnterNextNextInput', function () {
    return function (scope, element) {
        element.bind("keydown keypress", function (event) {
            if(event.which === 13) {
                scope.$apply(function (){
                    var inputs = $(element[0]).closest('.question__input').find(':input');
                    inputs.eq( inputs.index(element[0])+ 2 ).focus();
                });

                event.preventDefault();
            }
        });
    };
})

.directive('ctrlEnterSend', function () {
    return {
        link: function (scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                if(event.which === 13 && (event.ctrlKey || event.metaKey)) {
                    scope.$apply(function (){
                        scope.$eval(attrs.ctrlEnterSend);
                    });
                    event.preventDefault();
                }
            });
        }
    };
})

.directive('preventClipboard', function() {
    function link(scope, element, attrs) {
        let enabled = true;

        scope.$watch(attrs.preventClipboard, function(value) {
            enabled = value === undefined ? true : !!value;
        });

        element.on('cut copy paste', function (event) {
            if (enabled) {
                event.preventDefault();
            }
        });
    }
    return {
        link: link
    };
})

/**
 * Meant for HTML input tags
 * ajusts the size of the div to fit text
 */
.directive('resizeFitText', function () {
    return function (scope, element) {
        const styleString = 'this.style.height = "";this.style.height = this.scrollHeight + 15 + "px"';
        element.attr('oninput', styleString);
        // ajust size several times to make sure the text content is already in the div
        var count = 0;
        var adjustingIntervalId = setInterval(function() {
            element.height(0);
            element.height(element[0].scrollHeight);
            count++
            if(count >= 20) {
                clearInterval(adjustingIntervalId);
            }
        }, 25);
    };
})

.directive('myInfiniteScroll', ['$window', '$timeout', function($window, $timeout) {
    return {
        scope: {
            callback: '&myInfiniteScroll',
            distance: '=myInfiniteScrollDistance',
        },
        link: function(scope, elem, attrs) {
            var win = elem;

            var onScroll = _.debounce(function(oldValue, newValue) {
                if (scope.disabled) {
                    return;
                }

                var scrollPos = elem.scrollTop() + elem.innerHeight();
                var minScrollPos = 1200;
                var totalHeight = elem[0].scrollHeight;
                var remaining = totalHeight - scrollPos;
                var shouldGetMore = scrollPos > minScrollPos && (remaining - parseInt(scope.distance || 0, 10) <= 0);

                if (shouldGetMore) {
                    $timeout(scope.callback);
                }
            }, 100);

            win.bind('scroll', onScroll);

            // Remove window scroll handler when this element is removed.
            scope.$on('$destroy', function() {
                win.unbind('scroll', onScroll);
            });

            // Check on next event loop to give the browser a moment to paint.
            // Otherwise, the calculations may be off.
            onScroll();
        }
    };
}])

.directive("vgSrc",
    function () {
        return {
            restrict: "A",
            link: {
                pre: function (scope, elem, attr) {
                    var element = elem;
                    var sources;
                    var canPlay;

                    function changeSource() {
                        if (!sources) {
                            element.attr("src", '');
                            return;
                        }

                        for (var i = 0, l = sources.length; i < l; i++) {
                            canPlay = element[0].canPlayType(sources[i].type);

                            if (canPlay == "maybe" || canPlay == "probably") {
                                element.attr("src", sources[i].src);
                                element.attr("type", sources[i].type);
                                break;
                            }
                        }
                    }

                    scope.$watch(attr.vgSrc, function (newValue, oldValue) {
                        if (!sources || newValue != oldValue) {
                            sources = newValue;
                            changeSource();
                        }
                    });
                }
            }
        }
    }
)

.directive("playWhen",
    function () {
        return {
            restrict: "A",
            link: {
                pre: function (scope, element, attr) {
                    scope.$watch(attr.playWhen, function (newValue, oldValue) {
                        if (element && element[0] && element[0].play && element[0].pause && element.attr('src')) {
                            if (newValue != oldValue) {
                                if (newValue) {
                                    element[0].play();
                                } else {
                                    element[0].pause();
                                }
                            }
                        }
                    });
                }
            }
        }
    }
)

.directive("videoClipDuration", ["Server", function (Server) {
        return {
            restrict: "A",
            link: {
                pre: function (scope, element, attr) {
                    scope.$watch(attr.videoClipDuration, function (newValue, oldValue) {
                        function clearValue() {
                            element.text('');
                        }
                        function setValue(text) {
                            element.text(text);
                        }

                        if (!newValue) {
                            return clearValue();
                        }

                        Server.getObject('videoClips', newValue).then(function(result) {
                            if (!result || !result.duration) {
                                return clearValue();
                            }
                            var time = result.duration;
                            var minutes = Math.floor(time / 60);
                            var seconds = time - minutes * 60;
                            if (minutes < 10) {
                                minutes = '0' + minutes;
                            }
                            if (seconds < 10) {
                                seconds = '0' + seconds;
                            }
                            setValue(minutes + ':' + seconds);
                        });
                    });
                }
            }
        }
    }]
)

.factory('TimeUtils', function() {
    var mL = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var mS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var service = {
        hoursAmPm: function(date) {
            var h = date.getHours();
            var m = date.getMinutes();
            var am = true;

            if (h == 12) {
                am = false;
            }
            if (h > 12) {
                h -= 12;
                am = false;
            }

            if (m < 10) {
                m = '0' + m;
            }

            return h + ':' + m + (am ? 'AM' : 'PM');
        },
        HMM: function(date) {
            var h = date.getHours();
            var m = date.getMinutes();

            if (m < 10) {
                m = '0' + m;
            }

            return h + ':' + m;
        },
        addMonths: function (currentDate, numberMonths) {
            if (numberMonths == 0)
                return;
            var dateObject = currentDate;
            var day = dateObject.getDate();
            dateObject.setHours(20);
            dateObject.setMonth(dateObject.getMonth() + numberMonths + 1, 0);
            dateObject.setDate(Math.min(day, dateObject.getDate()));
            return dateObject;
        },
        buildMonthOptionsMenu: function(date) {
            var month = date.getMonth();
            var year = date.getFullYear();

            var months = [];
            for (let offset = 0; offset < 6; offset++) {
                const dateOption = new Date(year, month + offset, 1);
                const optMonth = dateOption.getMonth()
                const optYear = dateOption.getFullYear()
                months.push({
                    text: mS[optMonth] + ' ' + optYear,
                    text2: mL[optMonth] + ' ' + optYear,
                    month: optMonth,
                    year: optYear,
                    offset: offset,
                })
            }

            return months;
        },
        buildWeeksObject: function(currentDate) {
            var month = currentDate.getMonth();
            var year = currentDate.getFullYear();
            var dateNow = new Date();

            var days = [];
            for (var day = 1; day < 32; ++day) {
                var date = new Date(year, month, day);
                if (date.getMonth() == month) {
                    var today = (dateNow.getDate() == day && dateNow.getMonth() == month && dateNow.getFullYear() == year);
                    var past = (date.getTime() < dateNow.getTime());
                    var monthNumber = month + 1;
                    if (monthNumber < 10) monthNumber = '0' + monthNumber;
                    days.push({
                        day: day,
                        weekday: date.getDay(),
                        month: month,
                        mS: mS[month],
                        mL: mL[month],
                        year: year,
                        date: date,
                        past: past,
                        today: today,
                        title: day + '/' + monthNumber
                    });
                }
            }

            var weeks = [[]];
            var week = 0;
            var pad = 6;

            if (days[0].weekday > 0) {
                pad = days[0].weekday - 1;
            }

            while (pad > 0) {
                weeks[week].push({empty: true});
                --pad;
            }

            while (days.length) {
                var d = days.shift();
                if (d.today) {
                    d.past = false;
                }
                weeks[week].push(d);
                if (d.weekday == 0) {
                    ++week;
                    weeks.push([]);
                }
            }

            if (weeks[week].length > 0) {
                while (weeks[week].length < 7) {
                    weeks[week].push({empty: true});
                }
            }

            return weeks;
        },
        updateSessionTime: function(session) {
            if (!session) {
                return;
            }

            var now = new Date();

            var start = new Date(session.schedule);
            var end = new Date();
            end.setTime(start.getTime() + session.duration * 60 * 1000);
            var toStart = Math.round((start.getTime() - now.getTime()) / 1000);
            var fromStart = Math.round((now.getTime() - start.getTime()) / 1000);
            var toEnd = Math.round((end.getTime() - now.getTime()) / 1000);

            session.time = {
                start: start,
                end: end,
                now: now,
                toStart: (toStart > 0 ? toStart : 0),
                fromStart: (fromStart > 0 ? fromStart : 0),
                toEnd: (toEnd > 0 ? toEnd : 0),
                overTime: (toEnd < 0 ? -toEnd : 0)
            };

            if (session.state == 0 && session.time.toStart == 0) {
                session.state = 1;
            }
        }

    };

    return service;
})

.factory('eqTestData', ["$rootScope", function($rootScope) {
    return {
        check: function() {
            if ($rootScope.me && $rootScope.me.campaignId && window.localStorage) {
                var campaignId = $rootScope.me.campaignId;
                if (window.localStorage.eqTests && window.localStorage.eqTests.indexOf(campaignId) >= 0) {
                    return true;
                }
            }

            return false;
        },
        save: function() {
            if ($rootScope.me && $rootScope.me.campaignId && window.localStorage) {
                var campaignId = $rootScope.me.campaignId;

                if (window.localStorage.eqTests && window.localStorage.eqTests.indexOf(campaignId) >= 0) {
                    return;
                }
                if (!window.localStorage.eqTests) {
                    window.localStorage.eqTests = campaignId;
                } else {
                    window.localStorage.eqTests += ' ' + campaignId;
                }
            }
        }
    };
}])

.directive('stars', function () {
    var update = function(rating, element) {
        for (var i = 1; i <= 5; ++i) {
            var w = 0;
            if (i <= rating) {
                w = '100%';
            }
            if (rating > i - 1 && rating < i) {
                w = Math.round((rating - (i - 1)) * 100) + '%';
            }
            //console.log(rating, i, w);

            element.find('.star-' + i).width(w);
        }
    };

    return {
        restrict: 'E',
        scope: {
            iconClass: '=',
            iconClassFilled: '=',
            rating: '=',
            onRate: '&'
        },
        templateUrl: '/templates/controls/icon-star.html',
        link: function (scope, element, attrs) {
            //console.log(scope, element, attrs);
            scope.rate = function(a) {
                scope.onRate({$rate: a});
            };

            update(scope.rating, element);

            scope.$watch('rating', function(rating) {
                update(rating, element);
            });
        }
    }
})

// Metis oussama
// code metis tags remove with click right
.directive( 'contextMenu', ["$compile", "$window", function($compile, $window){
    contextMenu = {};
    contextMenu.restrict = "AE";
    contextMenu.link = function( lScope, lElem, lAttr ) {
        lElem.on('contextmenu', function (e) {
            if($('#contextmenu-node') ) {
                $('#contextmenu-node').remove();
            }
            e.preventDefault(); // default context menu is disabled
            // don't try to reopen if right clicking inside the context menu
            if (e.target.id !== 'contextmenu-node' && $(e.target).parents('#contextmenu-node').length === 0) {
                lElem.append( $compile( lScope[ lAttr.contextMenu ])(lScope) );
                var element = $('#contextmenu-node');
                positionMenu(e, element[0]);
            }
        });
        // lElem.on('mouseleave', function(e){
        //     console.log('Leaved the div');
        //     // on mouse leave, the context menu is removed.
        //     if($('#contextmenu-node') )
        //         $('#contextmenu-node').remove();
        // });

        //worked but too long
        document.addEventListener('click', function(e){
            let inside = (e.target.closest('#container'));
            if(!inside){
              let contextMenu = document.getElementById('#contextmenu-node');
                // contextMenu.setAttribute('style', 'display:none');
                $('#contextmenu-node').remove();
                // console.log("here boy outside");
            }
          });
    };
    var clickCoords;
    var clickCoordsX;
    var clickCoordsY;
    function positionMenu(e,menu) {
        clickCoords = getPosition(e);
        clickCoordsX = clickCoords.x;
        clickCoordsY = clickCoords.y;

        const menuRect = menu.getBoundingClientRect();
        
        let posX = clickCoordsX;
        if ((posX + menuRect.width) > ($window.innerWidth - 10)) {
            posX = (posX + menuRect.width) - ($window.innerWidth - 10);
        }
        let posY = clickCoordsY;
        if ((posY + menuRect.height) > ($window.innerHeight - 10)) {
            posY = (posY + menuRect.height) - ($window.innerHeight - 10);
        }

        if (e.target.offsetParent) {
            const offsetRect = e.target.offsetParent.getBoundingClientRect();
            posX -= offsetRect.x;
            posY -= offsetRect.y;
        }

        menu.style.left = posX + "px";
        menu.style.top =  posY + "px";
      }
    function getPosition(e) {
        var posx = 0;
        var posy = 0;

        if (!e) var e = window.event;

        if (e.pageX || e.pageY) {
          posx = e.pageX;
          posy = e.pageY;
        } else if (e.clientX || e.clientY) {
          posx = e.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
          posy = e.clientY + document.body.scrollTop + document.documentElement.scrollTop;
        }

        return {
          x: posx,
          y: posy
        }
    }
    return contextMenu;
}])

.filter('questionsRatingsTooltip', ["$rootScope", "Translate", function($rootScope, Translate) {

    return function(candidate) {
        if (!candidate || !candidate.reviews || !candidate.rating || candidate.rating == '0.0')
            return;

        var html = '';
        var totalSum = 0;
        var name, photoSrc, i;
        for (var id in candidate.reviews) {
            name = '';
            if ($rootScope.user && id == $rootScope.user._id) {
                name = $rootScope.userFullName($rootScope.user) + Translate.getLangString('ratings_content_you');
                photoSrc = $rootScope.user.photoSrc;
            }
            if ($rootScope.campaign && $rootScope.campaign.collaborators) {
                for (i in $rootScope.campaign.collaborators) {
                    if ($rootScope.campaign.collaborators[i]._id == id) {
                        name = $rootScope.userFullName($rootScope.campaign.collaborators[i]);
                        photoSrc = $rootScope.campaign.collaborators[i].photoSrc;
                        break;
                    }
                }
            }
            var sum = 0, count = 0;
            for (i in candidate.reviews[id]) {
                if (i != 'reviewed' && candidate.reviews[id][i] > 0 && candidate.reviews[id][i] < 6) {
                    sum += candidate.reviews[id][i];
                    ++count;
                }
            }
            if (name && sum > 0 && count > 0) {
                var rating = sum / count;
                //console.log(id, rating, sum, count);
                ++totalSum;
                html += '<li class="tooltip-ratings__field">';
                html += '<img class="tooltip-ratings__photo profile-photo candidates-profiles__members-photo" src="' + $rootScope.profilePhoto(photoSrc) + '" >';
                html += '<div class="tooltip-ratings__info">';
                html += '<span>' + name + '</span>';
                //html += '<span>' + rating + '</span>';
                html += '<div class="profile-field__stars">';
                html += starsHtml(rating);
                html += '</div>';
                html += '</div>';
                html += '</li>';
            }
        }

        var html2 = '<ul>';

        html2 += '<li class="tooltip-ratings__field tooltip-ratings__field--count">';
        html2 += '<span>' + Translate.getLangString('ratings_content_count_reviewers') + '</span>';

        html2 += '</li>';

        html = html2 + html + '</ul>';

        //console.log(html);
        //console.log('');
        return html;
    };
}])

.filter('allRatingsTooltip', ["$rootScope", "Translate", function($rootScope, Translate) {

    return function(candidate) {
        if (!candidate || !candidate.rating || !candidate.rating.total)
            return;

        var html = '';

        let ratingTypes = [
            {
                key: 'questions',
                rating: candidate.rating.questions,
                title: Translate.getLangString('questions'),
            },
            {
                key: 'interviews',
                rating: candidate.rating.interviews,
                title: Translate.getLangString('interviews'),
            },
            {
                key: 'other',
                rating: candidate.rating.other,
                title: Translate.getLangString('rating_notes'),
            },
        ];

        _.forEach(ratingTypes, ratingType => {
            if(!ratingType.rating)
                return;
            html += '<li class="tooltip-ratings__field tooltip-ratings__field--small">';
            html += '<div class="tooltip-ratings__info">';
            html += '<span>' + ratingType.title + '</span>';
            html += '<div class="profile-field__stars">';
            html += starsHtml(ratingType.rating);
            html += '</div>';
            html += '</div>';
            html += '</li>';
        });

        return html;
    };
}])
.filter('printQuestion', ["Translate", function(Translate) {
    return function(question) {
        if (!question)
            return '';
        var result = question.text || '';

        if (result.indexOf('video-clip-') === 0) result = '(' + Translate.getLangString('video_question_option') + ')';

        return result;
    }
}])

.filter('printAnswers', ["Translate", function(Translate) {
    return function(question) {
        if (!question)
            return '';

        if (question.skipped)
            return Translate.getLangString('skipped_question');

        if (question.mode === 1 && question.answer && question.predefinedAnswers && question.predefinedAnswers[parseInt(question.answer, 10)]) {
            return question.predefinedAnswers[parseInt(question.answer, 10)].text || '';
        }

        if (question.mode === 2 && question.answer && question.predefinedAnswers && question.predefinedAnswers.length) {
            var ar = question.answer.split(',');
            var result = [];
            for (var i = 0; i < ar.length; ++i) {
                var index = parseInt(ar[i], 10);
                var answer = question.predefinedAnswers[index] || {};
                result.push(answer.text || '');
            }
            return result.join('- ');
        }

        return question.answer || '';
    }
}])

.factory('Hash', function() {
    var bcrypt = dcodeIO.bcrypt;

    return {
        make: function(text) {
            return md5(text) + ' ' + bcrypt.hashSync(text, '$2a$10$.IyhBq7nEtS1rVOFDlPpRe');
        }
    };
})
.factory('drawProgressCircle', function () {

    /**
     * 
     * @param {jQuery div} container - in which to draw the circle
     * @param {*} score - between 0 and 1
     * @param {*} options - for styling
     */
    function drawCircle(container, score, options) {
        let width = container.width();
        container.circleProgress({
            size: width,
            startAngle: -Math.PI / 4 * 2,
            value: score,
            fill: options.getScoreColor(score),
            emptyFill: options.getScoreColorEmpty(score),
            thickness: 5,
            animation: false,
        })
    };

    let matchingColors = {
        above_75: {fill: '#008000', empty: '#40ff40'},
        above_50: {fill: '#FFB700', empty: '#f8db9e'},
        above_25: {fill: '#f4a460', empty: '#f9d1af'},
        above_0: {fill: '#b22222', empty: '#e78181'},
    };

    let scoreColorFunctions = {
        full: {
            matching: function(score) {
                if(score <= 0.25) {
                    return matchingColors.above_0.fill;
                } else if (score <= 0.5) {
                    return matchingColors.above_25.fill;
                } else if (score <= 0.75) {
                    return matchingColors.above_50.fill;
                } else if (score <= 1) {
                    return matchingColors.above_75.fill;
                }
            },
            assessFirst: function(score) {
                return '#9BD420'; // $assessfirst-green 
            },
        },
        empty: {
            matching: function(score) {
                if(score <= 0.25) {
                    return matchingColors.above_0.empty;
                } else if (score <= 0.5) {
                    return matchingColors.above_25.empty;
                } else if (score <= 0.75) {
                    return matchingColors.above_50.empty;
                } else if (score <= 1) {
                    return matchingColors.above_75.empty;
                }
            },
            assessFirst: function(score) {
                return '#e3ffa4'; // $assessfirst-lightgreen
            },
        },
    };

    return {
        matching: function(container, score) {
            drawCircle(container, score, {
                getScoreColor: scoreColorFunctions.full.matching,
                getScoreColorEmpty: scoreColorFunctions.empty.matching,
            });
        },
        assessFirst: function(container, score) {
            drawCircle(container, score, {
                getScoreColor: score => scoreColorFunctions.full.assessFirst(score / 100),
                getScoreColorEmpty: score => scoreColorFunctions.empty.assessFirst(score / 100),
            });
        },
    };

    // 
    // Color shading
    // 
    // function shadeColor(color, percent) {
    //     var R = parseInt(color.substring(1,3),16);
    //     var G = parseInt(color.substring(3,5),16);
    //     var B = parseInt(color.substring(5,7),16);
    //     R = parseInt(R * (100 + percent) / 100);
    //     G = parseInt(G * (100 + percent) / 100);
    //     B = parseInt(B * (100 + percent) / 100);
    //     R = (R<255)?R:255;  
    //     G = (G<255)?G:255;  
    //     B = (B<255)?B:255;  
    //     var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
    //     var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
    //     var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));
    //     return "#"+RR+GG+BB;
    // }
    // function shadeColor(color, amount) {
    //     return '#' + color.replace(/^#/, '').replace(/../g, color => ('0'+Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)).substr(-2));
    // }
})

.factory('StageFactory', ["$rootScope", "Server", "Translate", function ($rootScope, Server, Translate) {

    let allStagesDd;

    return {
        getAllStagesDd: async function() {
            if (allStagesDd) {
                return new Promise(resolve => resolve(allStagesDd));
            }
            return Server.get('stages')
            .then(response => {
                allStagesDd = response.map(function(stage){
                    const stageLabel = stage.label[Translate.currentLanguage()] + (stage.accessible ? '' : ` [${Translate.getLangString('not_accessible')}]`);
                    return {
                        ...stage,
                        text: stageLabel,
                        label: stageLabel,
                        id: stage.key,
                        customization: stage.customization || {},
                        accessible: stage.accessible,
                    };
                });

                allStagesDd = allStagesDd.map(stageDd => ({...stageDd, class: 'candidate-stage candidate-stage--in-dd'}))
                return allStagesDd;
            });
        },
        ddClick: async function(candidates, selected, date) {
            let promises = [];
            for(let i = 0 ; i < candidates.length ; i++) {
                promises.push(Server.post('candidates/' + candidates[i]._id, {
                    stage: selected.key || '_no_stage_',
                    stageDate: date,
                }).then(function(responseCandidate) {
                    candidates[i].stages = responseCandidate.stages;
                    candidates[i].stageCurrent = responseCandidate.stageCurrent;
                    candidates[i].views = responseCandidate.views;
                    return responseCandidate;
                }));
            }
            return await Promise.all(promises);
        }
    };
}])

.factory('InterviewDocuments', ["Upload", "Server", "Translate", "overlaySpinner", function (Upload, Server, Translate, overlaySpinner) {

    let exports = {};

    /**
     * Checks if all non-optional documents are uploaded
     * @param { [Object] } documents - Array of document objects
     */
    exports.checkAllUploaded = function(documents) {
        documents.forEach(function (doc) {
            doc.forceUpload = false;
            if (!doc.originalFilename && !doc.optional) {
                doc.forceUpload = true;
            }
        });
        let proceed = !_.some(documents, 'forceUpload'); // proceed if no document is forced to upload
        return proceed;
    };

    /**
     * Uploads a document to the server
     * @param { } file - from the directive 'ngf-select'
     * @param { Number } documentIndex - index of the document in the list
     * @param { Object } document - as in the database candidate.documents
     */
    exports.upload = function(file, documentIndex, documents) {

        clearCheckUploaded(documents);

        if (!file) {
            return;
        }

        let document = documents[documentIndex];
        document.uploading = true;
        document.originalFilename = "";

        let uploadSpinnerId = 'document-upload-' + documentIndex;
        overlaySpinner.show(uploadSpinnerId);
        Upload
            .upload({
                url: Server.makeUrl('candidates/me/document'),
                data: {originalFilename: file.name, file: file, documentIndex: documentIndex}
            })
            .then(function (resp) {
                overlaySpinner.hide(uploadSpinnerId);
                console.log('Success ' + ' uploaded. Response: ', resp.data);
                document.originalFilename = file.name;
                document.uploading = false;
            }, function (resp) {
                overlaySpinner.hide(uploadSpinnerId);
                console.log('Error status: ' + resp.status);
                document.uploading = false;
            }, function (evt) {
                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                console.log('progress: ' + progressPercentage + '% ' );
            });

        function clearCheckUploaded(documents) {
            documents.forEach(function (doc) {
                doc.forceUpload = false;
            });
        };
    }

    exports.getButtonTitle = function(document) {
        if (document.originalFilename && document.uploading) {
            return Translate.getLangString('interview_upload_file_btn3');
        }
        if (document.uploading) {
            return "";
        }
        return Translate.getLangString('interview_upload_file_btn1');
    };

    exports.isCV = function(doc) {
        // todo : hardcoded string is a bad practice
        return (doc && (doc.name == 'CV')) || (doc && (doc.name == 'Lebenslauf'));
    }

    exports.isNotPdfConversion = function(doc) {
        return doc && !doc.isPdfConversion;
    }

    return exports;
}])

.factory('ToasterService', ["Translate", "toaster", function (Translate, toaster) {
    let defaultSequence = '...';
    function replace(text, sequence, replacements) {
        _.forEach(replacements, replacement => {
            text = text.replace(sequence, replacement);
        });
        return text;
    }

    return {
        /**
         * Show success toaster
         * @param { String } textCode - translation key in 'anguage.yml'
         * @param { [String] } replacements - values to replace '...' with
         */
        success: function(textCode, replacements, options) {
            let body = Translate.getLangString(textCode);
            body = replace(body, defaultSequence, replacements);
            let toasterObj = {body: body};
            if (options && options.timeout)
                toasterObj.timeout = 2000;
            toaster.success(toasterObj);
        },
        /**
         * Show info toaster
         * @param { String } textCode - translation key in 'anguage.yml'
         * @param { [String] } replacements - values to replace '...' with
         */
        info: function(textCode, replacements, options) {
            let body = Translate.getLangString(textCode);
            body = replace(body, defaultSequence, replacements);
            let toasterObj = {body: body};
            if (options && options.timeout)
                toasterObj.timeout = 2000;
            toaster.info(toasterObj);
        },
        /**
         * Show warning toaster
         * @param { String } textCode - translation key in 'anguage.yml'
         * @param { [String] } replacements - values to replace '...' with
         */
        warning: function(textCode, replacements) {
            let body = Translate.getLangString(textCode);
            body = replace(body, defaultSequence, replacements);
            toaster.warning({
                body: body, 
                timeout: 10000
            });
        },
        /**
         * Show error toaster
         *  If the "err" object contains a code, then print it on the toaster
         *      Else, if a default codeis provided, then print that one
         *      Else, print the err_0_error_occurred code, translated -> "Something went wrong"
         * @param { Object } err - error object, depending on what the back end sent
         * @param { String } defaultTextCode - translation key in 'anguage.yml'
         * @param { [String] } replacements - values to replace '...' with
         */
        failure: function(error, defaultTextCode, replacements) {
            console.error(error);
            err = (error && error.data) || error;
            let body;
            if (err && err.error && err.error.code) {
                body = Translate.getLangString(err.error.code);
                if(err.error.replacements) {
                    body = replace(body, defaultSequence, err.error.replacements);
                }
            } else if (defaultTextCode) {
                body = Translate.getLangString(defaultTextCode);
            } else {
                body = Translate.getLangString('err_0_error_occurred');
            }
            body = replace(body, defaultSequence, replacements);
            return toaster.error({body: body});
        },
        /**
         * Display a toast aggregating validation messages
         * @param {Object} errorFields Object in which the keys are the fields validated, and the value is either a boolean or an error message
         */
        validationFailure: function(errorFields) {
            let messages = [];
            if (errorFields) {
                const errorKeys = Object.keys(errorFields)
                for (let field of errorKeys) {
                    if (errorFields[field]) {
                        if (errorFields[field] !== true) {
                            messages.push(errorFields[field]);
                        }
                    }
                }
            }

            let body;
            if (messages.length) {
                body = messages.join('<br />');
            } else {
                body = Translate.getLangString('err_1_form');
            }

            toaster.error({
                body,
                bodyOutputType: 'html',
                timeout: 10000
            });
        },
        /**
         * Show error toaster without using translation
         * @param { String } message - Raw message to shown on the toaster
         */
        failureMessage: function(message) {
            console.error(message);
            return toaster.error({body: message});
        },
    };
}])
.factory('Onboarding', ["Translate", "ngIntroService", "Server", "$rootScope", function(Translate, ngIntroService, Server, $rootScope) {

    var introJsDefaultOptions = {
        exitOnEsc: true,
        exitOnOverlayClick: false,
        showProgress: true,
        overlayOpacity: 0.3,
        showBullets: false,
        scrollToElement: true,
        disableInteraction: true,
        nextLabel: Translate.getLangString('onboarding_nextLabel'),
        prevLabel: Translate.getLangString('onboarding_prevLabel'),
        skipLabel: Translate.getLangString('onboarding_skipLabel'),
        doneLabel: Translate.getLangString('onboarding_doneLabel'),
    };

    function go(pageName, cb) {

        var optionsBySet = {
            'default': {
                steps: [{
                    intro: Translate.getLangString('onboarding_default'),
                }]
            },
            'dashboard-campaigns': {
                steps: [{
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_welcome'),
                },{
                    element: '#onboarding_dashboard-campaigns_existing',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_existing'),
                    position: 'right',
                },
                {
                    element: '#onboarding_dashboard-campaigns_new',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_new'),
                    position: 'right',
                    requiredUserRights: { name: 'campaigns.list', level: 'edit' },
                },
                {
                    element: '#onboarding_dashboard-campaigns_profile',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_profile'),
                    position: 'left',
                },
                {
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_end'),
                },
                {
                    element: '#onboarding-widget',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_widget'),
                }]
                .filter(function (step) {
                    // remove items if their element doesn't exist
                    return step.element ? $(step.element).length : 1;
                 })
                .filter(function(step) {
                    return !step.requiredUserRights || $rootScope.fns.userHasRights(step.requiredUserRights.name, step.requiredUserRights.level);
                })
            },
            'cc-settings': {
                steps: [{
                    intro: Translate.getLangString('onboarding_settings_welcome'),
                },{
                    element: '#onboarding_settings_settings',
                    intro: Translate.getLangString('onboarding_settings_settings'),
                    position: 'bottom',
                },
                {
                    element: '#onboarding_settings_questions',
                    intro: Translate.getLangString('onboarding_settings_questions'),
                    position: 'bottom',
                },
                {
                    element: '#onboarding_settings_documents',
                    intro: Translate.getLangString('onboarding_settings_documents'),
                    position: 'bottom',
                }],
                highlightClass: 'introjs-helperLayer__dark'
            },
            'cc-questions': {
                steps: [{
                        intro: Translate.getLangString('onboarding_questions_welcome'),
                        onbeforechange: function(){
                            $rootScope.$emit('cc-questions.onboarding.init');
                        },
                    },{
                        element: '#onboarding_questions_written',
                        intro: Translate.getLangString('onboarding_questions_written'),
                        position: 'left',
                    },{
                        intro: Translate.getLangString('onboarding_questions_written_open'),
                        onbeforechange: function(){
                            var newWrittenQuestionButton = document.querySelectorAll('#onboarding_questions_written')[0];
                            newWrittenQuestionButton.click();
                        },
                    },{
                        element: '#onboarding_questions_written_type',
                        intro: Translate.getLangString('onboarding_questions_written_type'),
                        position: 'left',
                    },{
                        element: '#onboarding_questions_written_text',
                        intro: Translate.getLangString('onboarding_questions_written_text'),
                        position: 'left',
                    },{
                        element: '#onboarding_questions_written_template',
                        intro: Translate.getLangString('onboarding_questions_written_template'),
                        position: 'right',
                    },{
                        intro: Translate.getLangString('onboarding_questions_matching_intro'),
                        onbeforechange: function(){
                            var questionTypeButton = document.querySelectorAll('#onboarding_questions_written_type')[0];
                            var allDropdownItems = document.querySelectorAll('#onboarding_questions_written_type a');
                            var multipleChoiceItem = _.filter(allDropdownItems, function(el) {return el.innerHTML == Translate.getLangString('written_question_single_choice_option');})[0];
                            questionTypeButton.click();
                            multipleChoiceItem.click();
                        },
                    },{
                        element: '#onboarding_questions_matching',
                        intro: Translate.getLangString('onboarding_questions_matching'),
                        position: 'left',
                    },{
                        element: '#onboarding_questions_video',
                        intro: Translate.getLangString('onboarding_questions_video'),
                        position: 'left',
                    },{
                        intro: Translate.getLangString('onboarding_questions_video_open'),
                        onbeforechange: function(){
                            var newVideoQuestionButton = document.querySelectorAll('#onboarding_questions_video')[0];
                            // click twice : first time to close the opened written question, second time to open the video question
                            newVideoQuestionButton.click();
                            newVideoQuestionButton.click();
                        },
                    },{
                        element: '#onboarding_questions_video_text',
                        intro: Translate.getLangString('onboarding_questions_video_text'),
                        position: 'left',
                    },{
                        element: '#onboarding_questions_add_video',
                        intro: Translate.getLangString('onboarding_questions_add_video'),
                        position: 'left',
                    },
                    {
                        intro: Translate.getLangString('onboarding_questions_end'),
                    },
                    {
                        element: '#onboarding-widget',
                        intro: Translate.getLangString('onboarding_dashboard-campaigns_widget'),
                    }
                ]
            },
            'dashboard-candidates': {
                steps: [{
                    intro: Translate.getLangString('onboarding_dashboard-candidates_welcome'),
                },{
                    element: '#onboarding_dashboard-candidates_collection',
                    intro: Translate.getLangString('onboarding_dashboard-candidates_collection'),
                    position: 'left',
                },
                {
                    element: '#onboarding-widget',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_widget'),
                }]
            },
            'dashboard-assessments': {
                steps: [{
                    intro: Translate.getLangString('onboarding_dashboard-assessments_welcome'),
                },{
                    element: '#onboarding_dashboard-assessments_add_assessment',
                    intro: Translate.getLangString('onboarding_dashboard-assessments_add_assessment'),
                },{       
                    intro: Translate.getLangString('onboarding_dashboard-assessments_go_to_candidates'),
                },{
                    element: '#onboarding-widget',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_widget'),
                }
                ]

            },'dashboard-collaborators': {
                steps: [{
                    intro: Translate.getLangString('onboarding_dashboard-collaborators_welcome'),
                },{
                    element: '#onboarding_dashboard-collaborators_invite',
                    intro: Translate.getLangString('onboarding_dashboard-collaborators_invite'),
                    position: 'left',
                },{
                    element: '#onboarding__dashboard-collaborators-three-dots',
                    intro: Translate.getLangString('onboarding_dashboard-collaborators_three-dots'),
                    position: 'left',
                },{
                    element: '#onboarding-widget',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_widget'),
                }
                ]

            },'dashboard-live_interview': {
                steps: [{
                    intro: Translate.getLangString('onboarding_dashboard-live-interview_welcome'),
                    //element: '#onboarding_dashboard-collaborators_welcome'
                },{
                    element: '#onboarding_dashboard-live-interview_invite',
                    intro: Translate.getLangString('onboarding_dashboard-live-interview_invite'),
                    position: 'left',
                },{
                    element: '#onboarding_dashboard-live-interview_list',
                    intro: Translate.getLangString('onboarding_dashboard-live-interview_list'),
                    position: 'right',
                },{
                    element: '#onboarding-widget',
                    intro: Translate.getLangString('onboarding_dashboard-campaigns_widget'),
                }
                ]

            }
        };

        var options;
        if (!pageName || !optionsBySet[pageName]) {
            options = optionsBySet['default'];
        } else {
            options = optionsBySet[pageName];
        }

        ngIntroService
            .clear()
            .setOptions(Object.assign(options, introJsDefaultOptions))
            .onbeforechange(function() {
                var intro = this;
                var currentStep = intro._currentStep;

                _.forEach(options.steps, function(myStep, stepIndex) {
                    if (currentStep == stepIndex && myStep.onbeforechange) {
                        myStep.onbeforechange();
                    }
                });

                // Hack: necessary to allow actions on the view (throug onbeforechange) (see: https://github.com/mendhak/angular-intro.js/issues/48)
                setTimeout(function() {
                    for (var i = intro._currentStep + 1; i < intro._options.steps.length; i++) {
                        var currentItem = intro._introItems[i];
                        var step = intro._options.steps[i];
                        if (step.element) {
                            currentItem.element = document.querySelector(step.element);
                            currentItem.position = step.position;
                        }
                    }
                }, 300);

            })
            .start();

        cb ? cb() : null;
    }

    function initWidget(pageName) {

        var widget = $("#onboarding-widget");
        widget.show();
        widget.unbind('click').click(function(){
            go(pageName);
        });

        // Automatic launch

        setTimeout(function () {

            if (!pageName) {
                return;
            }

            if ($rootScope?.user?.onboarding?.done?.[pageName]) {
                return;
            } 

            go(pageName, function () {
                saveOnboardingAsViewed(pageName);
            });

        }, 1000);
    }

    function saveOnboardingAsViewed(pageName) {
        Server.post('users/me/onboarding', {
            pageName: pageName
        })
        .then(function (resUser) {
            $rootScope.user.onboarding = resUser.onboarding
        });
    }

    return {
        initWidget: initWidget,
    }
}])
.factory('multiSelect', ["Translate", function (Translate) {
    var defaultOptions = {
        enableSearch: true,
        keyboardControls: true,
        checkBoxes: true,
        styleActive: true,
        dynamicTitle: false,
        scrollable: true,
        scrollableHeight: 'auto',
    };

    return {
        arraySettings: $.extend(
            {
                template: '{{option}}',
                smartButtonTextConverter: function(itemText, originalItem) {return originalItem;},
            }, defaultOptions),
        objectSettings: defaultOptions,
        texts: {
            checkAll: Translate.getLangString('select_all'),
            uncheckAll: Translate.getLangString('dropdown_uncheckAll'),
            enableSearch: Translate.getLangString('dropdown_enableSearch'),
            disableSearch: Translate.getLangString('dropdown_disableSearch'),
            selectionCount: Translate.getLangString('dropdown_selectionCount'),
            searchPlaceholder: Translate.getLangString('dropdown_searchPlaceholder'),
            buttonDefaultText: Translate.getLangString('dropdown_buttonDefaultText'),
            dynamicButtonTextSuffix: Translate.getLangString('dropdown_dynamicButtonTextSuffix'),
            selectGroup: Translate.getLangString('dropdown_selectGroup'),
        },
        optionsTemplates: {
            tagsWithCategories: '<span><span ng-if="option.tagCategory" class="tag-search__category">{{option.tagCategory.label}} :</span> {{ option.label }}</span>'
        }
    };
}])

.factory('googleMaps', ["Translate", function (Translate) {
    // https://stackoverflow.com/a/13274361
    function getBoundsZoomLevel(bounds, mapDim) {
        const WORLD_DIM = { height: 256, width: 256 };
        const ZOOM_MAX = 15;
    
        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }
    
        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }
    
        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
    
        var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
    
        var lngDiff = ne.lng() - sw.lng();
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
    
        var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
    
        return Math.min(latZoom, lngZoom, ZOOM_MAX) - 1;
    }

    return {
        init: async function(mapElementId, latitude, longitude, name) {
            const { Map } = await google.maps.importLibrary("maps");

            angular.element(document).ready(function() {
                try {
                    let map = new google.maps.Map(document.getElementById(mapElementId), {
                        center: {lat: latitude, lng: longitude},
                        zoom: 15,
                        scrollwheel: false,
                        navigationControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        streetViewControl: false
                    });
                    let marker = new google.maps.Marker({
                        position: {lat: latitude, lng: longitude},
                        map: map,
                        title: name,
                    });
                    let infowindow = new google.maps.InfoWindow({
                        content: name,
                    });
                    infowindow.open(map, marker);
                } catch (e) {
                }
            });
        },
        /**
         * 
         * @param {string} mapElementId id of the html element to render the map
         * @param {{latitude, longitude, name}[]} locations 
         * @param { (map) => void } [onboundschanged]
         */
        initMany: async function(mapElementId, locations, onboundschanged) {
            const { Map } = await google.maps.importLibrary("maps");

            function getInfoContent(loc) {
                return loc.infoContent || loc.name
            }

            angular.element(document).ready(function() {
                try {
                    const bounds = new google.maps.LatLngBounds()
                    locations.forEach(function(loc){
                        if(typeof loc.latitude === 'number' && typeof loc.longitude === 'number') {
                            bounds.extend({ lat: loc.latitude, lng: loc.longitude })
                        }
                    });
                    const center = bounds.getCenter();
                    const mapDiv = document.getElementById(mapElementId);
                    const zoom = getBoundsZoomLevel(bounds, { height: mapDiv.clientHeight, width: mapDiv.clientWidth });
                    const map = new google.maps.Map(mapDiv, {
                        center: center,
                        zoom: zoom,
                        scrollwheel: false,
                        navigationControl: false,
                        mapTypeControl: false,
                        fullscreenControl: false,
                        streetViewControl: false,
                    });
                    
                    if (onboundschanged) {
                        map.addListener('idle', () => {
                            onboundschanged(map);
                        })
                    }
                    const markers = []
                    for (const location of locations) {
                        const markerObj = {
                            position: {lat: location.latitude, lng: location.longitude},
                            map: map,
                            title: location.name,
                        };
                        // dont duplicate markers
                        if (markers.find(x => x.title === location.name)) {
                            continue;
                        }
                        markers.push(markerObj);
                        const marker = new google.maps.Marker(markerObj);
                        
                        // create info window
                        const items = locations.filter(loc => loc.name === location.name);
                        const infoWindow = new google.maps.InfoWindow({
                            content: items.length === 1 ? getInfoContent(location) : items.map(i => getInfoContent(i)).join('<br/>'),
                        });
                        infoWindow.addListener('closeclick', () => {
                            location.open = false;
                        });

                        if (location.markerOptions && location.markerOptions.opacity) {
                            marker.setOpacity(location.markerOptions.opacity);
                        }
                        if (location.onclick) {
                            marker.addListener("click", () => {
                                location.onclick(marker);
                            });
                        } else {
                            marker.addListener("click", () => {
                                if (!location.open) {
                                    infoWindow.open(map, marker);
                                    location.open = true;
                                }
                            });
                        }
                    }
                } catch (e) {
                    console.warn(e)
                }
            });
        },
        embedUrl: function(location) {
            const locName = encodeURIComponent(location.name ? location.name : [location.country, location.city, location.street, location.zip].filter(x => x).join(', '));
            return `https://www.google.com/maps/embed/v1/place?q=${locName}&key=AIzaSyAyksE2Sto35Y7k9X9E9XYhjyzZ181esyY`
        },
        embedPlaceholderUrl: function() {
            return `https://www.google.com/maps/embed/v1/view?zoom=1&center=0,0&key=AIzaSyAyksE2Sto35Y7k9X9E9XYhjyzZ181esyY`
        }
    }
}])
.factory('dateRangePicker', ["Translate", function (Translate) {
    // Set the daterangepicker object (https://www.daterangepicker.com/)
    const placeholder = Translate.getLangString('analytics_date_filter');
    function initDatePicker(filters, opts, onChange){
        moment.locale(Translate.getLangString('_SHORT'));
        let ranges;
        if(opts.useLongRange) {
            ranges = {
               [Translate.getLangString('analytics_date_no_filter')]: [null, null],
               [Translate.getLangString('month_to_date')]: [moment().startOf('month'), moment()],
               [Translate.getLangString('last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
               [Translate.getLangString('quarter_to_date')]: [moment().startOf('quarter'), moment()],
               [Translate.getLangString('year_to_date')]: [moment().startOf('year'), moment()],
               [`${Translate.getLangString('year')} ${moment().year() - 1}`]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
               [`${Translate.getLangString('year')} ${moment().year() - 2}`]: [moment().subtract(2, 'year').startOf('year'), moment().subtract(2, 'year').endOf('year')],
               [`${Translate.getLangString('year')} ${moment().year() - 3}`]: [moment().subtract(3, 'year').startOf('year'), moment().subtract(3, 'year').endOf('year')],
           };
        } else {
            ranges = {
                [Translate.getLangString('analytics_date_no_filter')]: [null, null],
                [Translate.getLangString('today')]: [moment(), moment()],
                [Translate.getLangString('yesterday')]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                [Translate.getLangString('week_to_date')]: [moment().startOf('isoWeek'), moment()],
                [Translate.getLangString('last_7_days')]: [moment().subtract(6, 'days'), moment()],
                [Translate.getLangString('last_week')]: [moment().subtract(1, 'isoWeek').startOf('isoWeek'), moment().subtract(1, 'isoWeek').endOf('isoWeek')],
                [Translate.getLangString('month_to_date')]: [moment().startOf('month'), moment()],
                [Translate.getLangString('last_30_days')]: [moment().subtract(29, 'days'), moment()],
                [Translate.getLangString('last_month')]: [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                [Translate.getLangString('quarter_to_date')]: [moment().startOf('quarter'), moment()],
                [Translate.getLangString('year_to_date')]: [moment().startOf('year'), moment()],
                [Translate.getLangString('last_year')]: [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
            };
        }
        $('#analytics-date-picker').append(`<span>${placeholder}</span>`);
        $('#analytics-date-picker').daterangepicker({
            autoApply: true,
            autoUpdateInput: false,
            ranges: ranges,
            locale: {
                "customRangeLabel": Translate.getLangString('custom_range'),
            },
        }, function(start, end) {
            setLabel(start, end);
            filters.startDate = start;
            filters.endDate = end;
            if(onChange) {
                onChange();
            }
        });
        setLabel(filters.startDate, filters.endDate);
    }
    function setLabel(start, end) {
        if(start && end && start.isValid() && end.isValid()) {
            $('#analytics-date-picker span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
            $('#analytics-date-picker').addClass('filter__item--selected');
        } else {
            $('#analytics-date-picker span').html(placeholder);
            $('#analytics-date-picker').removeClass('filter__item--selected');
        }
    }
    function updateDateRangePicker(filters) {
        var datePicker = $('#analytics-date-picker').data('daterangepicker');
        if (filters.startDate && filters.endDate && filters.startDate.isValid() && filters.endDate.isValid()) {
            datePicker.setStartDate(filters.startDate);
            datePicker.setEndDate(filters.endDate);
            setLabel(filters.startDate, filters.endDate);
        } else {
            datePicker.setStartDate(moment());
            datePicker.setEndDate(moment());
            setLabel(null, null);
        }
    }
    return {
        initDatePicker: initDatePicker,
        updateDateRangePicker: updateDateRangePicker,
    };
}])
.factory('componentCache', ["$rootScope", function($rootScope) {
    const expiration = 60 * 60 * 1000 // 1 hour

    function persistCache(cacheKey, source, properties) {
        if (!cacheKey) {
            throw new Error("Cache key must be defined");
        }
        if (!source || !(source instanceof Object)) {
            throw new Error("Source for caching must be an object");
        }
        if (!properties || properties.length === 0) {
            throw new Error("Properties to cache must be defined");
        }
        
        // we make caches with a timeout to clear memory, 
        if ($rootScope.componentCache[cacheKey] && $rootScope.componentCache[cacheKey].lifespan) {
            clearTimeout($rootScope.componentCache[cacheKey].lifespan);
        }

        const data = {};
        for (let prop of properties) {
            const value = _.get(source, prop);
            data[prop] = JSON.stringify(value);
        }
        
        $rootScope.componentCache[cacheKey] = {
            lifespan: setTimeout(() => $rootScope.componentCache[cacheKey] = undefined, expiration),
            data: data
        }
    }
    function applyCache(cacheKey, target, properties, excludeProperties) {
        if (!cacheKey) {
            throw new Error("Cache key must be defined");
        }
        if (!target || !(target instanceof Object)) {
            throw new Error("Target for retrieving the cache into must be an object");
        }

        if (!$rootScope.componentCache[cacheKey]) {
            return;
        }

        const setProp = (property) => {
            if (excludeProperties && excludeProperties.includes(property)) {
                return;
            }
            const currentValue = _.get(target, property);
            let cachedValue = $rootScope.componentCache[cacheKey].data[property];
            if (cachedValue === undefined) {
                return;
            }
            cachedValue = JSON.parse(cachedValue);
            if (Array.isArray(currentValue) && Array.isArray(cachedValue)) {
                currentValue.length = 0;
                currentValue.push(...cachedValue);
            } else if (typeof currentValue === 'object' && typeof cachedValue === 'object') {
                cachedValue = {
                    ...currentValue,
                    ...cachedValue
                }
                _.set(target, property, cachedValue);
            } else {
                const cachedDateValue = moment(cachedValue);
                if (cachedDateValue.isValid() && cachedValue.length > 10) {
                    _.set(target, property, cachedDateValue);
                } else {
                    _.set(target, property, cachedValue);
                }
            }
            clearTimeout($rootScope.componentCache[cacheKey].lifespan);
            $rootScope.componentCache[cacheKey].lifespan = setTimeout(() => $rootScope.componentCache[cacheKey] = undefined, expiration);
        }
        if (!properties || properties.length === 0) {
            for(let property of Object.getOwnPropertyNames($rootScope.componentCache[cacheKey].data)) {
                setProp(property);
            }
        } else {
            for (let property of properties) {
                setProp(property);
            }
        }
    }
    function getCacheValue(cacheKey, property) {
        if ($rootScope.componentCache[cacheKey]) {
            return $rootScope.componentCache[cacheKey][property]
        }
    }
    function clearCache(cacheKey) {
        if (cacheKey) {
            $rootScope.componentCache[cacheKey] = undefined;
        }
    }

    return {
        persistCache,
        applyCache,
        getCacheValue,
        clearCache,
    };
}]
)
.factory('Cookies', function() {
    var cookiesService = {};
    
    cookiesService.init = function(gtagParam, gaParam) {
        console.log("gaParam", gaParam);
        console.log("gtagParam", gtagParam);
        var langMap = {
            0: "career-en",
            1: "career-fr",
            2: "career-nl",
            3: "career-en",
            4: "career-en"
        };
        var userLang = localStorage.getItem('lang');
        var cookiesVersion = langMap[userLang] || "career-en";
    
        window.axeptioSettings = {
            clientId: "63763d0f280d6a01f33ebdb5",
            cookiesVersion: cookiesVersion,
        };
    
        window.axeptioDefaultChoices = {
            googletagmanager: false,
            google_analytics: false,
        };
    
        (function(d, s) {
            var t = d.getElementsByTagName(s)[0],
                e = d.createElement(s);
            e.async = true;
            e.src = "//static.axept.io/sdk.js";
            t.parentNode.insertBefore(e, t);
        })(document, "script");
        // Axeptio Callback
        void 0 === window._axcb && (window._axcb = []);
        window._axcb.push(function(axeptio) {
            axeptio.on("cookies:complete", function(choices) {
                console.log("Choices from Axeptio:", choices);
                if (choices.googletagmanager && gtagParam && gtagParam.id) {
                    console.log("launching GTAG");
                    launchGTAG(gtagParam);
                } 
                if (choices.google_analytics && gaParam && gaParam.id) {
                    console.log("launching GA");
                    launchGA(gaParam);
                }
            });
        });
    
        // Function to launch Google Tag Manager
        function launchGTAG(params) {
            var el = document.createElement('script');
            el.setAttribute('type', 'text/javascript');
            el.setAttribute('async', true);
            el.setAttribute('src', '//www.googletagmanager.com/gtag/js?id=' + params.id);
            document.body.appendChild(el);
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());
            gtag('config', params.id, {
                'anonymize_ip': params.anonymizeIp || false,
                'link_attribution': true
            });
        }  
        // Function to launch Google Analytics
        function launchGA(params) {
            window.ga = window.ga || function() {
                (ga.q = ga.q || []).push(arguments);
            };
            ga('create', params.id, 'auto');
            if (params.anonymizeIp) {
                ga('set', 'anonymizeIp', true);
            }
            ga('send', 'pageview');
        } 
    };
    return cookiesService;
})


.factory('ArchiveZip', ["$rootScope", function($rootScope) {

    exports.createZip = async (fileBuffers, fileNames, zipName, res) => {
        res.setHeader('Content-Type', 'application/zip');
        res.setHeader('Content-Disposition', `attachment; filename="${zipName}"`);
    
        const archive = archiver('zip', {
            zlib: { level: 9 }
        });
    
        archive.pipe(res);
    
        fileBuffers.forEach((buffer, index) => {
            archive.append(buffer, { name: fileNames[index] });
        });
    
        archive.finalize();
    }

}])

.factory('SidebarDocumentsService', ["$timeout", "$rootScope", "overlaySpinner", function($timeout, $rootScope, overlaySpinner) {

    this.pdfjsLib = window.pdfjsLib;
    
    var service = {
        isSidebarExpanded: false,
        isFullWidth: false,
        currentDocument: null,
        currentPage: 1,
        totalPages: 1,
        searchResults: [],
        currentSearchIndex: 0,
        searchText: '',
        pdfCache: {},
        thumbnailsCache: {},
        zoomLevel: 100,
        noSearchResults: false,
        searchInitiated: false,
        searchCounter: ''
    };

    service.resetSearch = function() {
        service.searchText = '';
        service.searchResults = [];
        service.currentSearchIndex = 0;
        service.noSearchResults = false;
        service.searchInitiated = false;
        service.searchCounter = '';
        service.clearHighlights();
    };

    service.initializeCache = async function(docs) {
        try {
            const pdfjsLib = await waitForPdfJsLib();
            const promises = docs.map(function(doc, index) {
                const url = window.location.origin + '/s3/' + window.__env.s3AssessmentPdf + '?filePath=' + doc.path;
    
                return pdfjsLib.getDocument(url).promise.then(function(pdf) {
                    service.pdfCache[doc.path] = pdf;
    
                    return pdf.getPage(1).then(function(page) {
                        const scale = 0.2;
                        const viewport = page.getViewport({ scale: scale });
    
                        const canvas = document.createElement('canvas');
                        const context = canvas.getContext('2d');
                        canvas.height = viewport.height;
                        canvas.width = viewport.width;
    
                        const renderContext = {
                            canvasContext: context,
                            viewport: viewport
                        };
    
                        return page.render(renderContext).promise.then(function() {
                            service.thumbnailsCache[doc.path] = canvas.toDataURL();
                        });
                    });
                }).catch(function(reason) {
                    console.error('Error preloading PDF or thumbnail:', reason);
                });
            });
    
            return Promise.all(promises);
        } catch (error) {
            console.error('Error waiting for pdfjsLib:', error);
        }
    };


    service.toggleSidebar = function() {
        service.isSidebarExpanded = !service.isSidebarExpanded;
        if (!service.isSidebarExpanded) {
            service.isFullWidth = false;
        }
        if (service.isSidebarExpanded) {
            $timeout(function() {
                service.renderThumbnails($rootScope.assessment.linkedAssessmentPdf);
            },100);
        }
    };

    service.expandToFullWidth = function() {
        service.isFullWidth = !service.isFullWidth;
        $timeout(function() {
            service.renderPage(service.currentPage);
        }, 300);
    }

    service.showPdf = function(doc) {
        service.resetSearch();
        service.currentDocument = doc;
        
        if (service.pdfCache[doc.path]) {
            service.pdfDoc = service.pdfCache[doc.path];
            service.totalPages = service.pdfDoc.numPages;
            service.currentPage = 1;
            service.renderPage(service.currentPage);
        } else {
            const url = window.location.origin + '/s3/' + window.__env.s3AssessmentPdf + '?filePath=' + doc.path;

            if (typeof pdfjsLib === 'undefined') {
                console.error('pdfjsLib is not defined');
                return;
            }

            const loadingTask = pdfjsLib.getDocument(url);
            loadingTask.promise.then(function(pdf) {
                service.pdfDoc = pdf;
                service.pdfCache[doc.path] = pdf;
                service.totalPages = pdf.numPages;
                service.currentPage = 1;
                service.renderPage(service.currentPage);
                $rootScope.$apply();
            }, function(reason) {
                console.error('Error loading PDF:', reason);
            });
        }
    };

    service.zoomIn = function() {
        if (service.zoomLevel < 200) {
            service.zoomLevel += 10;
            service.renderPage(service.currentPage);
        }
    };

    service.zoomOut = function() {
        if (service.zoomLevel > 100) {
            service.zoomLevel -= 10;
            service.renderPage(service.currentPage);
        }
    };

    service.renderPage = function(pageNumber) {
        if (!service.pdfDoc) return;

        return service.pdfDoc.getPage(pageNumber).then(function(page) {
            const pdfViewerContainer = document.getElementById('pdfViewerContainer');
            const containerWidth = pdfViewerContainer.clientWidth;

            const defaultScale = containerWidth / page.getViewport({ scale: 1 }).width;
            const scale = (defaultScale * service.zoomLevel) / 100;
            const viewport = page.getViewport({ scale: scale });

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport
            };

            pdfViewerContainer.innerHTML = '';
            pdfViewerContainer.appendChild(canvas);

            const renderTask = page.render(renderContext);
            return renderTask.promise.then(function() {
                if (service.searchText) {
                    return service.highlightText(page);
                }
            });
        });
    };

    service.highlightText = function(page) {
        if (!page) return Promise.resolve();

        return page.getTextContent().then(function(textContent) {
            const textItems = textContent.items;
            const canvas = document.querySelector('#pdfViewerContainer canvas');
            const context = canvas.getContext('2d');
            const viewport = page.getViewport({ scale: canvas.width / page.getViewport({ scale: 1 }).width });

            context.save();
            const padding = 5;

            textItems.forEach(function(item, index) {
                const text = item.str.toLowerCase();
                const searchTextLower = service.searchText.toLowerCase();

                if (text.includes(searchTextLower)) {
                    const tx = pdfjsLib.Util.transform(viewport.transform, item.transform);
                    const [x, y] = [tx[4], tx[5]];

                    const width = (item.width * viewport.scale) + padding * 2;
                    const height = (item.height * viewport.scale) + padding * 2;

                    const isActiveResult = service.searchResults[service.currentSearchIndex] &&
                                        service.searchResults[service.currentSearchIndex].page === page.pageNumber &&
                                        service.searchResults[service.currentSearchIndex].index === index;

                    context.fillStyle = isActiveResult ? 'rgba(255, 165, 0, 0.5)' : 'rgba(255, 255, 0, 0.2)';
                    context.fillRect(x - padding, y - item.height * viewport.scale - padding, width, height);
                    // Scroll to the active result
                    if (isActiveResult) {
                        const container = document.getElementById('pdfViewerContainer');
                        container.scrollTop = y - container.clientHeight / 2 + height / 2;
                    }
                }
            });

            context.restore();
        });
    };

    service.clearHighlights = function() {
        const canvas = document.querySelector('#pdfViewerContainer canvas');
        if (canvas) {
            const context = canvas.getContext('2d');
            context.clearRect(0, 0, canvas.width, canvas.height);
            service.renderPage(service.currentPage); 
        }
    };

    service.renderThumbnails = function(docs) {
        const promises = docs.map(function(doc, index) {
            if (!service.thumbnailsCache[doc.path]) {
                return service.renderThumbnail(doc, index);
            } else {
                service.displayCachedThumbnail(doc, index);
                return Promise.resolve();
            }
        });
        return Promise.all(promises);
    };

    service.displayCachedThumbnail = function(doc, index) {
        const cachedThumbnail = service.thumbnailsCache[doc.path];
        const img = document.getElementById('thumbnail' + index);
        if (cachedThumbnail && img) {
            img.src = cachedThumbnail;
        }
    };

    service.renderThumbnail = function(doc, index) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const url = window.location.origin + '/s3/' + window.__env.s3AssessmentPdf + '?filePath=' + doc.path;

        return pdfjsLib.getDocument(url).promise.then(function(pdf) {
            return pdf.getPage(1).then(function(page) {
                const scale = 0.2;
                const viewport = page.getViewport({ scale: scale });
    
                canvas.height = viewport.height;
                canvas.width = viewport.width;
    
                const renderContext = {
                    canvasContext: context,
                    viewport: viewport
                };
    
                return page.render(renderContext).promise.then(function() {
                    service.thumbnailsCache[doc.path] = canvas.toDataURL();
                    service.displayCachedThumbnail(doc, index);
                });
            });
        }).catch(function(reason) {
            console.error('Error loading PDF for thumbnail:', reason);
        });
    };

    service.previousPage = function() {
        if (service.currentPage <= 1) return;
        service.currentPage--;
        service.renderPage(service.currentPage);
    };

    service.nextPage = function() {
        if (service.currentPage >= service.totalPages) return;
        service.currentPage++;
        service.renderPage(service.currentPage);
    };

    service.firstPage = function() {
        service.currentPage = 1;
        service.renderPage(service.currentPage);
    }

    service.lastPage = function() {
        service.currentPage = service.totalPages;
        service.renderPage(service.currentPage);
    }

    service.goToPage = function(pageNumber) {
        if (!pageNumber || isNaN(pageNumber) || pageNumber < 1 || pageNumber > service.totalPages) {
            return;
        }
        service.currentPage = pageNumber;
        service.renderPage(service.currentPage);
    };

    service.searchTextInPdf = function(text) {
        if (!text || text.trim() === '') {
            service.clearSearch();
            return;
        }
        service.clearHighlights();

        service.searchResults = [];
        service.currentSearchIndex = 0;
        service.searchText = text.trim();
        service.noSearchResults = false;
        service.searchInitiated = true;

        const promises = [];
        for (let i = 1; i <= service.pdfDoc.numPages; i++) {
            promises.push(service.pdfDoc.getPage(i).then(function(page) {
                return page.getTextContent().then(function(textContent) {
                    const textItems = textContent.items;
                    textItems.forEach((item, itemIndex) => {
                        const text = item.str.toLowerCase();
                        const index = text.indexOf(service.searchText.toLowerCase());
                        if (index !== -1) {
                            service.searchResults.push({ page: i, index: itemIndex });
                        }
                    });
                });
            }));
        }

        Promise.all(promises).then(function() {
            $timeout(function() {
                if (service.searchResults.length > 0) {
                    service.goToSearchResult(0);
                } else {
                    service.searchCounter = '';
                    service.noSearchResults = true;
                }
                service.updateSearchCounter();
            });
        }).catch(function(error) {
            console.error("Error in promises:", error);
        });
    };

    service.pressEnterKey = function(event, param, pageNumber) {
        if (event.keyCode === 13) {
            if (param === 'searchText') {
                if (event.shiftKey) {
                    service.previousSearchResult();
                } else {
                    service.handleSearch();
                }
            } else if (param === 'goToPage') {
                service.goToPage(pageNumber);
            }
        }
    };
    

    service.previousSearchText = '';

    service.handleSearch = function() {
        if (service.searchText.trim() === '') {
            service.clearSearch();
            return;
        } else if (service.searchText !== service.previousSearchText) {
            service.previousSearchText = service.searchText;
            service.searchTextInPdf(service.searchText);
        } else if (service.searchResults.length === 0) {
            service.searchTextInPdf(service.searchText);
        } else {
            if (service.currentSearchIndex === service.searchResults.length - 1) {
                service.goToSearchResult(0);
            } else {
                service.nextSearchResult();
            }
        }
    };

    service.updateSearchCounter = function() {
        if (service.searchResults.length > 0) {
            service.searchCounter = `${service.currentSearchIndex + 1} / ${service.searchResults.length}`;
        } else {
            service.searchCounter = '';
        }
    };

    service.goToSearchResult = function(index) {
        if (index >= 0 && index < service.searchResults.length) {
            service.currentSearchIndex = index;
            const result = service.searchResults[index];
            service.currentPage = result.page;
            service.renderPage(service.currentPage).then(function() {
                return service.pdfDoc.getPage(service.currentPage);
            }).then(function(page) {
                return service.highlightText(page);
            }).then(function() {
                $timeout(function() {
                    service.updateSearchCounter();
                });
            }).catch(function(error) {
                console.error("Error ", error);
            });
        }
    };

    service.previousSearchResult = function() {
        if (service.currentSearchIndex > 0) {
            service.goToSearchResult(service.currentSearchIndex - 1);
        } else if (service.searchResults.length > 0) {
            service.goToSearchResult(service.searchResults.length - 1);
        }
    };

    service.nextSearchResult = function() {
        if (service.currentSearchIndex < service.searchResults.length - 1) {
            service.goToSearchResult(service.currentSearchIndex + 1);
        }
    };

    service.clearSearch = function() {
        service.searchText = '';
        service.searchResults = [];
        service.currentSearchIndex = 0;
        service.clearHighlights();
        service.searchCounter = '';
        service.noSearchResults = false;
        service.searchInitiated = false;
    };

    $rootScope.$watch(function() {
        return service.currentSearchIndex;
    }, function(newVal) {
        service.updateSearchCounter();
    });

    $rootScope.$watch(function() {
        return service.searchResults;
    }, function(newVal) {
        service.updateSearchCounter();
    });

    window.addEventListener('resize', function() {
        $rootScope.$apply(function() {
            if (service.currentDocument) {
                service.renderPage(service.currentPage);
            }
        });
    });

    service.formatPdfTitle = function(filename) {
        if (!filename) return '';
        const nameWithoutExtension = filename.replace(/\.pdf$/, "");
        return nameWithoutExtension.length > 55 ? nameWithoutExtension.substring(0, 55) + '...' : nameWithoutExtension;
    };

    service.openFirstDocument = function() {
        if ($rootScope.assessment.linkedAssessmentPdf && $rootScope.assessment.linkedAssessmentPdf.length > 0) {
            const firstDocument = $rootScope.assessment.linkedAssessmentPdf[0];
            service.showPdf(firstDocument);
        }
    };

    service.$onInit = async function() {
        try {
            if (service.isSidebarExpanded){
                overlaySpinner.show('loadingSidebarDocuments');
            }
            await service.initializeCache($rootScope.assessment.linkedAssessmentPdf);
            await service.renderThumbnails($rootScope.assessment.linkedAssessmentPdf);
            service.openFirstDocument();
            overlaySpinner.hide('loadingSidebarDocuments');
        } catch (error) {
            console.error("Error initializing cache", error);
            overlaySpinner.hide('loadingSidebarDocuments');
        }
    };

    function waitForPdfJsLib() {
        return new Promise((resolve, reject) => {
            const checkInterval = setInterval(() => {
                if (window.pdfjsLib) {
                    clearInterval(checkInterval);
                    resolve(window.pdfjsLib);
                }
            }, 100);
    
            setTimeout(() => {
                clearInterval(checkInterval);
                reject(new Error('pdfjsLib did not load'));
            }, 10000);
        });
    }

    return service;
}])

.factory('SocketService', ['$rootScope', function($rootScope) {
    const hostname = window.location.hostname === 'localhost' ? window.location.hostname + ':8000' : window.location.hostname;
    const host = window.location.protocol + '//' + hostname;
    const socket = io.connect(host, { secure: true, reconnect: true, rejectUnauthorized: false, transports: ["websocket"] });

    return {
        on: function(eventName, callback) {
            socket.on(eventName, function() {
                const args = arguments;
                $rootScope.$apply(function() {
                    callback.apply(socket, args);
                });
            });
        },
        emit: function(eventName, data, callback) {
            socket.emit(eventName, data, function() {
                const args = arguments;
                $rootScope.$apply(function() {
                    if (callback) {
                        callback.apply(socket, args);
                    }
                });
            });
        },
        id: function() {
            return socket.id;
        }
    };
}]);

function starsHtml(rating) {
    var html = '';
    for (var i = 1; i <= 5; ++i) {
        var w = 0;
        if (i <= rating) {
            w = '100%';
        }
        if (rating > i - 1 && rating < i) {
            w = Math.round((rating - (i - 1)) * 100) + '%';
        }

        html += '<div class="icon icon--star-popup icon--star--disabled">';
            html += '<div class="icon--star-popup--filled" style="width: ' + w + '"></div>';
        html += '</div>';
    }
    return html;
};



/**
 * @typedef ServerRequestOptions
 * @property { number } [timeout] Request timeout in milliseconds
 * @property { boolean } [preserveCache] Used to prevent clearing $http cache after a non GET request
 */
