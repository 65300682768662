angular.module('app').controller('CareerCtrl', ["$rootScope", "$scope", "Server", "$window", "Translate", "multiSelect", "googleMaps", "$timeout", "Util", "htmlTemplates", "Cookies", function ($rootScope, $scope, Server, $window, Translate, multiSelect, googleMaps, $timeout, Util, htmlTemplates,Cookies) {    
    //
    // URL Parameters
    //
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());


    //
    // Filters
    //
    $scope.sectionExistsInVisitorLanguage = section => {
        if (section.arrangement === 'imageBanner' || section.arrangement === 'videoBanner' || section.arrangement === "onlyMedia") {
            return section.images[0] || section.video[0];
        }
        if(section.arrangement === "onlyText") {
            return section.text[Translate.currentLanguage()];
        }
        if(section.arrangement === "textThenMedia" || section.arrangement === "mediaThenText") {
            return (section.images[0] || section.video[0])  && section.text[Translate.currentLanguage()];
        }
    };
    $scope.search = { main: '', city: [], countries: [], language: [], jobCategories: [], mapBounds: undefined, allowMapFilter: false };
    $scope.search.main = params.search;

    let multiSelectln = JSON.parse(JSON.stringify(multiSelect))
    // for countries
    $scope.multiselectSettings = multiSelect.arraySettings;
    $scope.multiselectSettings.smartButtonMaxItems = 3;
    $scope.multiselectSettings.dynamicTitle = true;
    $scope.multiselectTexts = multiSelect.texts;
    $scope.multiselectSettingsObj = { ...multiSelect.objectSettings, dynamicTitle: true, smartButtonMaxItems: 3 };
    $scope.multiselectTextsJobCategory = { ...multiSelect.texts, buttonDefaultText: Translate.getLangString('select_category') };
    $scope.multiselectTextsCountries = { ...multiSelect.texts, buttonDefaultText: Translate.getLangString('career_search_country') };
    $scope.multiselectTextsCities = { ...multiSelect.texts, buttonDefaultText: Translate.getLangString('career_search_city') };
    $scope.readyTobuildMultiSelect = false;

    // for campaigns
    $scope.allCities = [];
    $scope.allCountries = [];
    //  [ {id: 0, label: "Anglais"}, {id: 1, label: "Français"}, ...]
    $scope.allLanguages = Translate.getLangDropdownObject().map(l => ({ id: l.value, label: l.full }));
    $scope.jobCategoryOptions = [];
    $scope.showLessCampaigns = true;

    // functions
    $scope.cityFilter = cityFilter;
    $scope.mainFilter = mainFilter;
    $scope.jobCategoryFilter = jobCategoryFilter;
    $scope.countriesFilter = countriesFilter;

    $rootScope.$on('onVisitorLanguageChange', function() {
        updateMultiselectButtonText();
        updateMultiselectButtonTextln();
        updateMultiselectTextsJobCategory();
        updateMultiselectButtonTextCountries();
        setCareerNavbar();
    });
    $scope.ddLangs = Translate.getLangDropdownObject();

    function prefilterCategory() {
        if (location.search) {
            const queryString = Util.getQueryString(location);
            if (queryString.category) {
                const categoryList = queryString.category.split(',');
                $scope.search.jobCategories = $scope.jobCategoryOptions.filter(filter => categoryList.find(catLabel => catLabel === filter.label));
            }
        }
    }


    // fb tag test
    //setMetaTags();

    function setMetaTags() {
        $window.ogtitle.content = "Career Page on Beehire";
        $window.ogurl.content = "https://app.beehire.com/";
        $window.ogtype.content = "website";
        $window.ogdescription.content = "Discover career opportunities";
        //$window.ogimage.content = "/imgs/miniature_link_share_carreerpage.jpg";
    }

    const setFavicon = function () {
        // duplicated code (ref 2402)
        if ($rootScope.employerBranding.customization.favicon) {
            const favLinkTags = document.querySelectorAll('head [href*="icon"]')
            favLinkTags.forEach(favIconTag => {
                favIconTag.setAttribute('href', $rootScope.employerBranding.customization.favicon)
            })
        }
    }

    $scope.initGoogleMap = function () {
        const address = _.get($scope, 'employerBranding.companyInformation.address')
        if (address) {
            setTimeout(() => {
                $scope.goolgeStaticMap = googleMaps.embedUrl(address);
                document.getElementById('findus__map').src = $scope.goolgeStaticMap;
                $scope.$apply();
            }, 100)
        }
    }

    /// Navbar
    function setCareerNavbar() {
        let options = [];
        options.push({
            elementId: 'career__header',
            text: $rootScope.texts['career_nav_home'],
        });
        options.push({
            elementId: 'career__jobs',
            text: $rootScope.texts['career_nav_jobs'],
        });

        if (!$rootScope.hideNavAbout) {
            options.push({
                elementId: 'career__info',
                text: $rootScope.texts['career_nav_about'],
            });
        }
        $rootScope.setNavbarOptions(options);
    }

    //
    // go
    //

    loadCareerData()
        .then(setFavicon)
        .then($scope.initGoogleMap)
        .then(setMetaTags);

    $scope.sortByAlphabetic = function (cities) {
        cities.sort();
    }

    function loadCareerData() {
      var careerSlug = getCareerSlug();

      return Server.get('users/getPublicCampaigns/' + careerSlug)
        .then(function (data) {
            $scope.campaigns = data.campaigns;
            $scope.computeMaxHeight();
            $scope.user = data.user;
            $rootScope.employerBranding = data.employerBranding;
            if (!$rootScope.employerBranding.customization) {
                $rootScope.employerBranding.customization = {}
            }

            const availableLanguages = _.get($rootScope.employerBranding, 'careerPage.availableLanguages')||[];
            if (availableLanguages.length) {
                $scope.ddLangs = $scope.ddLangs.filter(lang => availableLanguages.includes(lang.value))
            }
            let languageSet = false;
            if(params.language) {
                const paramLang = Translate.getLangNumberFromShort(params.language);
                if (typeof paramLang === 'number' && (availableLanguages.length === 0 || availableLanguages.includes(paramLang))) {
                    Translate.changeVisitorLanguage(Translate.getLangNumberFromShort(params.language), availableLanguages);
                    languageSet = true;
                }
            }
            if (!languageSet) {
                Translate.initVisitorLanguageFromNavigator($scope.user.language, availableLanguages);
                languageSet = true;
            }

            if ($scope.user.suspended) {
                window.location = 'https://www.beehire.com/404';
                return;
            }

            // customization macros to make styling easier
            $rootScope.customization = {
                brandColor: { "color": $rootScope.employerBranding.customization.mainColor || false },
                brandBackColor: { "background-color": $rootScope.employerBranding.customization.mainColor || false },
                navbarFontColor: { "color": $rootScope.employerBranding.customization.navbarFontColor ? $rootScope.employerBranding.customization.navbarFontColor : undefined },
            }

            $scope.allCities = _.uniq(_.map($scope.campaigns, 'location.city'));
            $scope.filteredCities = $scope.allCities;
            
            // Get all countries from campaigns
            $scope.allCountries = $scope.campaigns.filter(campaign => campaign.location && campaign.location.country)
                                                  .map(campaign => campaign.location.country);
            
            // Check if there are worldwide campaigns or campaigns without a country
            const hasWorldwideCampaigns = $scope.campaigns.some(campaign => campaign.location.isWorldwide);
            const hasNoCountryCampaigns = $scope.campaigns.some(campaign => !campaign.location.country);
            
            if (hasWorldwideCampaigns) {
                $scope.allCountries.unshift(Translate.getLangString('worldwide_filter'));
            }

            if (hasNoCountryCampaigns) {
                $scope.allCountries.unshift(Translate.getLangString('no_country'));
            }

            $scope.allCountries = _.uniq($scope.allCountries);
            $scope.allCountries.sort();

            $scope.sortByAlphabetic($scope.allCities);
            $scope.sortByAlphabetic($scope.allCountries);
            updateMultiselectButtonText();
            updateMultiselectTextsJobCategory();

            let jobCategories = [];
            $scope.campaigns.forEach(campaign => {
                if (campaign.jobCategories) {
                    campaign.jobCategories.forEach(jobCategory => {
                        if (!jobCategories.find(filter => filter._id === jobCategory._id)) {
                            jobCategories.push(jobCategory)
                        }
                    });

                    let jobCategoryTooltip = ''
                    for (let jobCategory of campaign.jobCategories.slice(1)) {
                        jobCategoryTooltip += `<div>${jobCategory.label}</div>`;
                    }
                    campaign.jobCategoriesTooltip = jobCategoryTooltip;
                }
            })
            $scope.jobCategoryOptions = jobCategories.sort((a, b) => a.label > b.label ? 1 : a.label < b.label ? -1 : 0);
            prefilterCategory();

            // Map city to country
            $scope.cityCountryMap = {};
            $scope.campaigns.forEach(campaign => {
                const { city, country } = campaign.location;
                if (!$scope.cityCountryMap[city]) {
                    $scope.cityCountryMap[city] = country;
                }
            });

            // set page title
            $('title').text($rootScope.employerBranding.companyInformation.name + ' | ' + Translate.getLangString('career_page'));

            // set custom font, if any
            if($rootScope.employerBranding.careerPage.fontFamily) {
                let style = document.createElement('style');
                style.type = 'text/css';
                style.innerHTML = `.career-custom-font * {font-family: ${$rootScope.employerBranding.careerPage.fontFamily} !important;}`;
                document.getElementsByTagName('head')[0].appendChild(style);
                // <style>@import url('https://fonts.googleapis.com/css2?family=Fahkwang:wght@300&display=swap');</style>
                document.getElementById('js-add-career-font').classList.add('career-custom-font');
            }

            if ($rootScope.employerBranding.careerPage.filters.showMap) {
                $scope.initCampaignsGoogleMap();
            } else {
                $scope.search.allowMapFilter = false;
            }
            // Initialise cookies with gtag, if there is one
            Cookies.init($rootScope?.employerBranding?.legalInformation?.gtag, $rootScope?.employerBranding?.legalInformation?.ga);

            setCareerNavbar();
            setTimeout(hideLoader, 200);

        }, function () {
            // redirect to error landing page
            window.location = 'https://www.beehire.com/404';
        })

        function getCareerSlug() {
            // window.location.href ~ app.beehire/career/<COMPANY_SLUG>
            return window.location.href.split('/').slice(-1)[0]
        }
    }

    function setCareerNavbar() {
        let options = [{
            elementId: 'career__header',
            text: $rootScope.texts['career_nav_home'],
        }];
        if ($rootScope.employerBranding) {
            const sections = $rootScope.employerBranding.careerPage.sections || [];
            const addCareerSection = function (location) {
                options.push(...sections.filter(x => x.location === location && x.showInNavBar).map((section, index) => ({
                    elementId: `${location}${index}`,
                    text: section.title,
                })));
            }
            
            addCareerSection('beforeJobs');

            if ($scope.campaigns && $scope.campaigns.length) {
                options.push({
                    elementId: 'career__jobs',
                    text: $rootScope.texts['career_nav_jobs'],
                });
            }
            addCareerSection('beforeInfo');

            if (_.get($rootScope.employerBranding, 'companyInformation.description.'+$rootScope.visitorLang) || _.get($rootScope.employerBranding, 'companyInformation.presentationVideo')) {
                options.push({
                    elementId: 'career__info',
                    text: $rootScope.texts['career_nav_about'],
                });
            }
            addCareerSection('beforeFindUs');

            if (_.get($rootScope.employerBranding, 'companyInformation.address.name')) {
                options.push({
                    elementId: 'career__findus',
                    text: $rootScope.texts['career_nav_where'],
                });
            }
            addCareerSection('beforeFooter');
        }
        $rootScope.setNavbarOptions(options);
        $rootScope.setNavbarLanguages($scope.ddLangs);
    }

    $scope.initCampaignsGoogleMap = function() {
        const style = $rootScope.employerBranding.customization && $rootScope.employerBranding.customization.mainColor ?
            `background-color: ${$rootScope.employerBranding.customization.mainColor}` :
            "";
        htmlTemplates.getHtml('career-map-info.html')
            .then(infoContent => {
                return googleMaps.initMany(
                    'job-locations__map',
                    $scope.campaigns.filter(x => x.location).map(x => {
                        const variables = {
                            ["campaign.title"]: x.title[x.language],
                            ["campaign.description"]: x.description ? x.description[x.language] : 'n/a',
                            ["campaign.link"]: x.inviteLink,
                            ["location.name"]: x.location.name,
                            ["button.text"]: Translate.getLangString("career_learn_more"),
                            ["button.style"]: style,
                        };
                        return {
                            ...x.location,
                            infoContent: htmlTemplates.replaceVariables(infoContent, variables),
                        }
                    }),
                    (map) => {
                        const bounds = map.getBounds();
                        $scope.search.mapBounds = bounds;
                        $scope.$apply();
                    }
                )
            });
    }

    //
    // Watchers
    //
    $scope.$watch('search.countries', function(newVal, oldVal) {
        // No need to filter if no countries are selected
        if (!newVal || newVal.length === 0) {
            $scope.filteredCities = $scope.allCities.slice();
            // Reinitialize city selection
            $scope.search.city = [];
        } else {
            // Filter cities based on selected countries
            const newFilteredCities = $scope.allCities.filter(city => {
                let countryOfCity = $scope.cityCountryMap[city];
                return newVal.includes(countryOfCity);
            });
            // Update filtered cities
            $scope.filteredCities = newFilteredCities;
            // Reset city selection if selected cities are not in the new filtered list
            $scope.search.city = $scope.search.city.filter(city => newFilteredCities.includes(city));
        }
    }, true);
    


    //
    // Filters on campaigns
    //

    $scope.toggleCampaignsList = function() {
        $scope.showLessCampaigns = !$scope.showLessCampaigns
    }

    function cityFilter(campaign) {
        var checkedCities = $scope.search.city;
        if (!checkedCities || !checkedCities.length) {
            return true;
        }
        return _.includes(checkedCities, campaign.location.city);
    }

    function countriesFilter(campaign) {
        var checkedCountries = $scope.search.countries;

        // If no countries are selected in the filter, return true to show all campaigns.
        if (!checkedCountries || !checkedCountries.length) {
            return true;
        }

        // Check if the "Worldwide" filter is among the selected countries.
        const isWorldwideIncluded = checkedCountries.some(country => country === Translate.getLangString('worldwide_filter'));

        // Check if the campaign's country matches any of the selected countries.
        const isSpecificCountryMatch = checkedCountries.some(country => campaign.location && campaign.location.country === country);

        /* Return true if any of the following conditions are met:
            1. The campaign is marked as worldwide, and either "Worldwide" is selected or any country is selected.
            2. The campaign's country directly matches one of the selected countries.
            3. The campaign has no country defined and the "NoCountry" filter is selected. 
        */
        return (campaign.location && campaign.location.isWorldwide && 
               (isWorldwideIncluded || checkedCountries.length > 0)) ||
               isSpecificCountryMatch ||
               (checkedCountries.includes(Translate.getLangString('no_country')) && !campaign.location?.country && !campaign.location?.isWorldwide);
    }

    function jobCategoryFilter(campaign) {
        return $scope.search.jobCategories.every(filter => campaign.jobCategories.find(jobCategory => filter._id === jobCategory._id));
    }

    $scope.locationFilter = function(campaign) {
        if (!$scope.search.allowMapFilter) {
            return true;
        }
        return $scope.search.mapBounds && $scope.search.mapBounds.contains({ lat: campaign.location.latitude, lng: campaign.location.longitude });
    }

    function mainFilter(campaign) {
        if (!$scope.search.main) {
            return true;
        }
        const searchStrings = $scope.search.main.split(',');
        let isInCampaignCity;
        let isInCampaignTitle;
        _.forEach(searchStrings, function (searchString) {
            if (_.includes(campaign.location?.city?.toLowerCase(), searchString.trim().toLowerCase()))
                isInCampaignCity = true;
            for(let i = 0 ; i < $scope.allLanguages.length ; i++) {
                if (_.includes(campaign.title[i]?.toLowerCase(), searchString.trim().toLowerCase()))
                    isInCampaignTitle = true;
            }
        });
        return isInCampaignCity || isInCampaignTitle;
    }

    function updateMultiselectButtonText() {
        $scope.multiselectTextsCities = { ...multiSelect.texts, buttonDefaultText: Translate.getLangString('career_search_city') };
        $scope.multiselectTextsCountries = { ...multiSelect.texts, buttonDefaultText: Translate.getLangString('career_search_country') };
        $scope.multiselectTextsln = { ...multiSelect.texts, buttonDefaultText: Translate.getLangString('career_search_language') };
        $scope.multiselectTextsJobCategory = { ...multiSelect.texts, buttonDefaultText: Translate.getLangString('select_category') };
        $scope.readyTobuildMultiSelect = false;
        $timeout(function () {
            $scope.readyTobuildMultiSelect = true;
        }, 10);
    }

    function updateMultiselectButtonTextCountries() {
        $scope.multiselectTexts.buttonDefaultText = Translate.getLangString('career_search_country');
        $scope.readyToBuildMultiSelect = false;
        $timeout(function () {
            $scope.readyToBuildMultiSelect = true;
        }, 10);
    }

    function updateMultiselectButtonTextln() {
        $scope.multiselectTextsln.buttonDefaultText = Translate.getLangString('career_search_language');
        $scope.readyTobuildMultiSelectln = false;
        $timeout(function () {
            $scope.readyTobuildMultiSelectln = true;
        }, 10);
    }

    function updateMultiselectTextsJobCategory() {
        $scope.multiselectTextsJobCategory.buttonDefaultText = Translate.getLangString('select_category');
        $scope.readyTobuildMultiSelectJobCategory = false;
        $timeout(function () {
            $scope.readyTobuildMultiSelectJobCategory = true;
        }, 10);
    }

    $scope.computeMaxHeight = function () {
        const colsLength = window.innerWidth > 1050 ? 3 : window.innerWidth > 735 ? 2 : 1;
        $scope.campaignsMaxHeight = (Math.ceil($scope.campaigns.length/colsLength)*170) + "px";
    }

    window.onresize = _.debounce(function() {
        $scope.computeMaxHeight();
        $scope.$apply();
    }, 200)
}]);
