//@ts-check
/** {@link import('./components/list-filter.component.js) link} */
/** {@link import('./components/crud-list.component.js) link} */

//@ts-ignore
DashboardAssessmentCandidatesCtrl.$inject = ["$scope", "$rootScope", "$state", "$stateParams", "Server", "Translate", "Util", "ToasterService", "overlaySpinner", "PopupService", "multiSelect"];
angular.module('app').controller('DashboardAssessmentCandidatesCtrl', DashboardAssessmentCandidatesCtrl);

/**
 * @param {DashboardAssessmentCandidateScope} $scope 
 */
function DashboardAssessmentCandidatesCtrl($scope, $rootScope, $state, $stateParams, Server, Translate, Util, ToasterService, overlaySpinner, PopupService, multiSelect) {
  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.hasPrivileges(['hasAssessments'], user)) {
      $state.go('campaigns');
    }
    if (!$rootScope.fns.userHasRights('assessments.list', 'view', user)) {
      $state.go('campaigns');
    }
  });

  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = true;

  let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
  $scope.multiselectSettings = {
      ...multiSelectln.objectSettings,
      idProperty: "id",
      dynamicTitle: true,
      smartButtonMaxItems: 5,
  };
  $scope.multiselectTexts = multiSelectln.texts;
  $scope.singleselectSettings = {
      ...$scope.multiselectSettings,
      selectionLimit: 1,
      showCheckAll: false,
      showUncheckAll: false,
      checkBoxes: false,
  }
  
  $scope.setPageTitle = function() {
    const pageName = `${Translate.getLangString('assessment_candidates')}`;
    let identifier = undefined;
    if ($scope.assessment && $scope.assessment.title) {
      identifier = $scope.assessment.title[$scope.assessment.language || 0];
    }
    Util.setPageTitle(pageName, identifier);
  }

  $scope.getAssessmentCandidates = function() {
    overlaySpinner.show('assessment-candidates');
    return Server.get(`assessments/${$stateParams.assessmentId}?fetchCandidates=true`)
        .then(res => {
          overlaySpinner.hide('assessment-candidates');
          for (const candidate of res.candidates) {
            const notStartedText = Translate.getLangString('unstarted');
            candidate.campaignName = candidate.campaign.title[candidate.campaign.language];
            candidate.name = Util.userFullName(candidate);
            if (candidate.startedAt) {
              const date = new Date(candidate.finishedAt || candidate.startedAt);
              const formattedDate = date.toLocaleDateString('fr-FR', { year: 'numeric', month: '2-digit', day: '2-digit' });
              const formattedTime = date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
              candidate.statusDate = `${formattedDate} - ${formattedTime}`;
            } else {
              candidate.statusDate = notStartedText;
            }
            candidate.displayStatusDate = candidate.statusDate;
          }
          $scope.assessment = res;
          $scope.setPageTitle();
          return $scope.assessment;
        })
        .catch(err => {
          overlaySpinner.hide('assessment-candidates');
        });
  }

  $scope.inviteCandidate = function() {
    PopupService.openAssessmentInvite($scope, null, $scope.assessment);
  }

  $scope.ddSettingsOptions = [
    { text: Translate.getLangString('edit_assessment'), value: 'edit' },
    $rootScope.fns.userHasRights("assessments.list", "edit") ? { text: Translate.getLangString('duplicate_assessment'), value: 'duplicate' } : null,
    $rootScope.fns.userHasRights("assessments.list", "delete") ? { text: Translate.getLangString('delete'), value: 'delete' } : null,
  ].filter(m => m)
  $scope.ddSettingsClick = function(selected) {
    switch (selected.value) {

      case 'edit':
        // almost duplicate code (ref 2542)
        $state.go('assessment-edit', { assessmentId: $stateParams.assessmentId });
        break;

      case 'delete':
        // almost duplicate code (ref 2542)
        PopupService.openGenericPopup($scope, {
          submit: function () {
            $scope.modalHandle.close();
            Server.deleteObject('assessments', $stateParams.assessmentId)
              .then(function () {
                if ($state.is('assessments') && $rootScope.assessments) {
                    // the view is the list of assessments
                    _.remove($rootScope.assessments, c => c._id === $stateParams.assessmentId);
                } else {
                    // the view is the list of candidates
                    $state.go('assessments');
                }
              });
          },
          title: Translate.getLangString('delete_confirmation_title'),
          warningText: Translate.getLangString('delete_confirmation_warning'),
          messageText: Translate.getLangString('delete_confirmation_message'),
          yesText: Translate.getLangString('delete_confirmation_yes'),
          noText: Translate.getLangString('cancel')
        }, 'templates/modal-confirm-warning.html', {});
        break;
      case 'duplicate':
        // almost duplicate code (ref 2542)
        Server.post('assessments/' + $stateParams.assessmentId + '/duplicate')
          .then(function (newAssessment) {
            if ($state.is('assessments') && $rootScope.assessments) {
                $rootScope.assessments.push(newAssessment);
                // $rootScope.$emit('campaignsList.onCampaignsData');
            } else {
                // the view is the list of candidates
                $state.go('assessment-candidates', { assessmentId: newAssessment._id });
            }
          })
          .catch(function (err) {
              ToasterService.failure(err, 'err_35b_duplicating_assessment');
          });
        break;
    }
  }
  
  $scope.crud_translation = {
    title: 'assessment_candidates_beta',
    items: 'assessment_candidates',
    no_items: 'no_candidate_assessment_exists',
  }
  $scope.assessmentCandidatesProperties = [
    {
      type: 'image',
      location: 'header',
      key: 'photoSrc',
      text: '',
      noSort: true,
      noFilter: true,
    },
    {
      type: 'text',
      location: 'header',
      key: 'name',
      text: Translate.getLangString('name'),
      dataCssClass: 'assessment-card__header__title crud-list__title__col',
      filterCssClass: 'assessment-card__header__title crud-list__title__col',
      noSort: true,
      noFilter: true,
      ref: {
          state: 'submission-assessment-summary',
          stateParams: '{ candidateId: item.data._id, assessmentId: "'+$stateParams.assessmentId+'" }'
      }
    },
    {
      type: 'text',
      location: 'header',
      key: 'campaignName',
      text: Translate.getLangString('campaign'),
      dataCssClass: 'assessment-card__header__campaign',
      filterCssClass: 'assessment-card__header__campaign',
      noFilter: true,
      ref: {
          state: 'candidates',
          stateParams: '{ campaignId: item.data.campaign.id }'
      }
    },
    {
        type: 'matching',
        location: 'header',
        key: 'matchingScore',
        text: Translate.getLangString('candidates_filter_by_matching'),
        noFilter: true,
    },
    {
        type: 'stars',
        location: 'header',
        key: 'rating',
        text: Translate.getLangString('score'),
        noFilter: true
    },
    {
        type: 'text',
        location: 'header',
        key: 'displayStatusDate',
        text: Translate.getLangString('date'),
        dataCssClass: 'crud-list__datetime__col',
        filterCssClass: 'crud-list__datetime__col',
        noFilter: true,
    },
  ]

  $scope.getAssessmentCandidates();
  $scope.setPageTitle();
}

/**
 * @typedef DashboardAssessmentCandidateScope
 * @property { Assessment } [assessment]
 * @property { () => Promise<void> } getAssessmentCandidates
 * @property { () => Promise<void> } loadCampaigns
 * @property { () => void } inviteCandidate
 * @property { CrudListProperty[] } assessmentCandidatesProperties
 * @property { any[] } campaignOptions
 * @property { any } multiselectSettings
 * @property { any } multiselectTexts
 * @property { any } singleselectSettings
 * @property { any } crud_translation
 * @property { ({text: string, value: any}|null)[] } ddSettingsOptions
 * @property { (selected: any) => void } ddSettingsClick
 * @property { any } modalHandle
 * @property { () => void } setPageTitle
 * @property { () => void } $evalAsync
 */
