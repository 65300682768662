//@ts-check
//@ts-ignore
angular.module('app').controller('DashboardUserProfileTagsCtrl', ["$scope", "$rootScope", "$state", "$stateParams", "Server", "clipboard", "Translate", "Upload", "Util", "PopupService", "ToasterService", "overlaySpinner", "EventTracker", "multiSelect", function ($scope, $rootScope, $state, $stateParams, Server, clipboard, Translate, Upload, Util, PopupService, ToasterService, overlaySpinner, EventTracker, multiSelect) {
  $scope.userTags = [];
  $scope.userTagCategories = [];

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.hasPrivileges(["canUseCandidateTags"], user) ||
        !$rootScope.fns.userHasRights("candidates.tags", "view", user)
    ) {
      $state.go('user-profile');
    }
  });

  let multiSelectln = JSON.parse(JSON.stringify(multiSelect));
  $scope.multiselectSettings = {
    ...multiSelectln.objectSettings,
    idProperty: "id",
    dynamicTitle: true,
    smartButtonMaxItems: 5,
  };
  $scope.singleSelectSettings = {
    ...$scope.multiselectSettings,
    selectionLimit: 1,
    showCheckAll: false,
    showUncheckAll: false,
    checkBoxes: false,
  };

  $scope.tagsTexts = {
    title: '_VOID',
    add_item: 'add_tags',
    items: 'tags',
  }
  /** @type { CrudListProperty[] } */
  const tagsProperties = [
    {
      type: 'text',
      location: 'header',
      text: Translate.getLangString('tags_label'),
      key: 'label',
      noFilter: true,
      isGlobalFilter: true,
      dataCssClass: 'tag__label__col crud-list__title__col',
      filterCssClass: 'tag__label__col crud-list__title__col',
    },
    {
      type: 'dropdown',
      location: 'header',
      text: Translate.getLangString('category'),
      emptyText: Translate.getLangString('no_category'),
      key: 'tagCategory',
      noFilter: false,
      noSort: true,
      dataCssClass: 'tag__category__col',
      filterCssClass: 'tag__category__col',
      optionsFactory: function() {
        return $scope.userTagCategories
      },
      extraSettings: $scope.singleSelectSettings,
    }
  ]
  $scope.tagsProperties = tagsProperties;

  $scope.tagCategoriesTexts = {
    title: '_VOID',
    add_item: 'add_categories',
    items: 'categories',
    delete_warning: 'tag_category_delete_confirmation_warning',
  }
  /** @type {CrudListProperty[]} */
  const tagCategoriesProperties = [
    {
      type: 'text',
      location: 'header',
      text: Translate.getLangString('tags_label'),
      key: 'label',
      noFilter: true,
      isGlobalFilter: true,
      dataCssClass: 'tag__label__col crud-list__title__col',
      filterCssClass: 'tag__label__col crud-list__title__cols',
    }
  ];
  $scope.tagCategoriesProperties = tagCategoriesProperties

  $scope.saveTag = function(item) {
    const tagCategoryId = item.tagCategory[0] ? item.tagCategory[0].id : null;
    if (item._id) {
      return Server.patch('users/' + $rootScope.user.id + '/tags/' + item._id, {
        label: item.label,
        tagCategoryId,
      }).then(async () => {
        ToasterService.success('tag_updated');
        await $scope.loadUserTagCategories();
        await $scope.loadUserTags();
      }).catch((err) => {
        ToasterService.failure(err, 'tag_not_updated');
        return false;
      })
    } else {
      return Server.post('users/' + $rootScope.user.id + '/tags', {
          label: item.label,
          tagCategoryId,
      }).then(async () => {
        ToasterService.success('tag_created');
        await $scope.loadUserTagCategories();
        await $scope.loadUserTags();
      }).catch((err) => {
        ToasterService.failure(err, 'tag_not_created');
        return false;
      });
    }
  }

  $scope.deleteTag = function(item) {
      const oldUserTags = [...$scope.userTags];
      return Server.delete('users/' + $rootScope.user.id + '/tags/' + item._id)
        .then(() => {
          $scope.userTags = $scope.userTags.filter(t => t._id !== item._id);
          $scope.tagsCrudList.setPropOptions('tagCategory', $scope.userTagCategories);
          ToasterService.success('tag_deleted');
        }).catch((err) => {
          $scope.userTags = oldUserTags;
          ToasterService.failure(err, 'tag_not_deleted');
        })
  }

  $scope.saveTagCategory = function(item) {
    if (item._id) {
      return Server.patch('users/' + $rootScope.user.id + '/tag-categories/' + item._id, {
        label: item.label,
      }).then(async () => {
        ToasterService.success('tag_category_updated');
        await $scope.loadUserTagCategories();
      }).catch((err) => {
        ToasterService.failure(err, 'tag_category_not_updated');
        return false;
      })
    } else {
      return Server.post('users/' + $rootScope.user.id + '/tag-categories/', {
        label: item.label,
      }).then(async () => {
        ToasterService.success('tag_category_created');
        await $scope.loadUserTagCategories();
      }).catch((err) => {
        ToasterService.failure(err, 'tag_category_not_created');
        return false;
      })
    }
  }

  $scope.deleteTagCategory = function(item) {
    return Server.delete('users/' + $rootScope.user.id + '/tag-categories/' + item._id)
      .then(async () => {
        ToasterService.success('tag_category_deleted');
        await $scope.loadUserTags()
          .then(() => $scope.loadUserTagCategories());
        $scope.tagsCrudList.setPropOptions('tagCategory', $scope.userTagCategories);
      }).catch((err) => {
        ToasterService.failure(err, 'tag_category_not_deleted')
      })
  }

  $scope.loadUserTags = function() {
    return Server.get('users/' + $rootScope.user.id + '/tags')
      .then((tags) => {
        if (tags.length) {
          tags.forEach(tag => {
            if (tag.tagCategory) {
              tag.tagCategory = [{ ...tag.tagCategory, id: tag.tagCategory._id}]
            } else {
              tag.tagCategory = []
            }
          });
          $scope.userTags = tags;
        } else {
          $scope.userTags = [];
        }
      });
  }

  $scope.loadUserTagCategories = function() {
    return Server.get('users/' + $rootScope.user.id + '/tag-categories')
      .then((tagCategories) => {
        if (tagCategories.length) {
          $scope.userTagCategories = tagCategories.map(cat => {
            cat.id = cat._id;
            return cat;
          });
        } else {
          $scope.userTagCategories = [];
        }
        setTimeout(() => {
          if ($scope.tagsCrudList) {
            $scope.tagsCrudList.setPropOptions('tagCategory', $scope.userTagCategories);
            $scope.$apply();
          }
        }, 100);
      })
  }

  $scope.loadUserTags()
  .then(() => $scope.loadUserTagCategories())
  .then(() => {
    console.log("then loadUserTags = ", $scope.userTags);
    console.log("then loadUserTagCategories = ", $scope.userTagCategories);
  })
}])