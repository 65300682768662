angular.module('app').controller('CollaboratorsCtrl', ["$scope", "$rootScope", "Server", "$state", "PopupService", "ToasterService", "Util", "overlaySpinner", "Translate", "Onboarding", "$timeout", "$q", "EventTracker", function ($scope, $rootScope, Server, $state, PopupService, ToasterService, Util, overlaySpinner, Translate, Onboarding, $timeout, $q, EventTracker) {
  Util.setPageTitle(Translate.getLangString('collaborators'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = false;

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.hasPrivileges(['canCollaborate'], user)) {
      $rootScope.upgradePlan('upgrd_collaboration', { backOnClose: true })
    }
  });
  Onboarding.initWidget(null);

  $rootScope.loadUserRightsMapping();

  $scope.count = 0;
  $scope.searchField = '';
  $scope.inviteEmail = '';

  $scope.invite = function () {
      PopupService.openCollaboratorInvite($scope, {
          contact: $scope.inviteEmail
      }).then(function () {
          $state.go('collaborators', {}, { reload: true });
          $rootScope.reloadUser();
      });
      $scope.inviteEmail = '';
  };

    var checkCollaboratorOptions = function () {
        const newActions = []
        newActions.push({
            text: Translate.getLangString('collaborator_send_message_option'),
            action: (collaborator) => {
                PopupService.openCollaboratorMessage($scope, {
                    contact: $rootScope.userFullName(collaborator)
                }, collaborator._id);
            },
            isVisible: (collaborator) => {
                return collaborator._id !== $rootScope.user._id;
            }
        })

        if ($rootScope.fns.userHasRights("hasAdminRights", true)) {
            newActions.push({
                text: Translate.getLangString('manage_user_rights'),
                action: async (collaborator) => {
                    PopupService.openUserRights($scope, collaborator)
                        .then(rights => {
                            collaborator.rights = rights;
                            collaborator.validRights = Util.getUserRightsText(collaborator, 'view');
                            $scope.$apply();
                        });
                },
                isVisible: (collaborator) => {
                    return collaborator._id !== $rootScope.user._id && (!collaborator.userType || $rootScope.user.userType);
                }
            })
            

            if ($rootScope.fns.hasCollaboratorsSettings(["useDepartments"])) {
                newActions.push({
                    text: Translate.getLangString('collaborator_manage_departments'),
                    action: async (collaborator) => {
                        const hasFullAccess = $rootScope.fns.userHasRights("accessAllCampaigns", true, collaborator);
                        const fullAccessMessage = Translate.getLangString("access_all_departments_message");

                        const departments = await $scope.loadDepartments();
                        const checkedDepartments = await PopupService.openAssignableData(
                            $scope,
                            {
                                modalTitle: Translate.getLangString('departments'),
                                sourceItem: collaborator,
                                targets: departments,
                                hasFullAccess,
                                fullAccessMessage,
                                showListWithFullAccess: true,
                                getLabel: (target) => {
                                    return target.name;
                                },
                                isAssigned: (source, target) => {
                                    return source.departmentIds ? source.departmentIds.some(m => m === target._id) : false;
                                },
                                onAssign: (targetId) => {
                                    EventTracker.trackCollaboratorAddUserGroup();
                                    return Server.post('collaborators/' + collaborator._id + '/assign-department/' + targetId);
                                },
                                onUnassign: (targetId) => {
                                    return Server.post('collaborators/' + collaborator._id + '/unassign-department/' + targetId);
                                }
                            }
                        )
                        $scope.setCollaboratorDepartments(collaborator, checkedDepartments);
                        $scope.$apply();
                    },
                });
            }

            newActions.push({
                text: Translate.getLangString('collaborator_manage_assessments'),
                action: async (collaborator) => {
                    const hasFullAccess = $rootScope.fns.userHasRights("accessAllAssessments", true, collaborator);
                    const fullAccessMessage = Translate.getLangString("access_all_assessments_message");

                    const assessments = await $scope.loadAssessments()
                    const checkedAssessments = await PopupService.openAssignableData(
                        $scope,
                        {
                            modalTitle: Translate.getLangString('assessments'),
                            sourceItem: collaborator,
                            targets: assessments,
                            hasFullAccess,
                            fullAccessMessage,
                            getLabel: (target) => {
                                return target.title[target.language];
                            },
                            isAssigned: (source, target) => {
                                return source.allowedAssessmentIds ? source.allowedAssessmentIds.some(m => m === target._id) : false;
                            },
                            onAssign: (targetId) => {
                                return Server.post('collaborators/' + collaborator._id + '/assign-assessment/' + targetId);
                            },
                            onUnassign: (targetId) => {
                                return Server.post('collaborators/' + collaborator._id + '/unassign-assessment/' + targetId);
                            }
                        }
                    );
                    $scope.$apply();
                },
                isVisible: (collaborator) => {
                    return collaborator._id !== $rootScope.user._id && (!collaborator.userType || $rootScope.user.userType);
                }
            })

            if ($rootScope.fns.userHasRights("employerBranding.list", "view")) {
                newActions.push({
                    text: Translate.getLangString('collaborator_manage_branding'),
                    action: async (collaborator) => {
                        const hasFullAccess = $rootScope.fns.userHasRights("accessAllEmployerBrandings", true, collaborator);
                        const fullAccessMessage = Translate.getLangString("access_all_brandings_message");

                        const brandings = await $scope.loadEmployerBrandings();
                        const checkedBrandings = await PopupService.openAssignableData(
                            $scope,
                            {
                                modalTitle: Translate.getLangString('employer_branding'),
                                sourceItem: collaborator,
                                targets: brandings,
                                hasFullAccess,
                                fullAccessMessage,
                                getLabel: (target) => {
                                    return target.name;
                                },
                                isAssigned: (source, target) => {
                                    return source.allowedEmployerBrandingIds ? source.allowedEmployerBrandingIds.some(m => m === target._id) : false;
                                },
                                onAssign: (targetId) => {
                                    return Server.post('collaborators/' + collaborator._id + '/assign-branding/' + targetId);
                                },
                                onUnassign: (targetId) => {
                                    return Server.post('collaborators/' + collaborator._id + '/unassign-branding/' + targetId);
                                }
                            }
                        )
                        $scope.setCollaboratorAllowedEmployerBrandings(collaborator, checkedBrandings);
                    },
                    isVisible: (collaborator) => {
                        return collaborator._id !== $rootScope.user._id
                    }
                });
            }
            
            newActions.push({
                text: Translate.getLangString('collaborator_suspend_option'),
                action: (collaborator) => {
                    if (confirm(Translate.getLangString('collaborator_confirm_suspend'))) {
                        Server.post('collaborators/'+ collaborator._id + '/suspend').then(async function (data) {
                            ToasterService.success('collaborator_suspend_success');
                            await $scope.onCollaboratorsData(data);
                            $rootScope.reloadUser();
                        }).catch(err => {
                            ToasterService.failure(err, 'err_0_error_occurred');
                        });
                    }
                },
                isVisible: (collaborator) => {
                    return collaborator._id !== $rootScope.user._id && !collaborator.suspended && (!collaborator.userType || $rootScope.user.userType);
                }
            })
            newActions.push({
                text: Translate.getLangString('collaborator_unsuspend_option'),
                action: (collaborator) => {
                    if (confirm(Translate.getLangString('collaborator_confirm_unsuspend'))) {
                        Server.post('collaborators/'+ collaborator._id + '/unsuspend').then(async function (data) {
                            ToasterService.success('collaborator_unsuspend_success');
                            await $scope.onCollaboratorsData(data);
                            $rootScope.reloadUser();
                        }).catch(err => {
                            ToasterService.failure(err, 'err_0_error_occurred');
                        });
                    }
                },
                isVisible: (collaborator) => {
                    return collaborator._id !== $rootScope.user._id && collaborator.suspended && (!collaborator.userType || $rootScope.user.userType);
                }
            })
        }

        newActions.push({
            text: Translate.getLangString('collaborator_manage_campaigns'),
            action: async (collaborator) => {
                const campaigns = await $scope.loadCampaigns()
                const checkedCampaigns = await PopupService.openCollaboratorCampaignSelection($scope, collaborator, campaigns);
                $scope.setCollaboratorCampaigns(collaborator, checkedCampaigns);
                $scope.$apply();
            },
            isVisible: (collaborator) => {
                const collabIsNotSelf = collaborator._id !== $rootScope.user._id;
                const collabIsNotAdmin = collaborator.userType === 0;
                const hasRight = $rootScope.fns.userHasRights("collaborators.assignToCampaign",  "edit");
                return collabIsNotSelf && collabIsNotAdmin && hasRight;
            }
        });

        $scope.itemActions = newActions;
    };

    $scope.itemActions = [];
    $scope.collaboratorsProperties = [];
    $scope.setCrudListProperties = function() {
        /** @type { CrudListProperty[] } */
        const collaboratorsProperties = [
            {
                type: 'image',
                location: 'header',
                text: '',
                key: 'photoSrc',
                noFilter: true,
                noSort: true,
            },
            {
                type: 'text',
                location: 'header',
                text: Translate.getLangString("name"),
                key: 'name',
                isGlobalFilter: true,
                dataCssClass: 'collaborator__header__name crud-list__title__col',
                filterCssClass: 'collaborator__header__name crud-list__title__col',
                noFilter: true,
            },
            {
                type: 'text',
                location: 'header',
                text: Translate.getLangString("email"),
                key: 'email',
                isGlobalFilter: true,
                dataCssClass: 'collaborator__header__email',
                filterCssClass: 'collaborator__header__email',
                noFilter: true,
            },
            {
                type: 'text',
                location: 'header',
                text: Translate.getLangString("position"),
                key: 'position',
                isGlobalFilter: true,
                dataCssClass: 'collaborator__header__position',
                filterCssClass: 'collaborator__header__position',
                noFilter: true,
            },
            {
                type: 'badge',
                location: 'header',
                text: Translate.getLangString("user_rights"),
                key: 'validRights',
                dataCssClass: 'collaborator__header__user-rights',
                filterCssClass: 'collaborator__header__user-rights',
                noFilter: true,
            },
            (!$rootScope.fns.hasCollaboratorsSettings(["useDepartments"]) ? {
                type: 'badge',
                location: 'header',
                text: Translate.getLangString("campaigns"),
                key: 'campaigns',
                dataCssClass: 'collaborator__header__campaigns',
                filterCssClass: 'collaborator__header__campaigns',
                noFilter: true,
            } : {
                type: 'badge',
                location: 'header',
                text: Translate.getLangString("departments"),
                key: 'departmentNames',
                dataCssClass: 'collaborator__header__campaigns',
                filterCssClass: 'collaborator__header__campaigns',
                noFilter: true,
            }),
        ];
        $scope.collaboratorsProperties = collaboratorsProperties
    }
    $scope.crud_translation = {
        title: 'collaborators',
        items: 'collaborators',
    };

    $scope.loadCollaborators = function () {
        overlaySpinner.show('collaborators');
        return Server.get('collaborators?includesSelf=true').then(async function (collaborators) {
            overlaySpinner.hide('collaborators');
            await $scope.onCollaboratorsData(collaborators);
        });
    }

    $scope.onCollaboratorsData = async function(collaborators) {
        $rootScope.collaborators = collaborators;
        collaborators.filter(collab => collab.isPending).forEach(collab => {
            collab.name = Translate.getLangString("collaborator_pending_name");
            collab.position = '';
        })
        collaborators.filter(collab => collab.suspended).forEach(collab => {
            collab.name += ` (${Translate.getLangString("suspended")})`;
        })
        const self = collaborators.find(collab => collab._id === $rootScope.user._id)
        if (self) {
            self.name += " " + Translate.getLangString("collaborator_is_you")
        }
        
        if ($rootScope.collaborators.length > 0) {
            const campaigns = await $scope.loadCampaigns();
            $rootScope.collaborators.forEach(collab => {
                $scope.setCollaboratorCampaigns(collab, campaigns.filter(camp => camp.collaborators.find(m => m === collab._id)));
                $scope.setCollaboratorDepartments(collab, collab.departments);
                $scope.setCollaboratorAllowedEmployerBrandings(collab, collab.allowedEmployerBrandings);
                collab.validRights = Util.getUserRightsText(collab, 'view');
            });
        }

        if (!$rootScope.user) {
            const user = await $rootScope.reloadUser();
            checkCollaboratorOptions(user);
            $scope.setCrudListProperties();
        } else {
            checkCollaboratorOptions();
            $scope.setCrudListProperties();
        }
    }

    $scope.setCollaboratorCampaigns = function(collaborator, campaigns) {
        collaborator.campaigns = campaigns.map(camp => camp.title[camp.language]);
    }

    $scope.setCollaboratorDepartments = function(collaborator, departments) {
        collaborator.departmentIds = departments.map(department => department._id);
        collaborator.departments = departments;
        collaborator.departmentNames = departments.map(department => department.name);
    }

    $scope.setCollaboratorAllowedEmployerBrandings = function(collaborator, employerBrandings) {
        collaborator.allowedEmployerBrandingIds = employerBrandings.map(employerBranding => employerBranding._id);
        collaborator.allowedEmployerBrandings = employerBrandings;
    }

    $scope.loadCampaigns = async function() {
        try {
            return Server.get('campaigns?skipCollaboratorLoad=true&skipCandidatesLoad=true');
        } catch (err) {
            ToasterService.failure(err, 'campaign_load_error');
            return [];
        }
    };

    $scope.loadDepartments = async function() {
        if ($rootScope.departments && $rootScope.departments.length > 0) {
            const deferred = $q.defer();
            deferred.resolve($rootScope.departments);
            return deferred.promise;
        } else {
            try {
                return Server.get('departments').then(departments => {
                    $rootScope.departments = departments;
                    return departments;
                });
            } catch (err) {
                ToasterService.failure(err, 'departments_load_error');
                const deferred = $q.defer();
                deferred.resolve([]);
                return deferred.promise;
            }
        }
    };

    $scope.loadAssessments = function() {
        if ($rootScope.assessments && $rootScope.assessments.length > 0) {
            const deferred = $q.defer();
            deferred.resolve($rootScope.assessments);
            return deferred.promise;
        } else {
            return Server.get('assessments').then(assessments => {
                $rootScope.assessments = assessments;
                return assessments;
            }).catch(err => {
                ToasterService.failure(err, 'load_assessments_error');
                return [];
            });
        }
    }

    $scope.loadEmployerBrandings = function () {
        if ($rootScope.employerBrandings && $rootScope.employerBrandings.length > 0) {
            const deferred = $q.defer();
            deferred.resolve($rootScope.employerBrandings);
            return deferred.promise;
        } else {
            return Server.get('employer-brandings').then(employerBrandings => {
                $rootScope.employerBrandings = employerBrandings;
                return employerBrandings;
            }).catch(err => {
                ToasterService.failure(err, 'load_brandings_error');
                return [];
            });
        }
    }

    $scope.isItemEditable = function() {
        return false;
    }

    $q(async function(resolve, reject) {
        await $scope.loadCollaborators().catch(reject)
        resolve()
    });
    Onboarding.initWidget('dashboard-collaborators');
}]);
