
CollaboratorsDropdownController.$inject = ["$scope", "$element", "$attrs", "$rootScope", "$state", "Server", "PopupService", "Translate", "Util", "$window", "multiSelect", "$q"];angular.module('app')
  .component('collaboratorsDropdown', {
    templateUrl: '../templates/components/collaborators-dropdown.component.html',
    controller: CollaboratorsDropdownController,
    bindings: {
      options: '<',
      assignedCollaborators: '<',
      readonly: '<',
      direction: '@',
      onAssigned: '&',
      onRemoved: '&',
      context: '@',
    },
  });

function CollaboratorsDropdownController($scope, $element, $attrs, $rootScope, $state, Server, PopupService, Translate, Util, $window, multiSelect, $q) {
  $ctrl = this;
  $scope.element = $element;
  $scope.attrs = $attrs;

  $scope.texts = $rootScope.texts;
  $scope.profilePhoto = Util.profilePhoto;

  $scope.addCollaboratorSelected = {};
  $scope.collaboratorSelected = {};

  $scope.optionsDefined = false;

  $scope.extraCollaborators = [];
  $scope.multiSelectModelCollaboratorsAssigned = [];
  
  $scope.buildCollaboratorsMenu = function () {
    if (this.internalOptions) {
      this.availableCollaborators = [{
        text: Translate.getLangString('collaborator_add_option'),
        value: 'invitecollaborator',
      }];
      var inList = [];

      if (this.$ctrl.assignedCollaborators) {
        this.assignedCollaborators = this.$ctrl.assignedCollaborators.map((obj) => {
          if (typeof obj === "string") {
            inList.push(obj);
            if ($rootScope.user._id == obj) {
              return { _id: obj, name: $rootScope.user.name, photoSrc: $rootScope.user.photoSrc }
            }
            
            const collab = this.internalOptions.find(collab => collab._id === obj);
            if (collab) {
              return collab;
            } else {
              return { _id: obj, name: 'unkown' };
            }
          } else if (obj._id) {
            inList.push(obj._id);
            return obj;
          } else {
            throw new Error('Invalid assinged collaborators for collaborators dropdown');
          }
        });
        this.assignedCollaborators.forEach(this.buildContextMenu);
      }
      
      this.internalOptions.forEach((obj) => {
        if (inList.indexOf(obj._id) == -1 && !obj.suspended) {
          this.availableCollaborators.push({
              text: $rootScope.userFullName(obj),
              value: obj._id
          });
        }
      });
    }
  }

  $scope.buildContextMenu = function(collaborator) {
    collaborator.contextMenu = [];

    let suffix = '';
    let remove = true;
    let message = true;

    if ($rootScope.campaign && $rootScope.campaign.ownerId == collaborator._id) {
      suffix = `(${Translate.getLangString('account_owner')})`;
      remove = false;
    }

    if ($rootScope.user._id == collaborator._id) {
      suffix = Translate.getLangString('collaborator_is_you');
      remove = false;
      message = false;
    }

    if (collaborator.suspended) {
      suffix += ` (${Translate.getLangString('suspended')})`;
    }

    if (!$rootScope.fns.userHasRights("hasAdminRights", true) || collaborator.userType > 0) {
      remove = false;
    }

    if ($rootScope.campaign && $rootScope.campaign.createdBy === collaborator._id){
      suffix += ` (${Translate.getLangString('creator')})`
    }
    
    collaborator.contextMenu = [
      {
        text: $rootScope.userFullName(collaborator) + ' ' + suffix,
        divider: true
      }
    ]; 
  }

  $scope.onAddCollaboratorSelected = function(selected) {
    if (selected.value == 'invitecollaborator') {
      $state.go('collaborators');
    } else {
      this.$ctrl.onAssigned({ $collaboratorId: selected.value });
    }
  }

  $scope.onDeleteCollaboratorSelected = function(selectedItem) {
    const collaborator = $scope.internalOptions.find(c => c._id === selectedItem.id);
    if (collaborator) {
        this.$ctrl.onRemoved({ $collaborator: collaborator });
    } else {
        console.error("No collaborator found with ID:", selectedItem.id);
    }
  }

  $ctrl.$onChanges = function(changesObj) {
    if (changesObj.options) {
      if (changesObj.options.currentValue) {
        $scope.internalOptions = changesObj.options.currentValue;
        $scope.buildCollaboratorsMenu();
      }
      else if (!$scope.internalOptions && changesObj.options.isFirstChange()) {
        if ($rootScope.collaborators) {
          $scope.internalOptions = $rootScope.collaborators;
          $scope.buildCollaboratorsMenu();
        } else {
          Server.get('collaborators').then(function(collaborators) {
            $rootScope.collaborators = collaborators;
            $scope.internalOptions = collaborators;
            $scope.buildCollaboratorsMenu();
          });
        }
      }
    }

    if (changesObj.assignedCollaborators && !changesObj.assignedCollaborators.isFirstChange() && (changesObj.assignedCollaborators.currentValue !== changesObj.assignedCollaborators.previousValue)) {
      $scope.buildCollaboratorsMenu();
      $scope.availableExtraCollaborators();
    }
    if (changesObj.assignedCollaborators && changesObj.assignedCollaborators.currentValue) {
      $scope.initializeSelectedCollaborators();
  }
}

$scope.availableExtraCollaborators = function() {
  if ($scope.assignedCollaborators && $scope.assignedCollaborators.length > 3) {
    const nonSuspendedCollaborators = $scope.assignedCollaborators.filter(collab => !collab.suspended);
      $scope.extraCollaborators = nonSuspendedCollaborators.slice(3).map(collab => {
          $scope.buildContextMenu(collab);
          return {
              text: $rootScope.userFullName(collab),
              value: collab._id,
              contextMenu: collab.contextMenu 
          };
      });
  } else {
      $scope.extraCollaborators = [];
  }
};


$scope.loadAvailableCollaborators = function() {
  Server.get('collaborators').then(function(collaborators) {
    $rootScope.collaborators = collaborators;
     // Excluding the creator from the options list of available collaborators & suspended collaborators.
     const filteredCollaborators = collaborators.filter(collaborator => {
      return collaborator._id !== $scope.creator && !collaborator.suspended;
      
     });
    // If the current user (rootScope) is not the creator, we add him to the options list.
    if ($scope.creator !== $rootScope.user._id && !filteredCollaborators.some(c => c._id === $rootScope.user._id)) {
      const currentUserAsCollaborator = {
        _id: $rootScope.user._id,
        name: Util.userFullName($rootScope.user),
      };
      filteredCollaborators.push(currentUserAsCollaborator);
      $scope.internalOptions.push(currentUserAsCollaborator);
    }
    // Updating the options list of available collaborators.
    $scope.collaboratorsOptions = filteredCollaborators.map(c => ({
      id: c._id,
      label: Util.userFullName(c)
    }));
    $scope.initializeSelectedCollaborators();
  });
};

$scope.initializeSelectedCollaborators = function() {
  $scope.multiSelectModelCollaboratorsAssigned = [];
  if ($ctrl.assignedCollaborators && $ctrl.assignedCollaborators.length > 0) {
    const filteredAssignedCollaborators = $ctrl.assignedCollaborators.filter(collab => (collab.id !== $scope.creator) && (!collab.suspended));
    filteredAssignedCollaborators.forEach(assignedCollab => {
      if ($scope.collaboratorsOptions) {
        const match = $scope.collaboratorsOptions.find(option => option.id === assignedCollab.id);
        if (match) {
          $scope.multiSelectModelCollaboratorsAssigned.push(match);
        }
      }
    });
  }
  $scope.buildCollaboratorsMenu()
};

/* MULTI SELECT */
// Texts
let multiSelectCollaborators = JSON.parse(JSON.stringify(multiSelect))
$scope.multiSelectCollaboratorsTexts = multiSelectCollaborators.texts
$scope.multiSelectCollaboratorsTexts.buttonDefaultText = null;
$scope.multiSelectCollaboratorsTexts.selectionCount = null;
$scope.multiSelectCollaboratorsTexts.dynamicButtonTextSuffix = null;

// Settings
$scope.multiSelectAvailableCollaboratorsSettings = {
  showCheckAll: false,
  showUncheckAll: false,
  enableSearch: true,
  dynamicTitle: false,
}

// Events
$scope.multiSelectAvailableCollaboratorsEvents = {
  onItemSelect: function(item) {
    $scope.onAddCollaboratorSelected({value: item.id});
     
  },
  onItemDeselect: function(item) {
    $scope.onDeleteCollaboratorSelected(item);
  }
};

$scope.updateFilteredCollaborators = function() {
  if ($ctrl.context === "campaign") {
    $scope.collaboratorCreator = $scope.getCollaboratorCreator();
  } else {
    $scope.collaboratorCreator = null;
  }
  $scope.collaboratorsWithoutCreator = $scope.getCollaboratorsWithoutCreator();
};

$scope.getCollaboratorCreator = function() {
  return $scope.assignedCollaborators ? $scope.assignedCollaborators.filter(collab => collab._id === $rootScope.campaign.createdBy)[0] : null;
};

$scope.getCollaboratorsWithoutCreator = function() {
  if ($rootScope.campaign && $rootScope.campaign.createdBy && $ctrl.context === "campaign") {
    return $scope.assignedCollaborators ? $scope.assignedCollaborators.filter(collab => (collab._id !== $rootScope.campaign.createdBy) && (!collab.suspended)) : [];
  }
  return $scope.assignedCollaborators ? $scope.assignedCollaborators.filter(collab => !collab.suspended) : [];
};

/* WATCHERS */
$scope.$watch('assignedCollaborators', function(newVal, oldVal) {
  if (newVal !== oldVal) {
    $scope.updateFilteredCollaborators();
  }
}, true);

$scope.$watch('creator', function(newVal) {
  if (newVal !== undefined && newVal !== null) {
      $scope.loadAvailableCollaborators();
  }
});

$scope.$watch(function() {
  return $rootScope.campaign;
}, function(newCampaign) {
  if (newCampaign && newCampaign.createdBy) {
    $scope.creator = newCampaign.createdBy;   
  }
}, true);

$scope.getTooltipContent = function(type, collaboratorId) {
  let content = "<div>";

  if (type === 'creator' && $scope.collaboratorCreator) {
    content += `<div class='collaborator-creator'>${Util.userFullName($scope.collaboratorCreator)} (${Translate.getLangString('creator')})</div>`;
  } else if (type === 'notCreator') {
    const collaborator = $scope.collaboratorsWithoutCreator.find(c => c._id === collaboratorId);
    if (collaborator) {
      content += `<div class='collaborator'>${Util.userFullName(collaborator)}</div>`;
    }
  } else if (type === 'extra') {
    $scope.extraCollaborators.forEach(collaborator => {
      content += `<div class='extra-collaborator'>${collaborator.text}</div>`;
    });
  }

  content += "</div>";
  return content;
};

$ctrl.$onInit = function() {
  $q.all([
    $scope.loadAvailableCollaborators(),
    $scope.initializeSelectedCollaborators(),
    
  ]).then(function() {
    $scope.updateFilteredCollaborators();
  }).catch(function(error) {
    console.error("Error during initial data load:", error);
  });
};

}

