//@ts-check
angular.module('app').controller('SubmissionMessagesCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "Upload", "$timeout", "$state", "overlaySpinner", "ToasterService", "PopupService", "NG_QUILL_CONFIG_EMAILS", "Translate", "MailTemplates", "Util", "$q", function ($scope, $rootScope, $stateParams, Server, 
    Upload, $timeout, $state, overlaySpinner, ToasterService, PopupService, NG_QUILL_CONFIG_EMAILS, Translate, MailTemplates, Util, $q) {
    $scope.editorModules = NG_QUILL_CONFIG_EMAILS.modules;
    const hostname = window.location.hostname === 'localhost' ? window.location.hostname + ':8000' : window.location.hostname;
    const host = window.location.protocol + '//' + hostname;
    //@ts-ignore
    let socket = io.connect(host, { secure: true, reconnect: true, rejectUnauthorized: false, transports: ["websocket"] });
    $scope.newCommentField = '';
    $scope.messages = [];
    $scope.attachments = [];
    $scope.scrollGlue = true;
    let isListening = false;

    $rootScope.validateUserAccess((user) => {
        if (!$rootScope.fns.userHasRights('candidates.messages', 'view', user)) {
            $state.go('submission', { candidateId: $stateParams.candidateId })
        }
    });

    $scope.messageDropdownOptions = [
        {
            value: 'createTask',
            text: Translate.getLangString('task_add'),
            hasRights: $scope.fns.userHasRights('tasks.list', 'edit'),
            hasSettings: $scope.fns.hasUiSettings(['useTasksFromMessage']),
        }
    ].filter(options => options.hasRights && options.hasSettings)

    $scope.ddLangs = Translate.getLangDropdownObject();
    $scope.editLang = Translate.currentLanguage();
    MailTemplates.getMailTemplates($scope.editLang)
    .then(mailTemplates => {
        $scope.$apply(function() {
            $scope.mailTemplates = mailTemplates;
        });
        $scope.msgTemplateClick = function(templateKey) {
            $scope.activeMsgTemplate = MailTemplates.msgTemplateClick($scope.mailTemplates, templateKey);
            if ($scope.activeMsgTemplate) {
                $scope.messageText = $scope.activeMsgTemplate.body;
            }
        }
    });
    
    $scope.templateLanguageChanged = async function() {
        return $q(async (resolve, reject) => {
            $scope.mailTemplates = await MailTemplates.getMailTemplates($scope.editLang).catch(reject);
            if ($scope.activeMsgTemplate) {
                $scope.activeMsgTemplate = MailTemplates.msgTemplateClick($scope.mailTemplates, $scope.activeMsgTemplate.key);
                if ($scope.activeMsgTemplate) {
                    $scope.messageText = $scope.activeMsgTemplate.body;
                }
            }
            resolve();
        })
    }


    $scope.onUpdateMessages = function () {
        Server.get('candidates/' + $stateParams.candidateId + '/message')
            .then($scope.newMessagesReceived)
    };

    $scope.newMessagesReceived = function (messages) {
        $scope.messages = messages;
        if (!isListening) {
            socket.on($scope.messages[0].conversationId, (msg) => {
                $scope.onUpdateMessages();
            });
            isListening = true;
            console.log('listening');
        }
    };

    $scope.uploadAttachments = async function (files) {
        overlaySpinner.show('submission');

        for (const file of files) {
            try {
                let response = await Upload.upload({
                    url: Server.makeUrl(`users/${$rootScope.user._id}/documents`),
                    data: {
                        file: file
                    }
                });
                $scope.attachments.push(response.data);
            } catch (err) {
                ToasterService.failure(err, 'err_61_attachment_not_ok');
            }
        }
        overlaySpinner.hide('submission');
    };

    $scope.removeAttachment = function (index) {
        $scope.attachments.splice(index, 1);
    };

    $scope.sendMessage = function () {
        let text = $scope.messageText.replace(/<br\s*\/*>/ig, '\n');
        if (!text)
            return;

        overlaySpinner.show('submission');

        Server.post('candidates/' + $stateParams.candidateId + '/message', {
            text: text,
            attachments: $scope.attachments
        }).then(function(res) {
            overlaySpinner.hide('submission');
            $scope.onUpdateMessages(res);
        }, function (err) {
            overlaySpinner.hide('submission');
            ToasterService.failure(err, 'err_60_message_not_sent');
        });
        $scope.messageText = '';
        $scope.attachments = [];
        $scope.scrollGlue = true;
    };

    $scope.messageDropdownChanged = function(selected, message) {
        switch (selected.value) {
            case 'createTask':
                $scope.createTaskFromMessage(message);
                break;
        }
    }

    $scope.createTaskFromMessage = function(message) {
        let messageTitle = Util.getTextFromHtmlString(message.content);

        const firstWords = /([^\s]*\s?){0,3}/.exec(messageTitle);
        if (firstWords && firstWords[0].length < 20) {
            messageTitle = firstWords[0].trim();
        } else {
            messageTitle = messageTitle.substring(0, 15).trim();
        }

        PopupService.openCrudCreateTaskPopup(
            $scope,
            Translate.getLangString('task_add'),
            {
                title: Translate.getLangString("message_reminder_title_prefix") + messageTitle,
                body: Translate.getLangString("message_reminder_body_prefix") + '<p><div>'+message.content+'</div></p>' + Translate.getLangString("message_reminder_body_sufix").replaceAll("...", message.messageUrl),
                candidateId: $stateParams.candidateId,
                campaignId: $rootScope.campaign._id,
            }
        );
    }

    Server.get('candidates/' + $stateParams.candidateId + '/message')
        .then((messages) => {
            if (messages.length) {
                $scope.messages = messages;
                socket.on($scope.messages[0].conversationId, (msg) => {
                    $scope.onUpdateMessages();
                    $scope.scrollGlue = true;
                });
                isListening = true;
            }

            const { messageId } = Util.getQueryStringFromHash(location);
            if (messageId) {
                setTimeout(() => {
                    $scope.scrollGlue = false;
                    Util.scrollToElement(`*[id="${messageId}"]`, -220, '.submission.scrollable');
                }, 500);
            }
        });
}]);
