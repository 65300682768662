//@ts-check
angular.module('app').controller('AccountCtrl', ["$scope", "$rootScope", "Server", "Upload", "PopupService", "overlaySpinner", "Translate", "Hash", "Util", "ToasterService", "Onboarding", "socketListener", "multiSelect", function ($scope, $rootScope, Server, Upload, PopupService, overlaySpinner, Translate, Hash, Util, ToasterService, Onboarding, socketListener, multiSelect) {
  var selectedBrandColor = null;
  let multiSelectTimezone = JSON.parse(JSON.stringify(multiSelect))
  $scope.multiSelectTimezoneSettings = {
    ...multiSelectTimezone.objectSettings,
    idProperty: "id",
    displayProp: "name",
    dynamicTitle: true,
    smartButtonMaxItems: 1,
    selectionLimit: 1,
    showCheckAll: false,
    showUncheckAll: false,
    checkBoxes: false,
  };
  $scope.multiSelectTimezoneTexts = multiSelectTimezone.texts;
  $scope.selectedTimezone = []

  setupColorPicker();

  Util.setPageTitle(Translate.getLangString('profile'));
  $rootScope.secondaryNav = null;
  $rootScope.backEnabled = false;
  Onboarding.initWidget(null);

  var langsArray = Translate.getLangsArray();
  $scope.langsArray = langsArray;

  $scope.ddLangs = Translate.getLangDropdownObject();
  $scope.descriptionLang = Translate.currentLanguage();

  $scope.update = function () {

      $scope.editUser.emailSignature = $scope.editUser.emailSignature.replace(/\n/g, "<br>");
      $scope.editUser.emailSignature = $scope.editUser.emailSignature.replace(/\\n/g, "<br>");

      $scope.editUser.language = langsArray.indexOf($scope.editUser.languageString);

      if ($scope.editUser.language < 0) {
          $scope.editUser.language = 0;
      }

      $scope.editUser.brandColor = selectedBrandColor;

      if ($scope.selectedTimezone[0]) {
        $scope.editUser.timezone = $scope.selectedTimezone[0].id;
      }

      overlaySpinner.show('account');
      var lang = $rootScope.user.language;
      Server.post('users/me', $scope.editUser)
          .then(function (user) {
              overlaySpinner.hide('account');
              $rootScope.user = user;
              setupEditUser();
              if (user.language != lang) {
                  location.reload();
              }
              postMessage({ name: 'reloadOpener', user: $rootScope.user });
              ToasterService.success('user_well_updated');
          }, function (err) {
              overlaySpinner.hide('account');
              setupEditUser();
              ToasterService.failure(err, 'user_not_updated');
          });
  };

  $scope.noop = function () {
  };

  $scope.editProfilePhoto = function () {
      $scope.notification = '';
      const uploadUrl = 'users/me/photo';
      const getUrl = 'users/me';
      Util.editProfilePhoto($scope, $rootScope.user, PopupService, uploadUrl, getUrl);
  };

  if (!$rootScope.user) {
      overlaySpinner.show('account');
      $rootScope.reloadUser().then(setupEditUser);
  } else {
      setupEditUser();
  }

  $scope.showResetPassword = false;
  $scope.message = $scope.password1 = $scope.password2 = '';

  $scope.resetPassword = function () {
      $scope.showResetPassword = true;
      $scope.message = $scope.password1 = $scope.password2 = '';
  };

  $scope.updatePassword = function () {
      $scope.message = Util.checkPassword($scope.password1, $scope.password2);
      if ($scope.message) {
          return;
      }

      var newPasswordHash = Hash.make($scope.password1);

      Server.post('users/me/password', { newPasswordHash: newPasswordHash, newPassword: $scope.password1 });

      $scope.showResetPassword = false;
      $scope.message = $scope.password1 = $scope.password2 = '';
  };

  $scope.close = function () {
      $scope.showResetPassword = false;
      $scope.message = $scope.password1 = $scope.password2 = '';
  };

  function setupColorPicker() {
      if ($rootScope.user && 
          $rootScope.user.customization && 
          $rootScope.user.customization.brandColor && 
          $rootScope.user.customization.brandColor.color) {        
          selectedBrandColor = $rootScope.user.customization.brandColor.color;
      }

      $('#colorPicker').spectrum({
          color: selectedBrandColor,
          preferredFormat: "hex",
          allowEmpty: true,
          showInput: true,
          showButtons: true,
          hide: function (tinycolor) {
              if (!tinycolor) {
                  selectedBrandColor = null;
                  return;
              }
              selectedBrandColor = tinycolor.toHexString();
          }
      });
  };

  function setupEditUser() {
      var brRegex = /<br\s*[\/]?>/gi;

      $scope.originalUser = {
          name: $rootScope.user.name,
          position: $rootScope.user.position,
          email: $rootScope.user.email,
          company: $rootScope.user.company,
          companyDescription: $rootScope.user.companyDescription,
          companyUrl: $rootScope.user.companyUrl,
          address: $rootScope.user.address || {},
          emailSignature: ($rootScope.user.emailSignature || '').replace(brRegex, "\n"),
          settings: $rootScope.user.settings,
          language: $rootScope.user.language,
          languageString: langsArray[$rootScope.user.language || 0],
          timezone: $rootScope.user.timezone,
      };
      $scope.editUser = JSON.parse(JSON.stringify($scope.originalUser));
      $scope.selectedTimezone = [{ id: $scope.editUser.timezone, name: $scope.editUser.timezone }];
      overlaySpinner.hide('account');
  };

  if ($rootScope.user) {
      socketListener.subscribe($rootScope.user._id);
  }
  $scope.$on('socketRecruiterEvent', function (event, socketData) {
      $rootScope.user = socketData.user;
      setupEditUser();
      ToasterService.success('user_well_updated');
  });

  $scope.removeCalendarAuth = function (profile_id) {
      overlaySpinner.show('account');
      Server.delete(`users/calendar-sync/authentication/${profile_id}`)
          .then(function (user) {
              overlaySpinner.hide('account');
              $rootScope.user = user;
              setupEditUser();
              ToasterService.success('user_well_updated');
          }, function (err) {
              overlaySpinner.hide('account');
              ToasterService.failure(err, 'user_not_updated');
          });
  }

  $scope.checkMfa = function() {
    const overlay = overlaySpinner.show('account');
    Server.post('users/me/check-mfa')
        .then((res) => {
            overlay.hide();
            _.set($rootScope.user, 'auth0.mfaActivated', res.mfaActivated);
        }).catch(err => {
            ToasterService.failure(err, 'err_0_error_occurred');
            overlay.hide();
        });
  }

  $scope.setupMfa = function() {
    const overlay = overlaySpinner.show('account');
    Server.post('users/me/setup-mfa').then((res) => {
        overlay.hide();
        const mfaWindow = window.open(res.ticketUrl, '_blank');
        if (mfaWindow) {
            const interval = setInterval(() => {
                if (mfaWindow && mfaWindow.closed) {
                    clearInterval(interval);
                    $scope.checkMfa();
                }
            }, 200);
        }
    }).catch(err => {
        ToasterService.failure(err, 'err_0_error_occurred');
        overlay.hide();
    });
  }

  $scope.resetMfa = function() {
    const overlay = overlaySpinner.show('account');
    Server.delete('users/me/reset-mfa').then((res) => {
        overlay.hide();
        $rootScope.user.auth0.mfaActivated = false;
    }).catch(err => {
        ToasterService.failure(err, 'err_0_error_occurred');
        overlay.hide();
    });
  }

  Server.get('users/calendar-sync/getAuthLink')
      .then(function (data) {
          $scope.calendarAuthLink = data.link;
      });

  Util.listTimezones().then(res => {
    $scope.timezones = res;
  });

  setTimeout(function() {
    document.querySelector('#changeEmail a')?.addEventListener('click', function(event) {
      PopupService.openSupportMessage($scope, {}, Translate.getLangString("change_email_message"));
    })
  }, 500)
}]);
