//@ts-check
//@ts-ignore
angular.module('app').controller('SubmissionInterviewNotesCtrl', ["$scope", "$rootScope", "$stateParams", "Server", "$state", "PopupService", "overlaySpinner", "Translate", "Util", "ToasterService", "StageFactory", function ($scope, $rootScope, $stateParams, Server, $state, PopupService, overlaySpinner, Translate, Util, ToasterService, StageFactory) {
  /** 
   * @typedef { Object } InterviewNote
   * @property { string } _id
   * @property { string } title
   * @property { string } body
   * @property { string } date
   * @property { number } rating
   * @property { {_id: string, name: string} } createdBy
   */

  $rootScope.validateUserAccess((user) => {
    if (!$rootScope.fns.userHasRights('candidates.interviews', 'view', user)) {
      $state.go('submission', { candidateId: $stateParams.candidateId })
    }
  });

  $scope.interviewNotes = [];
  $scope.entityProperties = [
    {
      type: 'text',
      location: 'header',
      key: 'title',
      text: Translate.getLangString('title'),
      dataCssClass: 'interview-card__header__title crud-list__title__col',
      filterCssClass: 'interview-card__header__title crud-list__title__col',
      noFilter: true,
      noSort: true,
    },
    {
      type: 'text',
      location: 'header',
      key: 'createdByName',
      text: Translate.getLangString('created_by'),
      dataCssClass: 'interview-card__header__user',
      filterCssClass: 'interview-card__header__user',
      noFilter: true,
      noSort: true,
      disabled: true,
    },
    {
      type: 'datetime',
      location: 'header',
      key: 'date',
      dateKey: 'day',
      text: Translate.getLangString('date'),
      dataCssClass: 'no-grow',
      filterCssClass: 'no-grow',
      noFilter: true,
      noSort: true,
    },
    {
      type: 'stars',
      location: 'header',
      key: 'rating',
      text: Translate.getLangString('rating'),
      dataCssClass: 'no-grow',
      filterCssClass: 'no-grow',
      noFilter: true,
      // noSort: true,
    },
    {
      type: 'quill',
      location: 'body',
      key: 'body',
      text: Translate.getLangString('body'),
      dataCssClass: 'no-grow',
      filterCssClass: '',
      noFilter: true,
      noSort: true,
    },
  ];
  $scope.crud_translation = {
    title: 'interview_notes',
    add_item: 'interview_entry_add',
    items: 'interview_notes',
  }

  /**
   * Check if all input values are valid
   * @param { InterviewNote } interviewNote interviewNote being created/updated
   * @returns true if inputs are valid
   */
  $scope.validateInputs = function(interviewNote) {
    const errorFields = {};
    if (!interviewNote.title) {
      errorFields.title = true;
    }
    if (!interviewNote.date) {
      errorFields.date = true;
    }
    return errorFields;
  }

  $scope.onItemAddClicked = function(newItem) {
    // const date = moment(new Date());
    // newItem.date = date.toDate().toLocaleDateString();
    newItem.date = new Date();
    newItem.day = newItem.date.toISOString();
    newItem.createdByName = Util.userFullName($rootScope.user);
}

  $scope.loadInterviewNotes = function() {
    if ($rootScope.candidate?.ratingElements?.interview?.length) {
      const interviewRatings = $rootScope.candidate.ratingElements.interview;
      $scope.interviewNotes = [...interviewRatings.map(interview => {
        if (interview.date) {
          interview.day = interview.date;
          interview.date = moment(interview.date);
        }
        interview.createdByName = interview.createdBy.name
        return interview
      })];
    }
  }

  /**
   * Rule to define if an interviewNote is editable by the current user
   * @param { InterviewNote } interviewNote 
   * @returns { boolean }
   */
  $scope.isEditable = function(interviewNote) {
      return !interviewNote._id || interviewNote.createdBy === $rootScope.user._id;
  }

  /**
   * 
   * @param {InterviewNote} interviewNote
   */
  $scope.deleteEntry = function(interviewNote) {
    overlaySpinner.show('submission');
    return Server.delete(`candidates/${$stateParams.candidateId}/interviewNotes/${interviewNote._id}`, interviewNote)
      .then(() => {
        overlaySpinner.hide('submission');
        ToasterService.success('interview_deleted');
        if ($scope.$parent.reloadCandidate) {
          $scope.$parent.reloadCandidate();
        }
      })
      .catch((err) => {
        overlaySpinner.hide('submission');
        ToasterService.failure(err, 'interview_not_deleted');
        $scope.loadInterviewNotes();
      });
  }

  /**
   * 
   * @param {InterviewNote} interviewNote
   */
  $scope.saveEntry = function (interviewNote) {
    overlaySpinner.show('submission');
    const entryDto = JSON.parse(JSON.stringify(interviewNote));
    if (interviewNote._id) {
      return Server.put(`candidates/${$stateParams.candidateId}/interviewNotes/${interviewNote._id}`, entryDto)
        .then(() => {
          overlaySpinner.hide('submission');
          ToasterService.success('interview_updated');
          if ($scope.$parent.reloadCandidate) {
              $scope.$parent.reloadCandidate();
          }
          return interviewNote;
        })
        .catch((err) => {
          overlaySpinner.hide('submission');
          ToasterService.failure(err, 'interview_not_updated');
        });
    } else {
      return Server.post(`candidates/${$stateParams.candidateId}/interviewNotes`, entryDto)
        .then((res) => {
          overlaySpinner.hide('submission');
          ToasterService.success('interview_created');
          interviewNote._id = res._id;
          if ($scope.$parent.reloadCandidate) {
              $scope.$parent.reloadCandidate();
          }
          return interviewNote;
        })
        .catch(err => {
          overlaySpinner.hide('submission');
          ToasterService.failure(err, 'interview_not_created');
        });
    }
  }

  /**
   * Rule to define if an interviewNote is editable by the current user
   * @param { InterviewNote } interviewNote 
   * @returns { boolean }
   */
  $scope.isEditable = function(interviewNote) {
    return !interviewNote._id || interviewNote.createdBy._id === $rootScope.user._id;
  }

  $scope.$on('onCandidateData', $scope.loadInterviewNotes);
}])
