//@ts-check
angular.module('app').controller('ProfileCtrl', ["$scope", "Server", "Translate", "Hash", "Util", function ($scope, Server, Translate, Hash, Util) {
  Server.get('users/me/signup').then(function (data) {
      $scope.user = data;
      Translate.changeVisitorLanguage($scope.user.language);
  });

  $scope.errMessage = '';

  $scope.submit = function () {
      $scope.errMessage = Util.checkPassword($scope.password, $scope.repeatPassword);
      console.log('$scope.errMessage', $scope.errMessage);
      if ($scope.errMessage) {
          return;
      }

      $scope.user.password = Hash.make($scope.password);
      $scope.errMessage = '';

      Server.post('users/' + $scope.user.id + '/signup', $scope.user).then(function () {
          window.location.replace('/signout');
      });
  };
}]);
